import { useContext } from 'react'
import { usePluginFeatures } from '@modules/plugins/plugin_manager_react_utils.jsx'
import { LocaleContext } from '@lib/i18n/localization_provider'
import { Button, Typography, theme } from 'antd'
import './not_found.scss'

const NotFound = () => {
    const { token } = theme.useToken()
    const { locale } = useContext(LocaleContext)
    const appHeaderHeight = document.querySelector('.app-header')?.offsetHeight + 50
    const appFooterHeight = document.querySelector('.app-footer')?.offsetHeight + 50
    const pluginFeatures = usePluginFeatures()

    return (
        <div
            className="not-found"
            style={{
                backgroundColor: token.colorBgLayout,
                marginBlockStart: `${appHeaderHeight}px`,
                marginBlockEnd: `${appFooterHeight}px`,
            }}
        >
            <div className="not-found__content">
                <div className="not-found__image">{pluginFeatures?.notFound?.icon?.inject()}</div>
                <div className="not-found__info">
                    <Typography.Title className="not-found__title" level={1}>
                        {locale('notFound.title')}
                    </Typography.Title>
                    <Typography.Paragraph className="not-found__text">{locale('notFound.text')}</Typography.Paragraph>
                </div>
                <div className="not-found__btn">
                    <Button type="primary" shape="round" onClick={() => (window.location.href = '/')}>
                        {locale('notFound.linkText')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NotFound
