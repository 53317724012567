import { useState } from 'react'
import useEventListener from '@hooks/useEventListener'

export default function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    useEventListener('resize', () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    })

    return windowSize
}

// export default function WindowSizeComponentExample() {
//     const { width, height } = useWindowSize()

//     return (
//       <div>
//         {width} x {height}
//       </div>
//     )
//   }
