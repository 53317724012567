import { createRoot } from 'react-dom/client'
import App from '@views/application/App.jsx'
import { ReactProcessRouter } from '@prospective/process-router-react'
import { router } from './configuration/router'
import { appendScript, gtmBody, gtmHead } from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { resources, TAG_TABLE } from '@lib/i18n/resources'
import { LocalizationProvider } from '@lib/i18n/localization_provider.jsx'
import { CONFIG } from '@configuration/config_variables.js'
import * as serviceWorker from './serviceWorker'
import ThemeProvider from '@modules/theming/ThemeProvider.jsx'
import 'regenerator-runtime/runtime'
import 'antd/dist/reset.css'
import './index.scss'
import { FeatureToggles } from '@utils/feature_toggle.jsx'

const logger = Logger('index', CATEGORIES.MAIN)

if (FeatureToggles.features.gtm.value && CONFIG.GTM_KEY) {
    try {
        appendScript(gtmHead(CONFIG.GTM_KEY), document.head)
        const bodyFragment = document.createElement('fragment')
        bodyFragment.innerHTML = gtmBody(CONFIG.GTM_KEY)
        document.body.append(bodyFragment.children.item(0))
    } catch (error) {
        logger.warn.withError(error, 'Could not initialize Google Tag Manager')
    }
}

router.start()

const appContainer = document.getElementById('root')
const root = createRoot(appContainer)

root.render(
    <LocalizationProvider resources={resources} tagTable={TAG_TABLE}>
        <ThemeProvider>
            <ReactProcessRouter router={router}>
                <App />
            </ReactProcessRouter>
        </ThemeProvider>
    </LocalizationProvider>
)

serviceWorker.unregister()
