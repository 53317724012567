import { Localization } from '@lib/i18n/localization.js'
import { Authorization } from '@modules/authorization/authorization.js'
import { getDateOptions } from '@components/elements/datepicker/datepicker.js'
import { O } from '@prospective/pms-js-utils'
import { View } from '@lib/view_context/view-context.js'

export const KPIMetadata = (locale, proAnalyticsPermissions) => {
    const dateOptions = getDateOptions(locale)
    return {
        kpis: {
            visible: false,
        },
        kpiStatisticsStatus: {
            label: locale('status'),
        },
        kpiStatistics: {
            visible: proAnalyticsPermissions?.kpi?.permissions.read || false,
        },
        kpiActiveJobs: {
            label: locale('kpiStatistics.stellen'),
            description: locale('infoTooltip.kpi.jobs'),
        },
        kpiActivePublications: {
            label: locale('kpiStatistics.publications'),
            description: locale('infoTooltip.kpi.publications'),
        },
        kpiPublicationsOnline: {},
        kpiPublicationsOnlinePercentage: {},
        kpiPublicationsPrint: {},
        kpiPublicationsPrintPercentage: {},
        kpiCostsTotal: {
            label: locale('kpiStatistics.costs'),
            description: locale('infoTooltip.kpi.costs'),
        },
        kpiCostsOnline: {
            label: locale('media.online'),
        },
        kpiCostsOnlinePercentage: {},
        kpiCostsPrint: {
            label: locale('media.print'),
        },
        kpiCostsPrintPercentage: {},
        kpiViews: {
            label: locale('views'),
        },
        kpiClicks: {
            label: locale('click.plural'),
        },
        kpiDeviceRatioWeb: {
            label: locale('proAnalytics.web'),
        },
        kpiDeviceRatioMobile: {
            label: locale('proAnalytics.mobile'),
        },
        kpiDeviceRatioPercentWeb: {
            label: locale('proAnalytics.web'),
        },
        kpiDeviceRatioPercentMobile: {
            label: locale('proAnalytics.mobile'),
        },
        kpiPits: {
            label: locale('proAnalytics.pits'),
        },
        dateRange: {
            label: locale('timePeriod'),
            default: dateOptions.last30Days.key,
            dictionary: O(dateOptions).values(),
            actions: ['onChange'],
        },
    }
}

export const CockpitKPIsContext = View.observe(Localization, Authorization).update((localization, authorization) =>
    KPIMetadata(localization.locale, authorization.proAnalyticsPermissions)
)
