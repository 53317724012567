import { useEffect, useState } from 'react'
import { requiredValidator } from '@prospective/pms-view-context'
import { O } from '@prospective/pms-js-utils'

/**
 * React custom hook returning a viewModel for given context type
 * @param context
 */
export function useViewModel(context) {
    const [validationMessages, setValidationMessages] = useState({})
    const [viewDescriptor, setViewDescriptor] = useState({
        values: context.values,
        metadata: context.metadata,
        errors: context.errors,
        eventTriggers: context.eventTriggers
    })

    const contextUpdateHandler = ({ values, metadata, errors, eventTriggers, validationMessages }) => {
        setViewDescriptor({
            values,
            metadata,
            errors,
            eventTriggers
        })
        setValidationMessages(validationMessages)
    }

    useEffect(() => {
        context.subscribe(contextUpdateHandler)
        contextUpdateHandler({
            values: context.values,
            metadata: context.metadata,
            errors: context.errors,
            eventTriggers: context.eventTriggers,
            validationMessages: context.validationMessages,
        })
        return () => {
            if (context) context.unsubscribe(contextUpdateHandler)
        }
    }, [])

    const setValue = (context, property, value) => {
        context.updateState({ values: { [property]: value } })
    }

    /**
     * @type ViewModelObject
     */
    const viewModel = O(viewDescriptor.metadata)
        .map((descriptor, property) => {
            /**
             * @type {FieldModel}
             */
            const fieldModel = {
                value: viewDescriptor.values[property],
                default: descriptor.default,
                setValue: value => {
                    setValue(context, property, value)
                    context.eventTriggers[property].onChange(value)
                    return fieldModel
                },
                label: descriptor.label,
                disabled: descriptor.disabled ? "disabled" : "",
                readOnly: descriptor.readOnly,
                visible: !(descriptor.visible === false),
                sequenceNumber: descriptor.sequenceNumber,
                dictionary: descriptor.dictionary ? descriptor.dictionary : undefined,
                dictionaryMode: descriptor.dictionaryMode || DICTIONARY_MODE.FIXED,
                placeholder: descriptor.placeholder,
                validationMessages: validationMessages[property] || [],
                isInvalid: validationMessages[property]?.length > 0,
                isValid: (validationMessages[property] || []).length === 0,
                isRequired: descriptor.validators?.some((validator) => validator.type === requiredValidator),
                validationStatus: (validationMessages[property] || []).length ? "error" : undefined,
                clearValidationState: () => context.clearValidation(property),
                validate: () => {
                    const validationResult = context.validate(property)
                    const nextValidationMessages = O(validationMessages)
                        .excluding(property)
                        .merge(validationResult)
                        .valueOf()
                    setValidationMessages(nextValidationMessages)
                    return O(validationResult).size === 0
                },
                ...O(viewDescriptor.eventTriggers[property])
                    .map((trigger, eventName) => (...args) => {
                        // trigger(...args)
                        context.eventTriggers[property][eventName](...args)
                        return fieldModel
                    })
                    .valueOf()
            }

            const customFields = O(descriptor).excluding(
                "default",
                "label",
                "readOnly",
                "sequenceNumber",
                "dictionaryMode",
                "disabled",
                "visible",
                "dictionary",
                "placeholder",
                "actions"
            )
            customFields.forEach((value, field) => (fieldModel[field] = value))
            return fieldModel
        })
        .valueOf()

    Reflect.defineProperty(viewModel, 'validate', {
        get: () => (...fields) => {
            const validationResult = context.validate(...fields)
            setValidationMessages(validationResult)
            return validationResult
        },
        enumerable: false
    })
    Reflect.defineProperty(viewModel, 'getValues', {
        get: () => () => viewDescriptor.values,
        enumerable: false
    })
    Reflect.defineProperty(viewModel, 'errors', {
        get: () => O(viewDescriptor.errors).filter(value => value).valueOf(),
        enumerable: false
    })
    Reflect.defineProperty(viewModel, 'validationMessages', {
        get: () => validationMessages,
        enumerable: false
    })
    return viewModel
}

export const DICTIONARY_MODE = {
    /** Dictionary items can't be changed */
    FIXED: 'fixed',
    /** Dictionary items can be edited */
    EDITABLE: 'editable',
    /** Dictionary can be extended with new items */
    EXTENDABLE: 'extendable',
    /** Dictionary can be extended with new items. Existing items can be edited */
    EDITABLE_AND_EXTENDABLE: 'editable_and_extendable',
}
