import { Modal } from 'antd'
import { useEffect, useState } from 'react'
import {
    ApiOutlined,
    AppstoreAddOutlined,
    BugFilled,
    ExceptionOutlined,
    FileProtectOutlined,
    FormatPainterOutlined,
    TranslationOutlined,
} from '@ant-design/icons'
import { Debug, useDebug } from '@utils/debug.js'
import { FloatButton } from 'antd'
import LogViewer from '@components/modules/log_viewer/log_viewer.jsx'
import PluginsViewer from '@components/modules/debug/plugins_viewer/plugins_viewer.jsx'
import FeaturesViewer from '@components/modules/debug/features_viewer/features_viewer.jsx'
import ThemesViewer from '@components/modules/debug/themes_viewer/themes_viewer.jsx'
import PermissionsViewer from '@components/modules/debug/permissions_viewer/permissions_viewer.jsx'
import TranslationsViewer from '@components/modules/debug/translations_viewer/translations_viewer.jsx'

const DebugTools = () => {
    const [logModal, setLogModal] = useState(null)
    const { debugMode, isLogViewerOpen, openLogViewer, closeLogViewer, selectedLogNumber } = useDebug()
    const [modal, contextHolder] = Modal.useModal()

    const log = () => {
        const modalWindow = modal.info({
            title: 'Logs',
            content: <LogViewer selectedLogNumber={selectedLogNumber} />,
            centered: true,
            width: '800',
            onOk() {
                closeLogViewer()
            },
        })
        setLogModal(modalWindow)
    }

    const plugins = () =>
        modal.info({
            title: 'Plugins',
            content: <PluginsViewer />,
            centered: true,
            width: '700',
        })

    const featureToggles = () =>
        modal.info({
            title: 'Feature toggles',
            content: <FeaturesViewer />,
            centered: true,
        })

    const themes = () =>
        modal.info({
            title: 'Graphic themes',
            content: <ThemesViewer />,
        })

    const permissions = () =>
        modal.info({
            title: 'Permissions',
            content: <PermissionsViewer />,
            width: 700,
        })

    const translations = () =>
        modal.info({
            title: 'Translations',
            content: <TranslationsViewer />,
        })

    useEffect(() => {
        if (isLogViewerOpen) log()
        else logModal?.destroy()
    }, [isLogViewerOpen])

    return (
        debugMode && (
            <div>
                {contextHolder}
                <FloatButton.Group id="debugToolsBtn" trigger="click" icon={<BugFilled />}>
                    <FloatButton tooltip={`v${Debug.version}`} onClick={openLogViewer} />
                    <FloatButton icon={<ExceptionOutlined />} tooltip="Message log" onClick={openLogViewer} />
                    <FloatButton icon={<ApiOutlined />} tooltip="Plugins" onClick={plugins} />
                    <FloatButton
                        className="feature-toggle-btn"
                        icon={<AppstoreAddOutlined />}
                        tooltip="Feature toggles"
                        onClick={featureToggles}
                    />
                    <FloatButton icon={<FormatPainterOutlined />} tooltip="Themes" onClick={themes} />
                    <FloatButton icon={<FileProtectOutlined />} tooltip="Permissions" onClick={permissions} />
                    <FloatButton icon={<TranslationOutlined />} tooltip="Translations" onClick={translations} />
                </FloatButton.Group>
            </div>
        )
    )
}

export default DebugTools
