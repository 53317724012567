import { defaultTheme } from '../default/default.theme.js'

export const darkTheme = {
    ...defaultTheme,

    antd: {
        ...defaultTheme.antd,
        token: {
            ...defaultTheme.antd.token,
            colorBgLayout: '#0a0a0a',
            colorBgContainer: '#858585',
            colorBgElevated: '#484848',
        },
    },
}
