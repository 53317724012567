import dateFnsGenerateConfig from 'rc-picker/es/generate/dateFns'
import {
    endOfMonth,
    endOfToday,
    endOfYear,
    endOfYesterday,
    startOfMonth,
    startOfYear,
    startOfYesterday,
    subDays,
    subMonths,
    subYears,
} from 'date-fns'
// import {DatePicker as AntDatePicker} from 'antd'
import generatePicker from 'antd/es/date-picker/generatePicker'
import { O } from '@prospective/pms-js-utils'

export const getDateOptions = locale => ({
    yesterday: {
        label: locale('yesterday'),
        key: 'yesterday',
        get value() {
            return {
                from: startOfYesterday(),
                to: endOfYesterday(),
            }
        },
    },
    currentMonth: {
        label: locale('currentMonth'),
        key: 'currentMonth',
        get value() {
            return {
                from: startOfMonth(new Date()),
                to: endOfToday(),
            }
        },
    },
    lastMonth: {
        label: locale('lastMonth'),
        key: 'lastMonth',
        get value() {
            const today = new Date()
            return {
                from: startOfMonth(subMonths(today, 1)),
                to: endOfMonth(subMonths(today, 1)),
            }
        },
    },
    last30Days: {
        label: locale('last30Days'),
        key: 'last30Days',
        get value() {
            return {
                from: subDays(new Date(), 30),
                to: endOfToday(),
            }
        },
    },
    currentYear: {
        label: locale('currentYear'),
        key: 'currentYear',
        get value() {
            return {
                from: startOfYear(new Date()),
                to: endOfToday(),
            }
        },
    },
    lastYear: {
        label: locale('lastYear'),
        key: 'lastYear',
        get value() {
            const today = new Date()
            return {
                from: startOfYear(subYears(today, 1)),
                to: endOfYear(subYears(today, 1)),
            }
        },
    },
})

export const DatePickerDictionary = locale =>
    O(getDateOptions(locale))
        .map((entry, key) => ({
            label: entry.label,
            value: key,
            getDateRange: () => entry.value,
        }))
        .values()

export const DatePicker = generatePicker(dateFnsGenerateConfig)
