
import Notification from '../notification/notification'
import { notificationData } from '@views/settings/notifications/dummy_data'
import './notification_list.scss'

const NotificationsList = ({ locale }) => {
    const renderNotifications = () =>
        notificationData().map(notification => <Notification key={notification.id} {...notification} />)

    return (
        <div className="notifications-list">
            <div className="notifications-list__top">
                <p>{locale('settings.notifications.title')}</p>
            </div>
            <div className="notifications-list__content">{renderNotifications()}</div>
        </div>
    )
}

export default NotificationsList
