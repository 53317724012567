import { lengthValidator, requiredValidator } from '@prospective/pms-view-context'

const specialCharacters = '!@#$%^&*()_+=-]}[{\\|\'";:/?.>,<`~'
const atLeast1SpecialCharacterValidator = (
    message = `Value must include at least 1 special character: ${specialCharacters}`
) => ({
    validate: value => {
        if (value === undefined || value === null) return []
        const result = [...value].some(character => specialCharacters.includes(character))
        return result ? [] : [message]
    },
})

const atLeast1UpperCaseValidator = (message = `Value must include at least 1 upper case character`) => ({
    validate: value => {
        if (value === undefined || value === null) return []
        const result = [...value].some(
            character => character === character.toUpperCase() && character !== character.toLowerCase()
        )
        return result ? [] : [message]
    },
})

const atLeast1LowerCaseValidator = (message = `Value must include at least 1 lower case character`) => ({
    validate: value => {
        if (value === undefined || value === null) return []
        const result = [...value].some(
            character => character === character.toLowerCase() && character !== character.toUpperCase()
        )
        return result ? [] : [message]
    },
})

const atLeast1DigitValidator = (message = `Value must include at least 1 digit`) => ({
    validate: value => {
        if (value === undefined || value === null) return []
        const result = [...value].some(character => !isNaN(character))
        return result ? [] : [message]
    },
})

export const passwordEqualityValidator = (message = `Given values are not equal`) => ({
    validate: (newPasswordRepeat, { newPassword }) =>
        ((newPasswordRepeat === undefined || newPasswordRepeat === null || newPasswordRepeat === '') &&
            (newPassword === undefined || newPassword === null || newPassword === '')) ||
        newPasswordRepeat === newPassword
            ? []
            : [message],
})

export const createPasswordValidator = locale => {
    return (minLength = 0) => {
        const required = requiredValidator(locale('newPasswordIsRequiredError'))
        const length = lengthValidator(locale('passwordLengthError', { minLength }), minLength)
        const atLeast1SpecialCharacter = atLeast1SpecialCharacterValidator(locale('passwordSpecialCharacterError'))
        const atLeast1UpperCase = atLeast1UpperCaseValidator(locale('passwordUpperCaseLetterError'))
        const atLeast1LowerCase = atLeast1LowerCaseValidator(locale('passwordLowerCaseLetterError'))
        const atLeast1Digit = atLeast1DigitValidator(locale('passwordDigitError'))

        return {
            validate: value => {
                const requiredValidatorResult = required.validate(value)
                if (requiredValidatorResult.length) return [requiredValidatorResult]
                const lengthValidatorResult = length.validate(value)
                const atLeast1SpecialCharacterValidatorResult = atLeast1SpecialCharacter.validate(value)
                const atLeast1UpperCaseValidatorResult = atLeast1UpperCase.validate(value)
                const atLeast1LowerCaseValidatorResult = atLeast1LowerCase.validate(value)
                const atLeast1DigitValidatorResult = atLeast1Digit.validate(value)
                return []
                    .concat(lengthValidatorResult)
                    .concat(atLeast1SpecialCharacterValidatorResult)
                    .concat(atLeast1UpperCaseValidatorResult)
                    .concat(atLeast1LowerCaseValidatorResult)
                    .concat(atLeast1DigitValidatorResult)
            },
        }
    }
}
