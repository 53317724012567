
import { PluginManager } from '@modules/plugins/plugin_manager.js'
import { plugins } from '@configuration/plugins.js'
import { CONFIG, PMS_CUSTOMERS } from '@configuration/config_variables.js'
import { CATEGORIES, Logger } from '@modules/logging/logger.js'
import { O } from '@prospective/pms-js-utils'
import { Stream } from '@lib/stream/stream2.js'
import { RemoteData } from '@lib/remote_data/remote-data.js'

const logger = Logger('Plugins', CATEGORIES.MAIN)
const isVDVMandate = CONFIG.customer === PMS_CUSTOMERS.VDV
const customer = CONFIG.customer.code
const defaultActivePlugins = !O(plugins).hasKey(customer)
    ? ['JB']
    : isVDVMandate
        ? ['SC_APP', 'VDV']
        : [customer]
PluginManager.plugins = plugins
let activePlugins

const load = Stream(
    async function* (plugins = undefined) {
        yield RemoteData.pending()
        if (!activePlugins) activePlugins = defaultActivePlugins
        if (activePlugins === plugins)
            return  RemoteData.success().setValue(PluginManager.state)
        if (!plugins) plugins = activePlugins
        logger.debug(`Updating active plugins: ${plugins.join(',')}`)
        try {
            await PluginManager.setActivePlugins(plugins)
        } catch (error) {
            return RemoteData.error(error)
        }
        if (PluginManager.status === PluginManager.STATUS_LOADING) {
            const status = await PluginManager.onStatusChange.promise
            if (status === PluginManager.STATUS_ERROR)
                throw RemoteData.error('Some plugins could not be activated')
        }
        activePlugins = PluginManager.activePlugins
        logger.debug('Plugins loaded.')
        return RemoteData.success().setValue(PluginManager.state)
    })

export const Plugins = {
    load,
    get activePlugins () { return activePlugins },
    get defaultActivePlugins () { return defaultActivePlugins },
    ...PluginManager.onChange
}

Plugins.STATUS_LOADING = PluginManager.STATUS_LOADING
