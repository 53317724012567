import {useContext} from 'react'
import {Alert, Button, Spin} from 'antd'
import {ProAnalyticsContext} from './pro_analytics.context'
import {LoadingOutlined} from '@ant-design/icons'
import {usePluginFeatures} from '@modules/plugins/plugin_manager_react_utils.jsx'
import {useCustomerConfig} from '@modules/customer_config/customer_config'
import {LocaleContext} from '@lib/i18n/localization_provider.jsx'
import './pro_analytics.view.scss'
import { useViewModel } from '@lib/view_context/view-model.js'
import { ErrorAlert } from '@components/elements/alerts/alerts.jsx'
import { SubHeaderPortal } from '@components/modules/app_sub_header/sub_header_portal.jsx'
import ProAnalyticsSubHeader from '@views/pro_analytics/pro_analytics_dashboard/pro_analytics_sub_header.jsx'

const isPendingOrUndefined = status => status === 'pending'

export default function ProAnalyticsDashboard({ className = '', ...props }) {
    const viewModel = useViewModel(ProAnalyticsContext)
    const { locale } = useContext(LocaleContext)
    const currency = viewModel?.currency?.value
    const pluginFeatures = usePluginFeatures()
    const customerConfig = useCustomerConfig()

    if (viewModel.errors.insufficientPermissions)
        return <div className={`ProAnalyticsDashboard ${className}`} {...props}>
            <ErrorAlert error={viewModel.errors.insufficientPermissions} closable={false} />
        </div>

            return (
            <div className={`ProAnalyticsDashboard ${className}`} {...props}>
            {customerConfig.error && (
                <div className="messageContainer">
                    <Alert
                        message={locale('proAnalytics.clientConfigErrorTitle')}
                        description={customerConfig.error.error}
                        type="info"
                        showIcon
                    />
                </div>
            )}
            {!viewModel.dashboardMessage.value && (
                <div className="widgetContainer">
                    {pluginFeatures?.proAnalytics?.kpiWidget?.inject({
                        locale,
                        viewModel,
                        currency,
                    })}
                    {pluginFeatures?.proAnalytics?.jobsCountWidget?.inject({
                        locale,
                        viewModel,
                    })}
                    {pluginFeatures?.proAnalytics?.costsPerMediumWidget?.inject({
                        locale,
                        viewModel,
                        currency,
                    })}
                    {pluginFeatures?.proAnalytics?.costsWidget?.inject({
                        locale,
                        viewModel,
                        currency,
                    })}
                    {pluginFeatures?.proAnalytics?.performanceWidget?.inject({
                        locale,
                        viewModel,
                    })}
                    {pluginFeatures?.proAnalytics?.candidateJourneyWidget?.inject({
                        locale,
                        viewModel,
                    })}
                    {pluginFeatures?.proAnalytics?.careerCenterWidget?.inject({
                        locale,
                        viewModel,
                    })}
                    {pluginFeatures?.proAnalytics?.reportsWidget?.inject({
                        locale,
                        viewModel,
                    })}
                </div>
            )}
            {viewModel.dashboardMessage.value && (
                <div className="messageContainer">
                    <Alert
                        message={viewModel.dashboardMessage.value}
                        description={
                            viewModel.recentlyUsedOrganisationNodes.value.length > 0 && (
                                <div>
                                    {viewModel.recentlyUsedOrganisationNodes.label}
                                    <ul className="recentlyUsedOrganisationNodes">
                                        {viewModel.recentlyUsedOrganisationNodes.value.map(node => (
                                            <li key={node.id}>
                                                <Button
                                                    type="link"
                                                    onClick={() =>
                                                        viewModel.recentlyUsedOrganisationNodes.onChange(node)
                                                    }
                                                >
                                                    {node.label}
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        }
                        type="info"
                        showIcon
                    />
                </div>
            )}
            {isPendingOrUndefined(viewModel?.dictionariesStatus?.value?.status) && (
                <div className="messageOverlay">
                    <div className="spinContainer">
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </div>
                </div>
            )}
            {isPendingOrUndefined(viewModel?.customerConfigStatus?.value?.status) &&
                !isPendingOrUndefined(viewModel?.dictionariesStatus?.value?.status) && (
                    <div className="messageOverlay">
                        <div className="spinContainer">
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        </div>
                    </div>
                )}
                <SubHeaderPortal>
                    <ProAnalyticsSubHeader className="pageSection" />
                </SubHeaderPortal>
        </div>
    )
}
