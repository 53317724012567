import { useState } from 'react'
import { UserSession } from '@login/user-session'
import { Image } from 'antd'
import { CONFIG } from '@configuration/config_variables.js'
import './main_logo.scss'

const MainLogo = () => {
    const logoUrl = !window.location.host.startsWith('localhost') ? 'shared-resources/logo/large.svg' : ''
    const [showLogo, setShowLogo] = useState(true)
    const njc = UserSession?.state?.user?.njc
    const shouldDisplayLogo = njc && ('user' in njc || !('mandate' in njc))

    const environment =
        CONFIG.environment === 'production' && CONFIG.isLocalEnvironment
            ? 'Production'
            : CONFIG.environment === 'stage'
              ? 'Staging'
              : CONFIG.environment === 'development'
                ? 'Development'
                : undefined

    return (
        shouldDisplayLogo && (
            <h1 className="main-logo__logo-text">
                {showLogo && (
                    <a href="#">
                        <div className="main-logo__image-wrap">
                            <Image
                                src={logoUrl}
                                preview={false}
                                height={60}
                                placeholder={<div />}
                                onError={() => setShowLogo(false)}
                            />
                        </div>
                        {environment && (
                            <span
                                className={`environment ${environment}`}
                                ref={node => {
                                    if (node === null) return
                                    const nodeWidth = node.offsetWidth
                                    node.style.left = `-${nodeWidth - 10}px`
                                }}
                            >
                                {environment}
                            </span>
                        )}
                    </a>
                )}
            </h1>
        )
    )
}

export default MainLogo
