export let Localization
export let Authorization
export let CustomerConfig
export let UserDictionaries
export let Logger
export let UserSession
export let JobBoosterService

export const injectDependencies = dependencies => {
    Logger = dependencies.Logger
    Localization = dependencies.Localization
    CustomerConfig = dependencies.CustomerConfig
    UserDictionaries = dependencies.UserDictionaries
    Authorization = dependencies.Authorization
    UserSession = dependencies.UserSession
    JobBoosterService = dependencies.JobBoosterService
}