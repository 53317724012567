import { useState, useEffect } from 'react'
import { Spin, Empty, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { SettingsIcon } from '@assets/icons/icons'
import ErrorBoundary from '@hoc/error_boundary/error_boundary.jsx'
import Card from '@components/elements/card/card'
import ReportsWidgetCard from './report_widget_card/report_widget_card'
import './reports_widget.scss'

const ReportsWidget = ({ viewModel, locale }) => {
    const reports = viewModel?.reports?.value
    const status = viewModel?.reportsStatus?.value?.status
    const [errorMessage, setErrorMessage] = useState(viewModel.reportsStatus.value?.error)

    useEffect(() => {
        if (status && status === 'error') {
            setErrorMessage(viewModel.reportsStatus.value?.error)
        }
    }, [status])

    const xlsDownload = (reportId, reportName) => {
        viewModel.reportXls.onTrigger(reportId, reportName)
    }

    const renderReports = (arr = []) => {
        if (arr.length !== 0) {
            return arr.map(report => (
                <ReportsWidgetCard
                    report={report}
                    locale={locale}
                    key={report.id}
                    xlsIsDownloading={viewModel.reportXlsLoading.value}
                    xlsDownload={() => xlsDownload(report)}
                    redirect={id => viewModel.reportsLink.onTrigger(id.toString())}
                />
            ))
        }
    }

    return (
        <ErrorBoundary
            title="Reports"
            viewModel={viewModel}
            message={errorMessage}
            status={status}
            locale={locale}
            key={viewModel}
        >
            <Card
                title={
                    <>
                        <span>Reports</span>
                    </>
                }
                extra={
                    viewModel.reportsManagement.visible && (
                        <Button
                            shape="round"
                            onClick={viewModel.reportsManagement.onTrigger}
                            icon={<SettingsIcon className="button-icon" />}
                        >
                            {locale('reports.management')}
                        </Button>
                    )
                }
            >
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={status === 'pending'}>
                    <div className="reports-widget">
                        {reports && reports.length !== 0 ? (
                            <div className="reports-widget__wrap">{renderReports(reports)}</div>
                        ) : (
                            <Empty description={locale('noData')} style={{ marginTop: '2rem' }} />
                        )}
                    </div>
                </Spin>
            </Card>
        </ErrorBoundary>
    )
}

export default ReportsWidget
