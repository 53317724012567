import { Process, ProcessTerminationError, stateOf, terminate, useEffect, useState } from '@prospective/process-router'
import { complement } from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { errorModal } from '@components/modules/global_notifications/global_notifications.jsx'
import { Localization } from '@lib/i18n/localization'

const logger = Logger('injectPluginProcesses', CATEGORIES.MAIN)
export function injectPluginProcesses(feature, parent) {
    const [processMapping, setProcessMapping] = useState([])
    const { locale } = stateOf(Localization)

    useEffect(() => {
        const newProcesses = feature?.processes?.inject() || []
        const processes = processMapping.map(entry => entry.process)
        const removedProcesses = complement(processes, newProcesses)
        const addedProcesses = complement(newProcesses, processes)

        removedProcesses.forEach(process =>
            terminate(processMapping.find(entry => entry.process === process).instance)
        )
        const newEntries = addedProcesses.map(process => ({ process, instance: parent.child(process({})) }))
        newEntries.forEach(entry =>
            entry.instance.catch(error => {
                if (error instanceof ProcessTerminationError) return
                const { logNumber } = logger.error.withError(
                    `Plugin process '${entry.instance.label}' failed with an error`,
                    error
                )
                errorModal({
                    title: 'Error',
                    content: locale('plugins.pluginProcessError', {
                        processName: entry.instance.label,
                        logNumber,
                    }),
                })
            })
        )
        const newMapping = processMapping
            .filter(entry => !removedProcesses.includes(entry.process))
            .concat(newEntries)
        setProcessMapping(newMapping)

        return () => {
            processMapping.forEach(entry => (entry.instance.status !== Process.PENDING) && terminate(entry.instance))
        }
    }, [feature?.processes])

    return processMapping.map(entry => entry.instance)
}
