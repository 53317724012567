import { useContext } from 'react'
import { LocationContext } from '@prospective/process-router-react'
import { theme } from 'antd'
import { CONFIG } from '@configuration/config_variables.js'
import { usePluginFeatures } from '@modules/plugins/plugin_manager_react_utils'
import ProfilePanel from '@components/modules/profile_panel/profile_panel'
import AppHeaderLanguages from '@components/modules/app_header/app_header_languages'
import MainLogo from '@plugins/common/main_navigation/main_logo/main_logo.jsx'
import './app_header.scss'
import { ApplicationContext } from '@views/application/application.context.js'
import { useViewModel } from '@lib/view_context/view-model.js'

export default function ApplicationHeader({ className = '', ...props }) {
    const { token } = theme.useToken()
    const features = usePluginFeatures()
    const location = useContext(LocationContext)
    const applicationView = useViewModel(ApplicationContext)
    const compact = location?.key?.startsWith('order')

    const environment =
        CONFIG.environment === 'production' && CONFIG.isLocalEnvironment
            ? 'Production'
            : CONFIG.environment === 'stage'
              ? 'Staging'
              : CONFIG.environment === 'development'
                ? 'Development'
                : undefined

    const headerStyle = {
        backgroundColor: token.colorBgElevated,
    }

    return (
        <div
            className={`nav-header ${compact ? 'compact' : ''} ${className} ${environment}`}
            {...props}
            style={headerStyle}
            role="main-navigation"
        >
            <div className={`nav-header__content pageSection`}>
                <div className="nav-header__logo">
                    <MainLogo />
                </div>
                <div className="nav-header__nav-content">
                    {!location?.key?.startsWith('order')
                        && applicationView.authenticated.value
                        && features?.mainNavigation?.inject()}
                </div>
                <div className="nav-header__options">
                    <ProfilePanel className="profile-panel" />
                    <AppHeaderLanguages />
                </div>
            </div>
        </div>
    )
}
