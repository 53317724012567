import { getNodesByIds, includeChildNodes } from '@modules/dictionaries/organization-hierarchy'
import { haveSameElements } from '@prospective/pms-js-utils'
import { DateRange, getSearchFields } from '@views/pro_analytics/pro_analytics_utils'

/**
 * @typedef {Object} DateRangeObject
 * @property {Date} from
 * @property {Date} to
 */

/**
 * @typedef {Object} ProAnalyticsFiltersObject
 * @property {DateRangeObject} [dateRangeFilter]
 * @property {string} [textFilter]
 * @property {number[]} [organizationStructureFilter]
 * @property {number[]} [recruiterFilter]
 * @property {string[]} [mediaFilter]
 * @property {string[]} [fieldOfActivityFilter]
 * @property {string[]} [industryFilter]
 * @property {string[]} [atsFilter]
 * @property {string} [orderId]
 * @property {string} [postingId]
 * @property {string} [publicationId]
 * @property {string} [internalTitle]
 * @property {string} [externalTitle]
 */

/**
 * @typedef {Object} IProAnalyticsFilters
 * @property {function(organizationStructure: []): number[]} getOrganizationStructureIds
 * @property {function(organizationStructure: [], locale: function): {from: string, to: string, searchTerm: string, filters: Object.<string, *>}} getRequestParameters
 * @property {function} isEqual
 */

const isRequestFiltersType = Symbol('proAnalyticsRequestFilters')
/**
 * Helper interface to work with ProAnalytics filters
 * @param {ProAnalyticsFiltersObject} filters
 * @returns {ProAnalyticsFiltersObject | IProAnalyticsFilters}
 * @example
 * const filtersChanged = ProAnalyticsFiltersInterface(filters).equal(oldFilters)
 */
export const ProAnalyticsFiltersInterface = (filters = {}) => {
    if (filters[isRequestFiltersType]) return filters
    /**
     * Returns ids of organizationStructureFilter including child-nodes
     * @param organizationStructure
     * @returns {number[]}
     */
    const getOrganizationStructureIds = organizationStructure => {
        const organizationNodesWithChildNodes = includeChildNodes(
            filters.organizationStructureFilter || [],
            organizationStructure
        )
        return getNodesByIds(organizationNodesWithChildNodes, organizationStructure)
            .map(node => node.id)
    }
    /** Converts filters into ProAnalytics API params
     * @param {[]} organizationStructure
     * @param {function} locale
     * */
    const getRequestParameters = (organizationStructure, locale) => {
        const SEARCH_FIELDS = getSearchFields(locale)
        return {
            searchTerm: filters.textFilter,
            from: filters.dateRangeFilter?.from,
            to: filters.dateRangeFilter?.to,
            filters: {
                [SEARCH_FIELDS.hierarchyNodeIds.key]:
                    getOrganizationStructureIds(organizationStructure) || [],
                [SEARCH_FIELDS.recruiterId.key]: filters.recruiterFilter || [],
                [SEARCH_FIELDS.mediumId.key]: filters.mediaFilter || [],
                [SEARCH_FIELDS.fieldOfActivity.key]: filters.fieldOfActivityFilter || [],
                [SEARCH_FIELDS.industry.key]: filters.industryFilter || [],
                [SEARCH_FIELDS.atsId.key]: filters.atsFilter || [],
                [SEARCH_FIELDS.orderId.key]: filters.orderId ? [filters.orderId] : [],
                [SEARCH_FIELDS.postingId.key]: filters.postingId ? [filters.postingId] : [],
                [SEARCH_FIELDS.publicationId.key]: filters.publicationId ? [filters.publicationId] : [],
                [SEARCH_FIELDS.internalTitle.key]: filters.internalTitle ? [filters.internalTitle] : [],
                [SEARCH_FIELDS.externalTitle.key]: filters.externalTitle
                    ? [filters.externalTitle]
                    : undefined,
            },
        }
    }
    const isEqual = (otherFilters = {}) => {
        const filters2 = ProAnalyticsFiltersInterface(otherFilters)

        return (
            DateRange(filters.dateRangeFilter).equal(filters2.dateRangeFilter) &&
            filters.textFilter === filters2.textFilter &&
            filters.internalTitle === filters2.internalTitle &&
            filters.externalTitle === filters2.externalTitle &&
            filters.orderId === filters2.orderId &&
            filters.postingId === filters2.postingId &&
            filters.publicationId === filters2.publicationId &&
            haveSameElements(filters.organizationStructureFilter, filters2.organizationStructureFilter) &&
            haveSameElements(filters.recruiterFilter, filters2.recruiterFilter) &&
            haveSameElements(filters.mediaFilter, filters2.mediaFilter) &&
            haveSameElements(filters.fieldOfActivityFilter, filters2.fieldOfActivityFilter) &&
            haveSameElements(filters.industryFilter, filters2.industryFilter) &&
            haveSameElements(filters.atsFilter, filters2.atsFilter)
        )
    }
    const toObject = () => ({
        textFilter: filters.textFilter,
        internalTitle: filters.internalTitle,
        externalTitle: filters.externalTitle,
        orderId: filters.orderId,
        postingId: filters.postingId,
        publicationId: filters.publicationId,
        organizationStructureFilter: filters.organizationStructureFilter,
        recruiterFilter: filters.recruiterFilter,
        mediaFilter: filters.mediaFilter,
        fieldOfActivityFilter: filters.fieldOfActivityFilter,
        industryFilter: filters.industryFilter,
        atsFilter: filters.atsFilter,
    })
    return {
        [isRequestFiltersType]: true,
        ...filters,
        getOrganizationStructureIds,
        getRequestParameters: getRequestParameters,
        isEqual,
        toObject,
    }
}
ProAnalyticsFiltersInterface.areFiltersEqual = (filters1, filters2) =>
    ProAnalyticsFiltersInterface(filters1).isEqual(filters2)

export const areDateRangesEqual = (dateRange1, dateRange2) => DateRange(dateRange1).equal(dateRange2)
