import { CustomerConfig } from './customer_config'
import { CATEGORIES, Logger } from '@modules/logging/logger.js'
import { Localization } from '@lib/i18n/localization'
import { createErrorStatus } from '@utils/request_statuses'
import { Authorization } from '@modules/authorization/authorization'
import { ROOT_CONFIG } from '@modules/customer_config/root_config'
import JobBooster from '@services/job_booster_service'
import { Process, stateOf } from '@prospective/process-router'
import { UserSession } from '@login/user-session.js'

export const CustomerConfiguration = Process(({ process, params }) => {
    const { locale } = stateOf(Localization)
    const logger = Logger('CustomerConfig Process', CATEGORIES.MAIN)
    const isAdminUser = Authorization.isAdminUser
    const service = JobBooster

    return async () => {
        if (CustomerConfig.configurationLoaded) {
            process.exit()
            return
        }
        try {
            let settings
            if (!isAdminUser) settings = await service.getCustomerSettings(params.nodeIds)
            else settings = ROOT_CONFIG

            if (settings !== undefined)
                CustomerConfig.state = {
                    ...settings,
                    configurationIsLoading: false,
                }

            process.exit()
        } catch (err) {
            const logEntry = logger.info.withError(err, 'Could not load customer settings')

            CustomerConfig.state = {
                configurationIsLoading: false,
                error: createErrorStatus(
                    locale('proAnalytics.clientConfigErrorMessage', {
                        logNumber: logEntry.logNumber,
                    })
                ),
            }
            process.throw(err)
        }
    }
})

CustomerConfiguration.label = 'CustomerConfiguration'
