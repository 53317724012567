import { useContext } from 'react'
import { LocationContext } from '@prospective/process-router-react'
import { ConfigProvider, theme } from 'antd'
import './app_sub_header.scss'

export default function ApplicationSubHeader({ className = '', children, ...props }) {
    const { token } = theme.useToken()
    const location = useContext(LocationContext)
    const compact = location?.key?.startsWith('order')

    const subHeaderStyle = {
        backgroundColor: location?.key === 'proAnalytics' ? token.jbColorBgSecondary : token.colorBgElevated,
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: token.colorBorder,
    }

    const subHeaderTheme = {
        ...theme,
        token: {
            ...token,
            backgroundColor: token.colorBgElevated,
        },
    }

    return (
        <ConfigProvider theme={subHeaderTheme}>
            <div id="jbApplicationSubheader" className={`app-subheader ${compact ? 'compact' : ''} ${className}`} {...props} style={subHeaderStyle}>
                {/*{children}*/}
            </div>
        </ConfigProvider>
    )
}
