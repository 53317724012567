import { Tree } from '@utils/tree.js'

export const OrganizationHierarchyDescriptor = {
    getChildren: node => node?.children?.map((node, index) => ({ key: index, node })),
    setChildren: (node, children) => {
        node.children = children
        return node
    },
    isBranch: node => Boolean(node?.children?.length),
    isLeaf: node => !Boolean(node?.children?.length),
}

export const OrganizationHierarchy = hierarchy => {
    const tree = Tree({ children: hierarchy, id: null }, OrganizationHierarchyDescriptor)
    return {
        ...tree,
        findById: id => tree.find(node => node.id === id),
        findByIds: (ids = []) => tree.findNodes(node => ids.includes(node.id))
    }
}
