import { useContext } from 'react'
import { useViewModel } from '@lib/view_context/view-model'
import { ReportListContext } from '@views/settings/reports/report_list.context'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { Alert, Button, Spin, Table, Breadcrumb } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { EditIcon, RemoveIcon } from '@assets/icons/icons'
import { LoadingOutlined } from '@ant-design/icons'
import { O } from '@prospective/pms-js-utils'
import { useDebug } from '@utils/debug'
import { STATUS_PENDING } from '@utils/request_statuses'
import { jbButtonSecondaryDisabledFill, jbButtonSecondaryRestFill } from '@styles/default_theme.js'
import './report_list.scss'
import { ErrorAlert } from '@components/elements/alerts/alerts.jsx'

export default function ReportList({ className = '', ...props }) {
    const viewModel = useViewModel(ReportListContext)
    const { locale } = useContext(LocaleContext)
    const { openLogViewer, debugMode } = useDebug()
    const {languageCode} = useContext(LocaleContext)
    const nameColumn = 'name' + languageCode.charAt(0).toUpperCase() + languageCode.slice(1)
    const tableColumns = [
        {
            title: locale('ordinalNumber'),
            dataIndex: 'id',
            key: 'id',
            width: 70,
            align: 'right',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: locale('reports.reportName', {lang: languageCode}),
            dataIndex: nameColumn,
            key: nameColumn,
            ellipsis: true,
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '',
            key: 'options',
            width: 100,
            align: 'right',
            render: (test, record) => {
                const deletePending = viewModel.reportDeleteStatus?.value?.details?.[record.id] === STATUS_PENDING
                const deleteDisabled = deletePending || viewModel.deleteReport.disabled
                const editDisabled = deletePending || viewModel.editReport.disabled
                const deleteButtonFill = viewModel.deleteReport.disabled
                    ? jbButtonSecondaryDisabledFill
                    : jbButtonSecondaryRestFill
                const editButtonFill = viewModel.editReport.disabled
                    ? jbButtonSecondaryDisabledFill
                    : jbButtonSecondaryRestFill
                const editIcon = <EditIcon fill={editButtonFill} />
                const deleteIcon = deletePending ? (
                    <LoadingOutlined style={{ fontSize: 16 }} spin />
                ) : (
                    <RemoveIcon fill={deleteButtonFill} />
                )
                return (
                    <div className="reports-management__options">
                        <Button
                            type="text"
                            disabled={editDisabled}
                            icon={editIcon}
                            onClick={() => viewModel.editReport.onTrigger(record.id)}
                            className="reports-management__options__option-button"
                        />
                        <Button
                            type="text"
                            icon={deleteIcon}
                            disabled={deleteDisabled}
                            onClick={() => viewModel.deleteReport.onTrigger(record.id)}
                            className="reports-management__options__option-button"
                        />
                    </div>
                )
            },
        },
    ]

    const pageHeaderExtra = viewModel.newReport.visible && (
        <Button shape="round" disabled={viewModel.newReport.disabled} onClick={() => viewModel.newReport.onTrigger()}>
            {viewModel.newReport.label}
        </Button>
    )

    return (
        <PageHeader
            ghost={false}
            title={viewModel.title.label}
            extra={pageHeaderExtra}
            breadcrumbRender={() => <Breadcrumb items={[{ title: locale('settings') }]} />}
        >
            <div className={`reports-management ${className}`} {...props}>
                {O(viewModel.errors).size > 0 && (
                    <div className="password__errors">
                        {O(viewModel.errors)
                            .values()
                            .map(error => (
                                <Alert
                                    key={error.title}
                                    message={error.title}
                                    showIcon
                                    description={error.description}
                                    type="error"
                                    closable
                                    action={
                                        debugMode &&
                                        error.logNumber !== undefined && (
                                            <Button
                                                shape="round"
                                                danger
                                                className="password__errors-log"
                                                size="small"
                                                onClick={() => openLogViewer(error.logNumber)}
                                            >
                                                Log
                                            </Button>
                                        )
                                    }
                                />
                            ))}
                    </div>
                )}
                {viewModel.errors.insufficientPermissionsError ? (
                    <ErrorAlert error={viewModel.errors.insufficientPermissionsError} closable={false} />
                ) : (
                    <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                        spinning={viewModel.reportsRequestStatus.value?.status === 'pending'}
                    >
                        <Table
                            columns={tableColumns}
                            dataSource={viewModel.reports.value}
                            rowClassName={(item, index) => (index % 2 === 0 ? 'even' : 'odd')}
                            pagination={false}
                            scroll={{ x: '100' }}
                            locale={locale('antd', 'Table')}
                        />
                    </Spin>
                )}
            </div>
        </PageHeader>
    )
}
