import { useEffect, useState } from 'react'
import { PluginManager } from '@modules/plugins/plugin_manager'

/**
 * @example
 * const pluginFeatures = usePluginFeatures()
 * const skills = pluginFeatures.users.skills.inject()
 */
export function usePluginFeatures() {
    const [features, setFeatures] = useState({})

    const onPluginManagerUpdate = state => {
        if (state.features !== features) setFeatures(state.features)
    }

    useEffect(() => {
        PluginManager.subscribe(onPluginManagerUpdate)
        setFeatures(PluginManager.features)
        return () => {
            PluginManager.unsubscribe(onPluginManagerUpdate)
            setFeatures({})
        }
    }, [])

    return features
}

export function withPluginFeatures(Component) {
    return function (props) {
        /** @type ApplicationFeatures */
        const features = usePluginFeatures()
        return <Component {...props} features={features} />
    }
}
