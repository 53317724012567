import { useEffect, useState } from 'react'
import { Switch } from 'antd'
import { O } from '@prospective/pms-js-utils'
import { FeatureToggles } from '@utils/feature_toggle.jsx'
import './features_viewer.scss'

const FeaturesViewer = () => {
    const [features, setFeatures] = useState({})
    const [featuresSwitchState, setFeaturesSwitchState] = useState({})

    const onFeatureToggleChange = state => {
        setFeatures(state.features)
        setFeaturesSwitchState(
            O(state.features)
                .map(item => item.value)
                .valueOf()
        )
    }

    useEffect(() => {
        onFeatureToggleChange(FeatureToggles)
        FeatureToggles.subscribe(onFeatureToggleChange)
        return () => {
            FeatureToggles.unsubscribe(onFeatureToggleChange)
        }
    }, [])

    const featureHandler = (checked, key) => {
        setFeaturesSwitchState({ ...featuresSwitchState, [key]: checked })
        if (checked) FeatureToggles.features[key].on()
        if (!checked) FeatureToggles.features[key].off()
    }

    const renderFeatures = () => {
        return O(features)
            .map((features, key) => {
                return (
                    <li className="feature-viewer__list-item" key={key}>
                        <div className="featureName">{key}</div>
                        <Switch
                            checked={featuresSwitchState[key]}
                            onChange={checked => featureHandler(checked, key)}
                            data-switch={key}
                        />
                    </li>
                )
            })
            .values()
    }

    return (
        <div className="feature-viewer">
            <ul className="feature-viewer__list">{renderFeatures()}</ul>
        </div>
    )
}

export default FeaturesViewer
