import ImgixClient from '@imgix/js-core'
import { CONFIG } from '@configuration/config_variables.js'

const defaultParams = {
    auto: 'compress',
}

const service = new ImgixClient({ domain: CONFIG.IMGIX_HOST })
const concatDefaultParams = (params = {}) => ({ ...defaultParams, ...params })
export const ImgIxService = {
    concatDefaultParams,
    buildURL: (name, params = {}) => service.buildURL(name, concatDefaultParams(params))
}