import { O } from '@prospective/pms-js-utils'
import { Alert, Button, Space } from 'antd'
import { useDebug } from '@utils/debug'
import './alerts.scss'

export const ErrorAlert = ({ className='', error, onClear, closable = true, retryLabel = 'Retry', ...props }) => {
    const { openLogViewer, debugMode } = useDebug()
    return error ? <Alert
        key={error.title + error.description + error.logNumber}
        message={error.title}
        showIcon
        description={error.description}
        type="error"
        closable={closable}
        onClose={() => {
            if (error.onClear) error.onClear()
            if (onClear) onClear(error)
        }}
        action={
            <Space direction="horizontal">
                {debugMode && error.logNumber !== undefined &&
                    <Button
                        shape="round"
                        danger
                        className="alert__errors-log"
                        size="small"
                        onClick={() => openLogViewer(error.logNumber)}
                    >
                        Log
                    </Button>
                }
                {
                    error.retry && <Button
                        shape="round"
                        size="small"
                        onClick={error.retry}
                    >
                        {retryLabel}
                    </Button>
                }
            </Space>
        }
        {...props}
    /> : null
}

const Alerts = ({ className = '', errors, onClear, retryLabel = 'Retry', ...props }) => {
    return (
        <div className={`alert ${className}`} {...props} style={props.style}>
            {O(errors).size > 0 && (
                <div className="alert__errors">
                    {O(errors)
                        .values()
                        .map((error, index) => (<ErrorAlert
                            key={index}
                            error={error}
                            onClear={onClear}
                            retryLabel={retryLabel}
                        />))}
                </div>
            )}
        </div>
    )
}

export default Alerts
