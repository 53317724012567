import React from 'react'
import { Result } from 'antd'
import Card from '@components/elements/card/card'
import './error_boundary.scss'

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        error: { message: '', stack: '' },
        info: { componentStack: '' },
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        this.setState({ error, info })
    }

    render() {
        const { hasError } = this.state
        const status = this.props.status || ''

        if (hasError || status === 'error')
            return (
                <Card
                    style={{ width: '100%' }}
                    title={
                        <>
                            <span>{this.props.title}</span>
                        </>
                    }
                >
                    <Result status="warning" title="Oooops..." extra={<>{this.props.message}</>} />
                </Card>
            )

        return this.props.children
    }
}

export default ErrorBoundary
