/**
 * Breaks line if exceeds given length limit.
 * Tries not to break words.
 * Returns an array of lines
 * @param textLine {string}
 * @param limit {number}
 * @return {*[]}
 */
export const wrapLine = (textLine, limit = 30) => {
    let words = textLine.split(' ')
    const lines = []
    let line = ''
    while (words.length > 0) {
        const word = words[0]
        if (word.length > limit - line.length && word.length === limit) {
            if (line.length) lines.push(line)
            lines.push(word)
            line = ''
            words = words.slice(1)
        } else if (word.length > limit - line.length && word.length > limit) {
            const cutIndex = limit - line.length - 1
            const part = word.substr(0, cutIndex)
            line = line.length ? [line, part].join(' ') : part
            words[0] = word.substr(cutIndex)
        } else if (word.length > limit - line.length && word.length < limit) {
            lines.push(line)
            line = word
            words = words.slice(1)
        } else {
            line = line.length ? [line, word].join(' ') : word
            words = words.slice(1)
        }
        if (!words.length && line.length) lines.push(line)
    }
    return lines
}

// Removes HTML tags from a string ( sanitization )
export const removeHtmlTagsAndEntities = htmlString => {
    if (typeof htmlString !== 'string') return ''

    // Remove HTML tags
    const noTags = htmlString.replace(/<[^>]*>/g, '')

    // Create a temporary DOM element to decode HTML entities
    let tempDiv = document.createElement('div')
    tempDiv.innerHTML = noTags
    const decodedString = tempDiv.textContent || tempDiv.innerText || ''

    return decodedString
}

export const truncate = (text, length = 0) => {
    if (!text) return text
    if (length > 0 && text.length > length) return text.substring(0, length) + '…'
    return text
}

export const slugifyText = text =>
    text
        .toLowerCase()
        .trim()
        .replace(/[\s\W-]+/g, '_')
        .replace(/^-+|-+$/g, '')
