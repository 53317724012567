import JobBoosterPlugin from '@plugins/jobBooster/jobBoosterPlugin.index'
import StellencockpitPlugin from '@plugins/stellencockpit/stellencockpitPlugin.index'
import JobIQPlugin from '@plugins/jobIQ/jobIQPlugin.index'
import RecruitingHubPlugin from '@plugins/recruitingHub/recruitingHubPlugin.index'
import VDVPlugin from '@plugins/vdv/vdv.index'

export const plugins = {
    JB: {
        name: 'JobBooster',
        description: 'JobBooster-specific features (no Marketplace)',
        key: 'jobbooster',
        plugin: JobBoosterPlugin,
    },
    SC: {
        name: 'Stellencockpit Pro',
        description: 'Stellencockpit-specific features',
        key: 'stellencockpit',
        plugin: StellencockpitPlugin,
    },
    SC_APP: {
        name: 'Stellencockpit',
        description: 'Stellencockpit-specific features',
        key: 'stellencockpit',
        plugin: StellencockpitPlugin,
    },
    VDV: {
        name: 'VDV',
        description: 'VDV-specific features',
        key: 'vdv',
        plugin: VDVPlugin,
    },
    JOBIQ: { name: 'JobIQ', description: 'JobIQ-specific features', key: 'jobiq', plugin: JobIQPlugin },
    RH: {
        name: 'RecruitingHub',
        description: 'Recruiting-specific features',
        key: 'recruitinghub',
        plugin: RecruitingHubPlugin,
    },
    // PL: { name: 'Language package: Polish', description: 'Polish translations', plugin: PolishLanguagePlugin },
}
