import { tryCatch } from '@prospective/pms-js-utils'
import { UserSession } from './user-session.js'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { Process, stateOf, useCallback } from '@prospective/process-router'

export const Login = Process.Singleton(({ process }) => {
    const logger = Logger('Login', CATEGORIES.MAIN)
    const userSession = stateOf(UserSession)

    const authenticate = useCallback(async userSession => {
        if (userSession.status === UserSession.AUTHENTICATED) {
            return UserSession.user
        } else if (userSession.status === undefined) {
            const [statusCheckError, status] = await tryCatch(UserSession.checkStatus)()
            if (statusCheckError) {
                logger.error.withError(statusCheckError, 'Authentication status check failed')
                throw new Error('Authentication status check failed')
            } else if (status === UserSession.AUTHENTICATED) {
                return UserSession.user
            } else {
                return undefined
            }
        } else {
            return undefined
        }
    })

    return async () => {
        logger.info('Starting Login')
        const [error, user] = await tryCatch(authenticate)(userSession)
        if (error) process.throw(new Error('Authentication status check failed', { cause: error }))
        else if (user) process.exit({ authenticated: user })
        else process.ready()
    }
})

Login.label = 'Login'

export const Logout = Process.Singleton(({ process }) => {
    const logger = Logger('Logout', CATEGORIES.MAIN)

    return () => {
        logger.info('Starting Logout')
        UserSession.closeSession()
        process.exit({ logout: true })
        logger.info('Leaving Logout')
    }
})
Logout.label = 'Logout'
