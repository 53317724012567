import { useState, useEffect, useRef } from 'react'
import { VariableSizeGrid as Grid } from 'react-window'
import { Table } from 'antd'
import useWindowSize from '@hooks/useWindowSize'
import ResizeObserver from 'rc-resize-observer'
import './virtual_table.scss'

const TABLET_VIEW = 992
const MOBILE_VIEW = 768
const MAX_COLUMNS = 8

export function VirtualTable({ dataSource, dynamic = false, widget, ...props }) {
    const { width } = useWindowSize()
    const divRef = useRef()
    const gridRef = useRef()

    const { columns, scroll, virtualColumns } = props
    const [tableWidth, setTableWidth] = useState(0)
    const columnsWithoutExplicitWidth = columns?.filter(({ width }) => !width)
    const columnsWithExplicitWidth = columns?.filter(({ width }) => width)
    const columnsWithExplicitWidthTotalWidth = columnsWithExplicitWidth.reduce(
        (total, column) => total + column.width,
        0
    )

    // Calculate column width if `virtualColumns` is provided otherwise set a default width for each column
    const calcColumnWidth = (columnName, column, type) => {
        const title = column.title
        const columnWidth = virtualColumns !== undefined && virtualColumns?.find(column => column.title === columnName)

        if (type === 'dynamic') {
            return {
                ...column,
                title,
                width: 300,
            }
        }

        return {
            ...column,
            title,
            width: columnWidth ? columnWidth['width'][type] : 250,
        }
    }

    // If `virtualColumns` is provided calculate the width for desktop/mobile/tablet, otherwise use default values
    const mergedColumns = columns.map(column => {
        const title = column.hidden ? '' : column.title
        if (columns.length === columnsWithExplicitWidth.length && virtualColumns !== undefined) {
            if (dynamic && columns?.length >= MAX_COLUMNS) return calcColumnWidth(column.dataIndex, column, 'dynamic')

            if (columns?.length >= MAX_COLUMNS) return calcColumnWidth(column.dataIndex, column, 'desktop')
            if (width <= TABLET_VIEW) return calcColumnWidth(column.dataIndex, column, 'tablet')
            if (width <= MOBILE_VIEW) return calcColumnWidth(column.dataIndex, column, 'mobile')

            const part = parseFloat(column.width) / columnsWithExplicitWidthTotalWidth
            return { ...column, title, width: Math.floor(part * tableWidth) }
        }

        if (width <= TABLET_VIEW) return calcColumnWidth(column.dataIndex, column, 'tablet')
        if (width <= MOBILE_VIEW) return calcColumnWidth(column.dataIndex, column, 'mobile')

        return {
            ...column,
            title,
            width:
                column.width ||
                Math.floor((tableWidth - columnsWithExplicitWidthTotalWidth) / columnsWithoutExplicitWidth.length),
        }
    })

    const [connectObject] = useState(() => {
        const obj = {}
        Object.defineProperty(obj, 'scrollLeft', {
            get: () => null,
            set: scrollLeft => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({
                        scrollLeft,
                    })
                }
            },
        })
        return obj
    })

    const resetVirtualGrid = () => {
        gridRef.current?.resetAfterIndices({
            columnIndex: 0,
            shouldForceUpdate: true,
        })
    }

    useEffect(() => resetVirtualGrid, [tableWidth, dataSource, columns, mergedColumns])

    const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
        ref.current = connectObject
        const totalHeight = rawData.length * 54
        return (
            <Grid
                ref={gridRef}
                className={`virtual-grid ${columns?.length > 6 && dynamic ? 'dynamic' : ''} ${
                    columns?.length >= 6 && !dynamic ? 'expand' : ''
                }`}
                columnCount={mergedColumns.length}
                columnWidth={index => {
                    const { width } = mergedColumns[index]
                    return totalHeight > scroll.y && index === mergedColumns.length - 1
                        ? width - scrollbarSize - 1
                        : width
                }}
                height={scroll.y}
                rowCount={rawData.length}
                rowHeight={() => 39}
                width={tableWidth}
                onScroll={({ scrollLeft }) => {
                    onScroll({
                        scrollLeft,
                    })
                }}
            >
                {cell => {
                    const { columnIndex, rowIndex, style } = cell
                    const column = mergedColumns[columnIndex]
                    let classNames = 'virtual-table-cell'
                    if (columnIndex === mergedColumns.length - 1) classNames += ' virtual-table-cell-last'
                    if (props.rowClassName) classNames += ' ' + props.rowClassName(rawData[rowIndex], rowIndex)
                    return (
                        <div
                            title={column.render ? undefined : rawData[rowIndex][column.dataIndex]}
                            className={classNames}
                            style={{
                                ...style,
                                padding: 8,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textAlign: column.align,
                            }}
                        >
                            {column.render
                                ? column.render(rawData[rowIndex][column.dataIndex])
                                : rawData[rowIndex][column.dataIndex]}
                        </div>
                    )
                }}
            </Grid>
        )
    }

    return (
        <div ref={divRef}>
            <ResizeObserver
                onResize={({ width }) => {
                    setTableWidth(width)
                }}
            >
                <div>
                    <Table
                        dataSource={dataSource}
                        columns={mergedColumns}
                        style={props.style}
                        pagination={props.pagination}
                        size={props.size}
                        rowClassName={props.rowClassName}
                        className="virtual-table"
                        components={{
                            body: renderVirtualList,
                        }}
                    />
                </div>
            </ResizeObserver>
        </div>
    )
}
