import { useCallback, useContext } from 'react'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { Link } from '@prospective/process-router-react'
import { PageHeader } from '@ant-design/pro-layout'
import { useViewModel } from '@lib/view_context/view-model'
import { PasswordContext } from '@views/settings/password/password.context.js'
import { Alert, Button, Form, Input, Spin, Typography, Breadcrumb } from 'antd'
import { Route } from '@prospective/process-router'
import { routes } from '@configuration/routes'
import { O } from '@prospective/pms-js-utils'
import { LoadingOutlined } from '@ant-design/icons'
import { useDebug } from '@utils/debug.js'
import './password.scss'

const renderValidationMessages = messages => messages.map(message => <div key={message}>{message}</div>)

export default function Password({ className = '', ...props }) {
    const { locale } = useContext(LocaleContext)
    const { openLogViewer, debugMode } = useDebug()
    const viewModel = useViewModel(PasswordContext)
    const settingsPath = Route(routes.settings).toLocation().path

    const onFinish = useCallback(() => {
        const validationResult = viewModel.validateAndHighlight()
        if (O(validationResult).size === 0) {
            viewModel.newPasswordApply.onTrigger()
        }
    }, [viewModel])

    return (
        <PageHeader
            title={locale('settings.password.title')}
            ghost={false}
            onBack={viewModel.back.onTrigger}
            breadcrumbRender={() => (
                <Breadcrumb
                    items={[
                        {
                            title: <Link routePath={settingsPath}>{locale('settings')}</Link>,
                        },
                        { title: locale('settings.password') },
                    ]}
                />
            )}
        >
            <div className={`password ${className}`} {...props}>
                {O(viewModel.errors).size > 0 && (
                    <div className="password__errors">
                        {O(viewModel.errors)
                            .values()
                            .map(error => (
                                <Alert
                                    key={error.title}
                                    message={error.title}
                                    showIcon
                                    description={error.description}
                                    type="error"
                                    closable
                                    action={
                                        debugMode && (
                                            <Button
                                                danger
                                                className="password__errors-log"
                                                size="small"
                                                onClick={() => openLogViewer(error.logNumber)}
                                            >
                                                Log
                                            </Button>
                                        )
                                    }
                                />
                            ))}
                    </div>
                )}
                <div className="password__data-access">
                    <p>{locale('settings.yourAccessData')}</p>
                </div>
                <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                    spinning={viewModel.changeRequestStatus.value?.status === 'pending'}
                >
                    <div className="password__form-wrap">
                        <Form layout="vertical" onFinish={onFinish}>
                            <Form.Item
                                label={viewModel.username.label}
                                validateStatus={viewModel.username.validationStatus}
                                hasFeedback={viewModel.username.isInvalid}
                                help={renderValidationMessages(viewModel.username.validationMessages)}
                            >
                                <Typography.Text>{viewModel.username.value}</Typography.Text>
                            </Form.Item>
                            <Form.Item
                                label={viewModel.currentPassword.label}
                                validateStatus={viewModel.currentPassword.validationStatus}
                                hasFeedback={viewModel.currentPassword.isInvalid}
                                help={renderValidationMessages(viewModel.currentPassword.validationMessages)}
                            >
                                <Input
                                    type="password"
                                    placeholder={viewModel.currentPassword.placeholder}
                                    value={viewModel.currentPassword.value}
                                    onChange={event =>
                                        viewModel.currentPassword
                                            .setValue(event.target.value)
                                            .onChange(event.target.value)
                                    }
                                    onBlur={() => viewModel.currentPassword.validateAndHighlight()}
                                    suffix={
                                        <span />
                                    } /* https://ant.design/components/input/#Why-Input-lose-focus-when-change-prefix/suffix/showCount */
                                />
                            </Form.Item>
                            <Form.Item
                                label={viewModel.newPassword.label}
                                validateStatus={viewModel.newPassword.validationStatus}
                                hasFeedback={viewModel.newPassword.isInvalid}
                                help={renderValidationMessages(viewModel.newPassword.validationMessages)}
                                tooltip={viewModel.newPassword.description}
                            >
                                <Input
                                    type="password"
                                    placeholder={viewModel.newPassword.placeholder}
                                    value={viewModel.newPassword.value}
                                    onChange={event =>
                                        viewModel.newPassword.setValue(event.target.value).onChange(event.target.value)
                                    }
                                    onBlur={() => {
                                        viewModel.newPassword.validateAndHighlight()
                                        if (viewModel.newPasswordRepeat.value !== undefined)
                                            viewModel.newPasswordRepeat.validateAndHighlight()
                                    }}
                                    suffix={
                                        <span />
                                    } /* https://ant.design/components/input/#Why-Input-lose-focus-when-change-prefix/suffix/showCount */
                                />
                            </Form.Item>
                            <Form.Item
                                label={viewModel.newPasswordRepeat.label}
                                validateStatus={viewModel.newPasswordRepeat.validationStatus}
                                hasFeedback={viewModel.newPasswordRepeat.isInvalid}
                                help={renderValidationMessages(viewModel.newPasswordRepeat.validationMessages)}
                                tooltip={viewModel.newPasswordRepeat.description}
                            >
                                <Input
                                    type="password"
                                    placeholder={viewModel.newPasswordRepeat.placeholder}
                                    value={viewModel.newPasswordRepeat.value}
                                    onChange={event =>
                                        viewModel.newPasswordRepeat
                                            .setValue(event.target.value)
                                            .onChange(event.target.value)
                                    }
                                    onBlur={() => viewModel.newPasswordRepeat.validateAndHighlight()}
                                    suffix={
                                        <span />
                                    } /* https://ant.design/components/input/#Why-Input-lose-focus-when-change-prefix/suffix/showCount */
                                />
                            </Form.Item>
                            <div className="password__form-cta-wrap">
                                <Button shape="round">{viewModel.cancel.label}</Button>
                                <Button shape="round" type="primary" htmlType="submit">
                                    {viewModel.newPasswordApply.label}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Spin>
            </div>
        </PageHeader>
    )
}
