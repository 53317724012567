import { useEffect, useState, useContext } from 'react'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { ThemeManager } from '@modules/theming/theme_manager.js'
import { ConfigProvider, theme } from 'antd'
import en_US from 'antd/es/locale/en_US'
import de_DE from 'antd/es/locale/de_DE'
import it_IT from 'antd/es/locale/it_IT'
import fr_FR from 'antd/es/locale/fr_FR'

export default function ThemeProvider({ children }) {
    const { languageCode } = useContext(LocaleContext)
    const [activeTheme, setActiveTheme] = useState(ThemeManager.activeTheme.theme.antd)

    const antdInternationalization = {
        en: en_US,
        de: de_DE,
        it: it_IT,
        fr: fr_FR,
    }[languageCode]

    const onThemeChange = (themeDescriptor, darkMode) => {
        const mode = darkMode ? 'darkTheme' : 'theme'
        setActiveTheme({
            ...themeDescriptor[mode].antd,
            algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        })
    }

    useEffect(() => {
        ThemeManager.activeThemeChange.subscribe(onThemeChange)
        return () => {
            ThemeManager.activeThemeChange.unsubscribe(onThemeChange)
        }
    }, [])

    return (
        <ConfigProvider locale={antdInternationalization} theme={activeTheme}>
            {children}
        </ConfigProvider>
    )
}
