export const ROOT_CONFIG = {
    proAnalytics: {
        kpiWidget: {
            isHidden: false,
            columns: {
                printPublications: {
                    isHidden: false,
                },
                isHidden: false,
            },
        },
        costsPerMediumWidget: {
            isHidden: false,
        },
        costsWidget: {
            isHidden: false,
            columns: {
                fieldOfActivity: {
                    isHidden: false,
                },
                isHidden: false,
            },
            statistics: {
                applicateEvents: {
                    isHidden: false,
                },
                clickEvents: {
                    isHidden: false,
                },
                isHidden: false,
            },
        },
        candidateJourneyWidget: {
            isHidden: false,
            columns: {
                numberOfPublications: {
                    isHidden: false,
                },
                channelGooglePerOD: {
                    isHidden: false,
                },
                channelDisplayPerOD: {
                    isHidden: false,
                },
                channelSocialPerOD: {
                    isHidden: false,
                },
                applicationsTotal: {
                    isHidden: false,
                },
                clicksPerOD: {
                    isHidden: false,
                },
                clicksTotal: {
                    isHidden: false,
                },
                applicationsPerOD: {
                    isHidden: false,
                },
                isHidden: false,
            },
        },
        jobsCountWidget: {
            isHidden: false,
        },
        performanceWidget: {
            isHidden: false,
            columns: {
                fieldOfActivity: {
                    isHidden: false,
                },
                isHidden: false,
            },
            statistics: {
                applicateEvents: {
                    isHidden: false,
                },
                clickEvents: {
                    isHidden: false,
                },
                isHidden: false,
            },
        },
        careerCenterWidget: {
            isHidden: false,
            columns: {
                intranet: {
                    isHidden: false,
                },
                isHidden: false,
            },
            statistics: {
                intranetSearchQueries: {
                    isHidden: false,
                },
                isHidden: false,
            },
        },
        reports: {
            isHidden: false,
        },
        filters: {
            columns: {
                fieldOfActivity: {
                    isHidden: false,
                },
                isHidden: false,
            },
            isHidden: false,
        },
    },
    settings: {
        permissions: {
            read: true,
            write: true,
        },
        personalSettings: {
            locale: {
                permissions: {
                    read: true,
                    write: true,
                },
            },
        },
        reports: {
            permissions: {
                read: false,
                write: false,
                execute: false,
            },
        },
    },
}
