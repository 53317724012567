export const STATUS_IDLE = 'idle'
export const STATUS_PENDING = 'pending'
export const STATUS_ERROR = 'error'
export const STATUS_SUCCESS = 'success'

export const isPending = statusObject => statusObject?.status === STATUS_PENDING
export const isError = statusObject => statusObject?.status === STATUS_ERROR

/**
 * Creates a status object of status STATUS_IDLE
 * @param {*} [details] Any additional useful information (i.e. id of an item being fetched from a service)
 * @returns {{progress: number, error: undefined, status: string, details: *}}
 */
export const createIdleStatus = details => ({
    status: STATUS_IDLE,
    error: undefined,
    progress: 0,
    details,
})
/**
 * Creates a status object of status STATUS_PENDING
 * @param {number} progress Between 0 and 1
 * @param {*} [details] Any additional useful information (i.e. id of an item being fetched from a service)
 * @returns {{progress: number, error: undefined, status: string, details: *}}
 */
export const createPendingStatus = (progress = 0, details) => ({
    status: STATUS_PENDING,
    error: undefined,
    progress,
    details,
})
/**
 * Creates a status object of status STATUS_SUCCESS
 * @param {*} [details] Any additional useful information (i.e. id of an item being fetched from a service)
 * @returns {{progress: number, error: undefined, status: string, details: *}}
 */
export const createSuccessStatus = details => ({
    status: STATUS_SUCCESS,
    error: undefined,
    progress: 1,
    details,
})
/**
 * Creates a status object of status STATUS_ERROR
 * @param {(any|{logNumber: (string|number), error: string})} error
 * @param {*} [details] Any additional useful information (i.e. id of an item being fetched from a service)
 * @returns {{error, status: string, details: *}}
 */
export const createErrorStatus = (error, details) => ({ status: STATUS_ERROR, error, details })

/**
 * Creates a new AbortController object instance
 * @param {*} [currentController]
 * @returns {AbortController}
 */
export const abortAndCreateNewController = currentController => {
    if (currentController) currentController.abort()
    return new AbortController()
}

/**
 * Structures given status error into an object:
 * ```
 * { title: string, description: string, logNumber: string|number }
 * ```
 * @param {function} locale
 * @param {{ error: string, logNumber: (string|number) }} statusError
 * @returns {{description: string, title: string, logNumber: (string|number)}|undefined}
 */
export const getErrorStructure = (locale, statusError) => statusError
    ? ({
        title: locale('error'),
        description: statusError.error,
        logNumber: statusError.logNumber,
    })
    : undefined


export const DEFAULT_IDLE_STATUS = Object.freeze(createIdleStatus())
