import { useCallback, useEffect, useState } from '@prospective/process-router'
import { O } from '@prospective/pms-js-utils'

let viewContextsRegister = new Map()
const hasErrors = validationMessages => O(validationMessages).values().some(errors => errors.length)

const getUpdateInterface = context => {
    const metadata = {}
    const values = {}
    const eventListeners = {}
    const fields = O(context.metadata).map((descriptor, field) => {
        metadata[field] = {}
        eventListeners[field] = {}
        const fieldInterface = {
            get default() {
                return descriptor.default
            },
            set default(defaultValue) {
                metadata[field].default = defaultValue
            },
            get value() {
                return values[field]
            },
            set value(fieldValue) {
                values[field] = fieldValue
            },
            get isLoading() {
                return metadata[field].isLoading
            },
            set isLoading(value) {
                metadata[field].isLoading = value
            },
            get dictionary() {
                return metadata[field].dictionary
            },
            set dictionary(value) {
                metadata[field].dictionary = value
            },
            get getDictionary() {
                return metadata[field].getDictionary
            },
            set getDictionary(value) {
                metadata[field].getDictionary = value
            },
            get disabled() {
                return metadata[field].disabled
            },
            set disabled(value) {
                metadata[field].disabled = value
            },
            get visible() {
                return metadata[field].visible
            },
            set visible(value) {
                metadata[field].visible = value
            },
            get label() {
                return metadata[field].label
            },
            set label(value) {
                metadata[field].label = value
            },
            get validators() {
                return metadata[field].validators
            },
            set validators(value) {
                metadata[field].validators = value
            },
            get status() {
                return metadata[field].status
            },
            set status(value) {
                metadata[field].status = value
            },
            get placeholder() {
                return metadata[field].placeholder
            },
            set placeholder(value) {
                metadata[field].placeholder = value
            },
            get onChange() {
                return eventListeners[field].onChange?.at(0)
            },
            set onChange(listener) {
                eventListeners[field].onChange = [listener]
            }
        }
        descriptor.actions?.forEach(action => {
            Reflect.defineProperty(fieldInterface, action, {
                get: () => eventListeners[field][action]?.at(0),
                set: listener => eventListeners[field][action] = [listener]
            })
        })
        return fieldInterface
    }).valueOf()
    Reflect.defineProperty(fields, 'set', {
        get: () => (fieldName, descriptor) => {
            const { value, ...fieldMetadata } = descriptor
            metadata[fieldName] = fieldMetadata
            values[fieldName] = value
            eventListeners[fieldName] = {}
            if (!metadata[fieldName].actions) metadata[fieldName].actions = []
            O(descriptor.actionListeners)?.forEach((listener, action) => {
                eventListeners[fieldName][action] = [listener]
                if (!metadata[fieldName].actions.includes(action))
                    metadata[fieldName].actions.push(action)
            })
        },
    })
    return { metadata, values, eventListeners, fields }
}

/**
 * @type IUseViewHook
 */
export const useView = context => {
    const [mutableState] = useState({ update: undefined, eventListeners: {} })
    const [processReady, setProcessReady] = useState(false)

    const updateEventListeners = (context, eventListeners) => {
        context.removeEventListeners(mutableState.eventListeners)
        context.addEventListeners(eventListeners)
        mutableState.eventListeners = eventListeners
    }

    const onValuesChange = useCallback(values => mutableState.update?.(values))

    const update = useCallback(updateFunction => {
        // if (!processReady) return
        const { metadata, values, eventListeners, fields } = getUpdateInterface(context)
        const errors = {}
        updateFunction(fields, errors)
        if (processReady) updateEventListeners(context, eventListeners)
        context.updateState({ metadata, values, errors })
    })

    const subscribe = useCallback(context => {
        if (context)
            context.onValuesChange.subscribe(onValuesChange)
    })

    const unsubscribe = useCallback(context => {
        if (context)
            context.onValuesChange.unsubscribe(onValuesChange)
    })

    useEffect(() => {
        subscribe(context)
        const hookInstances = viewContextsRegister.get(context) || 0
        viewContextsRegister.set(context, hookInstances + 1)
        setProcessReady(true)
        return () => {
            setProcessReady(false)
            unsubscribe(context)
            updateEventListeners(context,{})
            const hookInstances = viewContextsRegister.get(context) || 0
            viewContextsRegister.set(context, hookInstances - 1)
            if (!viewContextsRegister.get(context))
                context.reset()
        }
    }, [context])

    return {
        update,
        onUpdate: callback => mutableState.update = callback,
        validateValues: context.validateValues,
        validate: context.validate,
        validationMessages: context.validationMessages,
        get isValid() { return !hasErrors(context.validationMessages) },
        updateValidationErrors: context.updateValidationErrors,
        clearValidation: context.clearValidation,
        reset: context.reset,
        get values() { return context.values }
    }
}
