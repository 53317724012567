import { Publisher } from '@prospective/pms-js-utils'
import { Logger } from '@modules/logging/logger.js'
import { generatorFromPromises, Stream } from '@lib/stream/stream2.js'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import JobBoosterService from '@services/job_booster_service.js'

const logger = Logger('Dictionaries')
let dictionaries

export const DEFAULT_DICTIONARIES = {
    organizationStructure: [],
    orderStates: [],
    mediaFilters: [],
    countries: [
        { code: 'CH', de: 'Schweiz', en: 'Switzerland', fr: 'Suisse', it: 'Svizzera' },
        { code: 'DE', de: 'Deutschland', en: 'Germany', fr: 'Allemagne', it: 'Germania' },
        { code: 'FR', de: 'Frankreich', en: 'France', fr: 'France', it: 'Francia' },
        { code: 'IT', de: 'Italien', en: 'Italy', fr: 'Italie', it: 'Italia' },
        { code: 'AT', de: 'Oesterreich', en: 'Austria', fr: 'Autriche', it: 'Austria' },
        { code: 'GB', de: 'Grossbritannien', en: 'United Kingdom', fr: 'Angleterre', it: 'Gran Bretagna' },
        { code: 'US', de: 'USA', en: 'USA', fr: 'USA', it: 'USA' },
        { code: 'ES', de: 'Spanien', en: 'Spain', fr: 'Espagne', it: 'Spagna' },
        { code: 'BE', de: 'Belgien', en: 'Belgium', fr: 'Belgique', it: 'Belgio' },
        { code: 'NL', de: 'Niederlande', en: 'Netherlands', fr: 'Netherlands', it: 'Netherlands' },
        { code: 'CZ', de: 'Tschechien', en: 'Czech Republic', fr: 'Czech Republic', it: 'Czech Republic' },
        { code: 'HU', de: 'Ungarn', en: 'Hungary', fr: 'Hungary', it: 'Hungary' },
        { code: 'PL', de: 'Polen', en: 'Poland', fr: 'Pologne', it: 'Poland' },
        { code: 'DK', de: 'Dänemark', en: 'Denmark', fr: 'Denmark', it: 'Denmark' },
        { code: 'HR', de: 'Kroatien', en: 'Croatia', fr: 'Croatia', it: 'Croatia' },
        { code: 'LI', de: 'Liechtenstein', en: 'Liechtenstein', fr: 'Liechtenstein', it: 'Liechtenstein' },
        { code: 'CA', de: 'Kanada', en: 'Canada', fr: 'Canada', it: 'Canada' },
        { code: 'BR', de: 'Brasilien', en: 'Brazil', fr: 'Brazil', it: 'Brazil' },
        { code: 'ZA', de: 'Südafrika', en: 'South Africa', fr: 'South Africa', it: 'South Africa' },
        { code: 'ID', de: 'Indonesien', en: 'Indonesien', fr: 'Indonesien', it: 'Indonesien' },
        { code: 'AU', de: 'Australien', en: 'Australia', fr: 'Australie', it: 'Australia' },
        { code: 'LU', de: 'Luxemburg', en: 'Luxemburg', fr: 'Luxemburg', it: 'Luxemburg' },
        { code: 'KY', de: 'Cayman Islands', en: 'Cayman Islands', fr: 'Cayman Islands', it: 'Cayman Islands' },
        { code: 'BS', de: 'Bahamas', en: 'Bahamas', fr: 'Bahamas', it: 'Bahamas' },
        { code: 'GB', de: 'Guernsey', en: 'Guernsey', fr: 'Guernsey', it: 'Guernsey' },
        { code: 'HK', de: 'Hong Kong', en: 'Hong Kong', fr: 'Hong Kong	Hong Kong' },
        {
            code: 'AE',
            de: 'United Arab Emirates',
            en: 'United Arab Emirates',
            fr: 'United Arab Emirates',
            it: 'United Arab Emirates',
        },
        { code: 'FI', de: 'Finland', en: 'Finland', fr: 'Finland', it: 'Finland' },
        { code: 'MX', de: 'Mexiko', en: 'Mexico', fr: 'Mexico', it: 'Mexico' },
        { code: 'IN', de: 'Indien', en: 'India', fr: 'India', it: 'India' },
        { code: 'IE', de: 'Irland', en: 'Ireland', fr: 'Ireland', it: 'Ireland' },
        { code: 'CN', de: 'China', en: 'China', fr: 'China', it: 'China' },
        { code: 'NO', de: 'Norwegen', en: 'Norway', fr: 'Norvège', it: 'Norvegia' },
        { code: 'SE', de: 'Schweden', en: 'Sweden', fr: 'Suède', it: 'Svezia' },
        { code: 'SK', de: 'Slovakei', en: 'Slovakia', fr: 'Slovaquie', it: 'Slovakia' },
        { code: 'KR', de: 'Südkorea', en: 'South Korea', fr: 'Corée du Sud', it: 'Corea de Sud' },
        { code: 'BG', de: 'Bulgarien', en: 'Bulgaria', fr: 'Bulgarie', it: 'Bulgaria' },
        { code: 'SG', de: 'Singapur', en: 'Singapore', fr: 'Singapour', it: 'Singapore' },
        { code: 'UY', de: 'Uruguay', en: 'Uruguay', fr: 'Uruguay', it: 'Uruguay' },
        { code: 'MY', de: 'Malaysia', en: 'Malaysia', fr: 'Malaysia', it: 'Malaysia' },
        { code: 'RU', de: 'Russland', en: 'Russland', fr: 'Russland', it: 'Russland' },
        { code: 'MK', de: 'Nordmazedonien', en: 'Nordmazedonien', fr: 'Nordmazedonien', it: 'Nordmazedonien' },
        { code: 'JP', de: 'Japan', en: 'Japan', fr: 'Japon', it: 'Japan' },
        { code: 'RS', de: 'Serbien', en: 'Serbia', fr: 'Serbie', it: 'Serbia' },
        { code: 'BY', de: 'Weissrussland', en: 'Belarus', fr: 'Biélorussie', it: 'Bielorussia' },
        { code: 'TR', de: 'Türkei', en: 'Turkey', fr: 'Turquie', it: 'Turchia' },
        { code: 'PT', de: 'Portugal', en: 'Portugal', fr: 'Portugal', it: 'Portogallo' },
        { code: 'CY', de: 'Zypern', en: 'Cyprus', fr: 'Chypre', it: 'Cipro' },
        { code: 'UA', de: 'Ukraine', en: 'Ukraine', fr: 'Ukraine', it: 'Ukraine' },
        { code: 'GR', de: 'Griechenland', en: 'Greece', fr: 'Grèce', it: 'Grecia' },
        { code: 'RO', de: 'Rumänien', en: 'Romania', fr: 'Roumanie', it: 'Romania' },
    ],
    contactPersons: undefined,
    countryOfBusiness: undefined,
}

const filterActiveNodes = hierarchy =>
    hierarchy
        ?.filter(node => node.isActive !== false)
        .filter(node => node.hasOwnProperty('children'))
        .map(node => ({ ...node, children: filterActiveNodes(node.children) }))

const getHierarchyStructure = () =>
    JobBoosterService
        .getHierarchyNodes()
        .then(result => ({ organizationStructure: filterActiveNodes(result) }))
        .catch(error => Promise.reject(logger.error.withError(error, 'Loading organization structure failed.')))

/*const getLoggedInUser = () => JobBoosterService
    .getLoggedInUser()
    .then(user => ({ user }))
    .catch(error => Promise.reject(logger.error.withError(error, 'Loading user details failed.')))*/

const getOrderStates = () =>
    JobBoosterService
        .getOrderStates()
        .then(orderStates => ({ orderStates }))
        .catch(error => Promise.reject(logger.error.withError(error, 'Loading order states failed.')))

const getMediaFilters = () =>
    JobBoosterService
        .getMediaFilters()
        .then(mediaFilters => ({ mediaFilters }))
        .catch(error => Promise.reject(logger.error.withError(error, 'Loading media filters failed.')))

const loadObserver = Stream.Observer(state => {
    dictionaries = state.value || {}
    publishChange(Dictionaries)
})
const loadDictionaries = Stream(async function* (featureToggle) {
    logger.info('Loading dictionaries...')
    yield RemoteData.pending()

    const tasks = []
    tasks.push(getHierarchyStructure())
    /*if (featureToggle.features.cockpit.value ||
            featureToggle.features.profiles.value ||
            featureToggle.features.mediaList.value
        )
            tasks.push(getLoggedInUser())*/
    tasks.push(getOrderStates())
    if (featureToggle.features.mediaList.value) tasks.push(getMediaFilters())

    const tasksTotal = tasks.length
    const logs = []
    let tasksCompleted = 0
    let dictionaries = {}

    const loadingTasks = Stream.fromPromises.allSettled(tasks)
    for await (const task of loadingTasks) {
        tasksCompleted = tasksCompleted + 1
        if (task.status === 'fulfilled') {
            dictionaries = Object.assign(dictionaries, task.value)
            yield RemoteData.pending(tasksCompleted / tasksTotal).setValue(dictionaries)
        }
        if (task.status === 'rejected') logs.push(task.reason)
    }

    if (logs.length || tasksCompleted !== tasksTotal) {
        const logNumbers = logs.map(logEntry => logEntry.logNumber).join(', ')
        return RemoteData.error(`Some dictionaries could not be loaded. More details in logs ${logNumbers}`)
    }
    logger.info('Dictionaries loaded.')
    return RemoteData.setValue(dictionaries).success()
}).compose(loadObserver)

const reset = () => {
    dictionaries = DEFAULT_DICTIONARIES
}

reset()

const [change, publishChange] = Publisher()
export const Dictionaries = {
    ...change,
    get reset() {
        return reset
    },
    get state() {
        return dictionaries
    },
    get countries() {
        return dictionaries.countries
    },
    get mediaLists() {
        return dictionaries.mediaFilters
    },
    get orderStates() {
        return dictionaries.orderStates
    },
    get organizationStructure() {
        return dictionaries.organizationStructure
    },
    set organizationStructure(value) {
        dictionaries = { ...dictionaries }
        dictionaries.organizationStructure = value
        publishChange(Dictionaries)
    },
    get user() {
        return dictionaries.user
    },
    get contactPersons() {
        return dictionaries.contactPersons
    },
    set contactPersons(value) {
        if (value === dictionaries.contactPersons) return
        dictionaries = { ...dictionaries }
        dictionaries.contactPersons = value
        publishChange(Dictionaries)
    },
    getDictionary: dictionaryName => dictionaries[dictionaryName],
    setDictionary: (dictionaryName, value) => {
        dictionaries = { ...dictionaries }
        dictionaries[dictionaryName] = value
        publishChange(Dictionaries)
    },
    setDictionaries: newDictionaries => {
        dictionaries = { ...dictionaries }
        dictionaries = Object.assign(dictionaries, newDictionaries)
        publishChange(Dictionaries)
    },
    updateDictionary: (dictionaryName, updatedDictionary) => {
        dictionaries = { ...dictionaries }
        dictionaries[dictionaryName] = updatedDictionary
        publishChange(Dictionaries)
    },
    loadDictionaries,
}
