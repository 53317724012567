import { UserSession } from '../../login/user-session'

export function OAuthInterceptor() {
    return {
        intercept: request => {
            if (UserSession.isValid) {
                const cloned = request.clone()
                cloned.headers.set('Authorization', `Bearer ${UserSession.token}`)
                return cloned
            }
            return request
        },
    }
}
