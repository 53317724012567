import { useEffect, useState } from 'react'
import { Divider, Radio, Space, Switch } from 'antd'
import { ThemeManager } from '@modules/theming/theme_manager.js'

export default function ThemesViewer({ className = '', ...props }) {
    const [themes, setThemes] = useState([])
    const [activeTheme, setActiveTheme] = useState(null)
    const [darkMode, setDarkMode] = useState(false)

    const onChange = event => {
        const theme = themes.find(theme => theme.name === event.target.value)
        ThemeManager.update(({setActiveTheme}) => {
            setActiveTheme(theme)
        })
    }

    const onDarkModelChange = value =>
        ThemeManager.update(({setDarkMode}) => setDarkMode(value))

    const onThemesUpdate = ({themes}) => {
        setThemes([...ThemeManager.themes])
        setDarkMode(ThemeManager.darkMode)
        setActiveTheme(ThemeManager.activeTheme)
    }

    useEffect(() => {
        ThemeManager.subscribe(onThemesUpdate)
        setThemes([...ThemeManager.themes])
        setDarkMode(ThemeManager.darkMode)
        setActiveTheme(ThemeManager.activeTheme)
        return () => ThemeManager.unsubscribe(onThemesUpdate)
    }, [])

    return (
        <div className={`themes-viewer ${className}`} {...props}>
            <Radio.Group onChange={onChange} value={activeTheme?.name} optionType="default">
                <Space direction="vertical">
                    {
                        themes.map(theme => (
                                <Radio.Button
                                    key={theme.name}
                                    value={theme.name}
                                >
                                    <div>
                                        <div className='label' style={{ fontWeight: 'bold' }}>{theme.label}</div>
                                        <div className='description'>{theme.description}</div>
                                    </div>
                                </Radio.Button>
                        ))
                    }
                </Space>
            </Radio.Group>
            <Divider/>
            <Switch checked={darkMode} onChange={onDarkModelChange} checkedChildren="Light" unCheckedChildren="Dark" />
        </div>
    )
}