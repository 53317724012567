import { Localization } from '@lib/i18n/localization'
import { View } from '@lib/view_context/view-context'

const InvoicesMetadata = locale => {
    return {
        tasks: {},
        selectOrganizationInfo: {
            label: locale('settings.selectOrganization'),
            visible: false,
        },
        invoices: {
            label: locale('settings.invoices'),
            isLoading: undefined,
        },
        pdfDownload: {
            label: locale('pdfDownload'),
            isLoading: undefined,
            actions: ['onTrigger'],
        },
        back: {
            actions: ['onTrigger'],
        },
    }
}

// prettier-ignore
export const InvoicesContext = View
    .observe(Localization)
    .update((localization) => InvoicesMetadata(localization.locale)
)
