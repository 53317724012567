import { LogLevel } from '@intermattory/logging'
import {CONFIG} from '../../../configuration/config_variables'

export const LogzIOTarget = {
    log(entry) {
        const body = {
            ...entry,
            APP: 'JobBooster',
            message: entry.message.join(' '),
            date: entry.date.toISOString(),
            userEmail: entry.userEmail,
            loglevel: LogLevel.getLevelName(entry.level),
            userEmail: entry.userEmail
        }
        if (entry.error) {
            body.message = `${entry.message} ${entry.error.message}`
            if (entry.error.stack) body.stackTrace = entry.error.stack
            if (entry.error.cause) body.cause = entry.error.cause
        }

        fetch(CONFIG.logzIOEndpoint, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'omit',
            headers: {
                'Content-Type': 'text/plain',
            },
            body: JSON.stringify(body),
        })
            // .then((data) => { console.debug('Message logged', data) })
            .catch((error) => {
                console.debug('Log error', error)
            })
    },
}
