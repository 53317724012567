import { JobBoosterSymbol } from '@structures/jobbooster_symbol.js'
import { O } from '@prospective/pms-js-utils'

/**
 * Utility interface to work with permission structure
 * @param permissions
 * @returns {{map: (function(*): *)}|*}
 * @example
 * const permissions = {
 *     settings: {
 *         permissions: {
 *             read: true,
 *             write: false
 *         },
 *         users: {
 *             permissions: {
 *                 read: false,
 *                 write: true
 *             }
 *         },
 *         notifications: {
 *             permissions: {
 *                 read: false,
 *                 write: false
 *             }
 *         }
 *     },
 *     dashboard: {
 *         permissions: {
 *             read: true,
 *             write: false
 *         }
 *     }
 * }
 * const readPermissions = Permissions(permissions)
 *     .map((node, path) => node.read)
 * // readPermissions:
 * // {
 * //    settings: {
 * //        permissions: true,
 * //        users: false,
 * //        notifications: false
 * //    },
 * //    dashboard: {
 * //        permissions: true
 * //    }
 * // }
 */
export const Permissions = permissions => {
    if (permissions[JobBoosterSymbol.Type] === Permissions)
        return permissions
    const map = mappingFunction => {
        const mapNode = (node, path) => O(node).map((child, key) => {
            if (key === 'permissions')
                return mappingFunction(child, path.join('.'), node)
            return mapNode(child, [...path, key])
        }).valueOf()

        return mapNode(permissions, [])
    }
    return {
        [JobBoosterSymbol.Type]: Permissions,
        map
    }
}
