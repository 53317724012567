import { Localization } from '@lib/i18n/localization'
import { View } from '@lib/view_context/view-context.js'

const NotificationsMetadata = () => {
    return {
        back: {
            actions: ['onTrigger'],
        },
    }
}

export const NotificationsContext = View.observe(Localization).update(localization =>
    NotificationsMetadata(localization.locale)
)
