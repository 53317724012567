import { useContext } from 'react'
import { EyeOutlined, FileExcelOutlined } from '@ant-design/icons'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import './report_widget_card.scss'

const ReportsWidgetCard = ({ report, redirect, xlsDownload, xlsIsDownloading, locale }) => {
    const { languageCode } = useContext(LocaleContext)
    const isDownloading = id => xlsIsDownloading && xlsIsDownloading.id === id

    const nameColumn = 'name' + languageCode.charAt(0).toUpperCase() + languageCode.slice(1)
    const descriptionColumn = 'description' + languageCode.charAt(0).toUpperCase() + languageCode.slice(1)

    return (
        <div className="reports-widget-card">
            <div className="reports-widget-card__header">
                <div className="reports-widget-card__title">
                    <h3>{report[nameColumn]}</h3>
                </div>
            </div>
            <div className="reports-widget-card__content">
                <div className="reports-widget-card__description">
                    <p>{report[descriptionColumn]}</p>
                </div>
            </div>
            <div className="reports-widget-card__cta">
                <div className="reports-widget-card__cta-item">
                    <p onClick={() => redirect(report.id)}>
                        <EyeOutlined />
                        {locale('reports.viewReport')}
                    </p>
                </div>
                <div className="reports-widget-card__cta-item">
                    <p onClick={xlsDownload} className={`${isDownloading(report.id) ? 'isDownloading' : ''}`}>
                        <FileExcelOutlined />
                        <span>{locale('xlsDownload')}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ReportsWidgetCard
