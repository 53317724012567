import { View } from '@lib/view_context/view-context'
import { Localization } from '@lib/i18n/localization'

export const ExitConfirmationMetadata = locale => {
    return {
        cancel: {
            label: locale('cancel'),
            actions: ['onTrigger'],
            visible: true,
        },
        discard: {
            label: locale('discard'),
            actions: ['onTrigger'],
            visible: true,
        },
        save: {
            label: locale('save'),
            actions: ['onTrigger'],
            visible: false,
        },
        isActive: {},
    }
}

export const ExitConfirmationContext = View.observe(Localization).update(localization =>
    ExitConfirmationMetadata(localization.locale)
)
