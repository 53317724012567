
function isEmpty(value) {
    return value === null || value === undefined || value === ''
}

// TODO Looks complicated. Can we simplify this? Where is it used?
export const isObjectEmpty = obj => {
    // Check if the given value is an object (but not an array)
    if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
        for (let key in obj) {
            // If a nested object, recursively check its values
            if (typeof obj[key] === 'object') {
                if (!isObjectEmpty(obj[key])) {
                    return false
                }
            }
            // If not an object, check if it's empty
            else if (!isEmpty(obj[key])) {
                return false
            }
        }
    }
    // If not an object (or an array), check if the value is empty
    else if (!isEmpty(obj)) {
        return false
    }
    return true
}
