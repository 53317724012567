import { lengthValidator, requiredValidator } from '@prospective/pms-view-context'
import { Localization } from '@lib/i18n/localization'
import { Authorization } from '@modules/authorization/authorization'
import { PluginManager } from '@modules/plugins/plugin_manager'
import { View } from '@lib/view_context/view-context'
import { CONFIG, COUNTRY_OF_BUSINESS } from '@configuration/config_variables'

const CompanyInfoMetadata = (locale, permissions, plugin) => {
    return {
        tasks: {},
        company: {
            isLoading: undefined,
        },
        companyName: {
            label: locale('companyName'),
            placeholder: locale('pleaseEnter'),
            actions: ['onChange'],
            disabled: true,
            validators: [
                requiredValidator(locale('validator.companyInfo.required')),
                lengthValidator(locale('validator.companyInfo.lengthError'), 0, 255),
            ],
        },
        street: {
            label: locale('streetNumber'),
            placeholder: locale('pleaseEnter'),
            actions: ['onChange'],
            disabled: false,
            validators: [
                requiredValidator(locale('validator.street.required')),
                lengthValidator(locale('validator.street.lengthError'), 0, 255),
            ],
        },
        additionalAddress: {
            label: locale('additionalAddressOptional'),
            placeholder: locale('pleaseEnter'),
            actions: ['onChange'],
            disabled: false,
            validators: [lengthValidator(locale('validator.additionalInformation.lengthError'), 0, 255)],
        },
        postalCode: {
            label: locale('postalCode'),
            placeholder: locale('pleaseEnter'),
            actions: ['onChange'],
            disabled: false,
            validators: [requiredValidator(locale('validator.postalCode.required'))],
        },
        city: {
            label: locale('city'),
            placeholder: locale('pleaseEnter'),
            actions: ['onChange'],
            disabled: false,
            validators: [requiredValidator(locale('validator.city.required'))],
        },
        state: {
            label:
                CONFIG.countryOfBusiness === COUNTRY_OF_BUSINESS.SWITZERLAND
                    ? locale('canton')
                    : CONFIG.countryOfBusiness === COUNTRY_OF_BUSINESS.GERMANY
                      ? locale('federatedState')
                      : locale('administrativeDivision.state'),
            placeholder: locale('pleaseEnter'),
            actions: ['onChange'],
            disabled: plugin?.companyInformation?.fields?.state?.inject()?.disabled,
            validators: [
                requiredValidator(locale('validator.state.required', { state: plugin?.app?.countryState?.inject() })),
            ],
        },
        country: {
            label: locale('country'),
            placeholder: locale('pleaseEnter'),
            actions: ['onChange'],
            disabled: plugin?.companyInformation?.fields?.country?.inject()?.disabled,
            visible: plugin?.companyInformation?.fields?.country?.inject()?.visible,
            validators: [requiredValidator(locale('validator.country.required'))],
            dictionary: [
                { id: 1, label: locale('country.switzerland'), value: 'CH' },
                { id: 2, label: locale('country.germany'), value: 'DE' },
                { id: 3, label: locale('country.italy'), value: 'IT' },
                { id: 4, label: locale('country.france'), value: 'FR' },
                { id: 4, label: locale('country.austria'), value: 'AT' },
            ],
        },
        submit: {
            label: locale('save'),
            actions: ['onTrigger'],
            isLoading: undefined,
        },
        selectOrganizationInfo: {
            label: locale('settings.selectOrganization'),
            visible: false,
        },
        back: {
            actions: ['onTrigger'],
        },
    }
}

// prettier-ignore
export const CompanyInfoContext = View
    .observe(Localization, Authorization, PluginManager)
    .update((localization, authorization, plugin) => CompanyInfoMetadata(localization.locale, authorization.permissions, plugin.features)
)
