/**
 * @typedef {Object} Suggestion
 * @property {string} value Unique suggestion value
 * @property {string} match
 * @property {Suggestion[]} children
 * @property {string} searchTerm Search phrase for which this suggestion has been found
 * @property {string} [group] Contains group name if it's a branch-node
 */

/**
 * @typedef {Object} SearchSuggestionsInterface
 * @property {function(value: string)} findByValue Searches whole suggestions structure for a suggestion with given value
 * @property {function(predicate: function)} find Searches whole suggestions structure for a suggestion with given value
 * @property {function(): Suggestion[]} valueOf Returns plain suggestions structure
 */

/**
 * A helper interface to work with search suggestions structure.
 * @param {Suggestion[]} suggestions
 * @return {SearchSuggestionsInterface}
 * @constructor
 */
export function SearchSuggestions(suggestions = []) {
	return {
		find: predicate => {
			let result
			if (!predicate) throw new Error(`Invalid argument 'predicate'`)
			result = suggestions.find(predicate)
			if (!result)
				result = suggestions
					.filter(suggestion => suggestion.group)
					.map(group => group.suggestions)
					.flat().find(predicate)
			return result
		},
		/**
		 * @param {string} value
		 * @returns {*}
		 */
		findByValue: value => SearchSuggestions(suggestions).find(suggestion =>  suggestion.value === value),
		valueOf: () => suggestions
	}
}

