import { useEffect, useRef, useState } from 'react'
import { arrayTarget } from '@modules/logging/logger'
import { LogLevel } from '@intermattory/logging'
import { Table } from 'antd'
import './log_viewer.scss'
import { distinct } from '@prospective/pms-js-utils'

const renderErrorResponse = response => {
    if (response?.status)
        return <>
            {response.status} {response.error} <br/>
            <span style={{ fontWeight: 'bold' }}>Path:</span> {response.path} <br/>
            <span style={{ fontWeight: 'bold' }}>Timestamp:</span> {response.timestamp} <br/>
            <span style={{ fontWeight: 'bold' }}>Message:</span> {response.message} <br/>
        </>
    return JSON.stringify(response)
}

export default function LogViewer({ className = '', selectedLogNumber, ...props }) {
    const [log, setLog] = useState([])
    const tableReference = useRef(null)

    const columns = [
        { dataIndex: 'logNumber', title: '#', width: 50, align: 'right', fixed: true },
        {
            dataIndex: 'level',
            title: 'Level',
            width: 80,
            render: (level) => LogLevel.getLevelName(level),
            filters: distinct(log.map(entry => entry.level)).map(level => ({ text: LogLevel.getLevelName(level), value: level })),
            onFilter: (value, record) => record.level === value
        },
        {
            dataIndex: 'date',
            title: 'Date/time',
            align: 'left',
            render: (date) => date.toLocaleString(),
        },
        {
            dataIndex: 'source',
            title: 'Source',
            align: 'left',
            filters: distinct(log.map(entry => entry.source)).map(source => ({ text: source, value: source })),
            onFilter: (value, record) => record.source === value
        },
        {
            dataIndex: 'category',
            title: 'Category',
            align: 'left',
            width: 140,
            filters: distinct(log.map(entry => entry.category)).map(category => ({ text: category, value: category })),
            onFilter: (value, record) => record.category === value
        },
        {
            dataIndex: 'message',
            title: 'Message',
            align: 'left',
            render: (message, record) => <><div>{message.join('')}</div> <div>{record.error?.message?.toString()}</div></>,
        },
        {
            dataIndex: 'error',
            title: 'Error',
            align: 'left',
            render: (error) =>
                typeof error === 'object' && error !== null && Reflect.has(error, 'stack')
                ? error.stack
                : error?.toString(),
        },
        {
            dataIndex: 'error',
            title: 'Cause',
            align: 'left',
            render: error => renderErrorResponse(error?.cause?.message)
        },
    ]

    const defaultFilters = {
        logNumber: '',
        level: '',
        date: '',
        source: '',
        category: '',
        message: '',
        error: '',
    }
    const [filters, setFilters] = useState(defaultFilters)

    const logChangeListener = () => setLog([...arrayTarget.array])

    const getRowClassName = (entry) => {
        const logLevel = LogLevel.getLevelName(entry.level)
        return isNaN(parseInt(logLevel)) ? logLevel.toLowerCase() : ''
    }

    const filter = (log) => {
        return log.filter(
            (entry) =>
                (filters.logNumber !== '' ? entry.logNumber == filters.logNumber : true) &&
                (filters.level !== ''
                    ? LogLevel.getLevelName(entry.level)
                          .toLowerCase()
                          .search(filters.level.toLowerCase()) > -1
                    : true) &&
                (filters.date !== ''
                    ? entry.date.toLocaleString().search(filters.date) > -1
                    : true) &&
                (filters.source !== ''
                    ? entry.source.toLocaleString().search(filters.source) > -1
                    : true) &&
                (entry.category && filters.category !== ''
                    ? entry.category.toLocaleString().search(filters.category) > -1
                    : true) &&
                (filters.message !== ''
                    ? entry.message
                          .toLocaleString()
                          .toLowerCase()
                          .search(filters.message.toLowerCase()) > -1
                    : true) &&
                (entry.error && filters.error !== ''
                    ? entry.error.toLocaleString().search(filters.error) > -1
                    : true)
        )
    }

    useEffect(() => {
        arrayTarget.subscribe(logChangeListener)
        setLog(arrayTarget.array)
        return () => {
            arrayTarget.unsubscribe(logChangeListener)
        }
    }, [])

    useEffect(() => {
        if (selectedLogNumber !== undefined)
            tableReference.current?.scrollTo({ key: selectedLogNumber })
    }, [selectedLogNumber, tableReference.current])

    // const updateFilter = (filterName) => (event) => {
    //     setFilters({ ...filters, [filterName]: event.target.value })
    // }

    const filteredLog = filter(log)

    return (
        <div className={`log-viewer ${className}`} {...props}>
            <Table
                ref={tableReference}
                dataSource={log}
                columns={columns}
                rowKey={(entry) => entry?.logNumber}
                pagination={false}
                scroll={{ x: '100vw', y: 700 }}
                size="small"
                rowClassName={(entry) => getRowClassName(entry)}
            />
        </div>
    )
}
