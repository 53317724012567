/**
 * Reduces duplicates in given array
 * Requires an initial value
 * @param result
 * @param item
 * @returns {*[]}
 * @example
 * const input = ['Orange', 'Apple', 'Plum', 'Apple', 'Grapes', 'Orange']
 * const output = input.reduce(ArrayReducers.duplicatesReducer, [])
 */
const duplicatesReducer = (result, item) => result.includes(item) ? result : [...result, item]

/**
 *
 * @param sum
 * @param value
 * @returns {*}
 * @example
 * const input = [1, 2, 3, 4, 5]
 * const output = input.reduce(ArrayReducers.sum)
 */
const sum = (sum, value) => sum + value

export const ArrayReducers = {
    duplicatesReducer,
    sum
}
