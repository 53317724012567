import { useContext } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Link } from '@prospective/process-router-react'
import { Spin, Button, Input, Alert, Row, Col, Form, Select, Breadcrumb } from 'antd'
import { CompanyInfoContext } from '@views/settings/company_info/company_info.context'
import { useViewModel } from '@lib/view_context/view-model'
import { LoadingOutlined, EditOutlined } from '@ant-design/icons'
import { routes } from '@configuration/routes'
import { Route } from '@prospective/process-router'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { usePluginFeatures } from '@modules/plugins/plugin_manager_react_utils.jsx'
import Alerts, { ErrorAlert } from '@components/elements/alerts/alerts'
import TasksStatus from '@components/elements/tasks_status/tasks_status.jsx'
import '@views/settings/company_info/company_info.scss'

const renderValidationMessages = messages => messages?.map((message, index) => <div key={index}>{message}</div>)

const CompanyInfo = () => {
    const { locale } = useContext(LocaleContext)
    const pluginFeatures = usePluginFeatures()

    const [form] = Form.useForm()
    const viewModel = useViewModel(CompanyInfoContext)

    const isCompanyInfoLoading = viewModel.company.isLoading?.status === 'pending'
    const isFormSubmissionLoading = viewModel.submit.isLoading?.status === 'pending'
    const settingsPath = Route(routes.settings).toLocation().path

    return (
        <PageHeader
            title={locale('companyInformation')}
            ghost={false}
            onBack={viewModel.back.onTrigger}
            breadcrumbRender={() => (
                <Breadcrumb
                    items={[
                        {
                            title: <Link routePath={settingsPath}>{locale('settings')}</Link>,
                        },
                        { title: locale('settings.companyInformation') },
                    ]}
                />
            )}
        >
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isCompanyInfoLoading}>
                <div className="company-information">
                    <div className={`media-list__wrap ${isCompanyInfoLoading ? 'isLoading' : ''}`}>
                        {viewModel.selectOrganizationInfo.visible ? (
                            <Alert
                                className="organization-info"
                                message={viewModel.selectOrganizationInfo.label}
                                description={viewModel.selectOrganizationInfo.description}
                                type="info"
                                showIcon
                            />
                        ) : viewModel.errors?.hasOwnProperty('companyInfoError') ? (
                            <Alerts errors={viewModel.errors} />
                        ) : viewModel.errors.insufficientPermissionsError ? (
                            <ErrorAlert error={viewModel.errors.insufficientPermissionsError} closable={false} />
                        ) : (
                            <>
                                <Alerts errors={viewModel.errors} />
                                <Form
                                    className="company-info__form"
                                    layout="vertical"
                                    form={form}
                                    onFinish={viewModel.submit.onTrigger}
                                >
                                    <Row gutter={[24, 8]}>
                                        <Col span={24} style={{ textAlign: 'right' }}>
                                            <Button
                                                className="company-info__submit-btn"
                                                htmlType="submit"
                                                shape="round"
                                                type="primary"
                                                loading={isFormSubmissionLoading}
                                                disabled={isFormSubmissionLoading}
                                            >
                                                <EditOutlined />
                                                {viewModel.submit.label}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 8]}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={viewModel.companyName?.label}
                                                validateStatus={viewModel.companyName?.validationStatus}
                                                hasFeedback={viewModel.companyName?.isInvalid}
                                                help={renderValidationMessages(
                                                    viewModel.companyName?.validationMessages
                                                )}
                                            >
                                                <Input
                                                    value={viewModel.companyName?.value}
                                                    placeholder={viewModel.companyName?.placeholder}
                                                    disabled={viewModel.companyName.disabled}
                                                    suffix={<span />}
                                                    onBlur={() => viewModel.companyName?.validate()}
                                                    onChange={event =>
                                                        viewModel.companyName?.setValue(event.target.value)
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 8]}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={viewModel.street?.label}
                                                validateStatus={viewModel.street?.validationStatus}
                                                hasFeedback={viewModel.street?.isInvalid}
                                                help={renderValidationMessages(viewModel.street?.validationMessages)}
                                            >
                                                <Input
                                                    value={viewModel.street?.value}
                                                    placeholder={viewModel.street?.placeholder}
                                                    disabled={viewModel.street.disabled}
                                                    suffix={<span />}
                                                    onBlur={() => viewModel.street?.validate()}
                                                    onChange={event => viewModel.street?.setValue(event.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={viewModel.additionalAddress?.label}
                                                validateStatus={viewModel.additionalAddress?.validationStatus}
                                                hasFeedback={viewModel.additionalAddress?.isInvalid}
                                                help={renderValidationMessages(
                                                    viewModel.additionalAddress?.validationMessages
                                                )}
                                            >
                                                <Input
                                                    value={viewModel.additionalAddress?.value}
                                                    placeholder={viewModel.additionalAddress?.placeholder}
                                                    disabled={viewModel.additionalAddress.disabled}
                                                    suffix={<span />}
                                                    onBlur={() => viewModel.additionalAddress?.validate()}
                                                    onChange={event =>
                                                        viewModel.additionalAddress?.setValue(event.target.value)
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 8]}>
                                        <Col span={viewModel.country.visible ? 6 : 8}>
                                            <Form.Item
                                                label={viewModel.postalCode?.label}
                                                validateStatus={viewModel.postalCode?.validationStatus}
                                                hasFeedback={viewModel.postalCode?.isInvalid}
                                                help={renderValidationMessages(
                                                    viewModel.postalCode?.validationMessages
                                                )}
                                            >
                                                {pluginFeatures?.app?.locationSearch?.inject({
                                                    value: viewModel.postalCode.value,
                                                    placeholder: viewModel.postalCode.placeholder,
                                                    disabled: viewModel.postalCode.disabled,
                                                    error: locale('postalCode.errorMessage'),
                                                    onBlur: viewModel.postalCode.validate,
                                                    onChange: value => {
                                                        if (!value) {
                                                            viewModel.postalCode.setValue(undefined)
                                                            return
                                                        }
                                                        viewModel.postalCode.setValue(value)
                                                    },
                                                    onSelect: location => {
                                                        viewModel.postalCode.setValue(location.zip)
                                                        viewModel.city.setValue(location.city)
                                                        viewModel.state.setValue(location.region)
                                                        viewModel.country.setValue(location.countryCode)
                                                    },
                                                })}
                                            </Form.Item>
                                        </Col>
                                        <Col span={viewModel.country.visible ? 6 : 8}>
                                            <Form.Item
                                                label={viewModel.city?.label}
                                                validateStatus={viewModel.city?.validationStatus}
                                                hasFeedback={viewModel.city?.isInvalid}
                                                help={renderValidationMessages(viewModel.city?.validationMessages)}
                                            >
                                                <Input
                                                    value={viewModel.city?.value}
                                                    placeholder={viewModel.city?.placeholder}
                                                    disabled={viewModel.city.disabled}
                                                    suffix={<span />}
                                                    onBlur={() => viewModel.city?.validate()}
                                                    onChange={event => viewModel.city?.setValue(event.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={viewModel.country.visible ? 6 : 8}>
                                            <Form.Item
                                                label={viewModel.state?.label}
                                                validateStatus={viewModel.state?.validationStatus}
                                                hasFeedback={viewModel.state?.isInvalid}
                                                help={renderValidationMessages(viewModel.state?.validationMessages)}
                                            >
                                                <Input
                                                    value={viewModel.state?.value}
                                                    placeholder={viewModel.state?.placeholder}
                                                    disabled={viewModel.state.disabled}
                                                    suffix={<span />}
                                                    onBlur={() => viewModel.state?.validate()}
                                                    onChange={event => viewModel.state?.setValue(event.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {viewModel.country.visible && (
                                            <Col span={6}>
                                                <Form.Item
                                                    label={viewModel.country?.label}
                                                    validateStatus={viewModel.country?.validationStatus}
                                                    hasFeedback={viewModel.country?.isInvalid}
                                                    help={renderValidationMessages(
                                                        viewModel.country?.validationMessages
                                                    )}
                                                >
                                                    <Select
                                                        value={viewModel.country.value}
                                                        placeholder={viewModel.country.placeholder}
                                                        disabled={viewModel.country.disabled}
                                                        onBlur={() => viewModel.country?.validate()}
                                                        onChange={val => viewModel.country?.setValue(val)}
                                                        options={viewModel.country?.dictionary.map(dictionary => ({
                                                            label: dictionary.label,
                                                            value: dictionary.value,
                                                        }))}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </Row>
                                </Form>
                            </>
                        )}
                    </div>
                </div>
            </Spin>
            <TasksStatus tasks={viewModel.tasks.dictionary} />
        </PageHeader>
    )
}

export default CompanyInfo
