import { useContext } from 'react'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { PageHeader } from '@ant-design/pro-layout'
import { useViewModel } from '@lib/view_context/view-model'
import { NotificationsContext } from '@views/settings/notifications/notifications.context'
import NotificationsList from '@views/settings/notifications/notification_list/notification_list'
import './notifications.scss'

const Notifications = ({ className = '', ...props }) => {
    const { locale } = useContext(LocaleContext)
    const viewModel = useViewModel(NotificationsContext)

    return (
        <PageHeader title={locale('notifications')} ghost={false} onBack={viewModel.back.onTrigger}>
            <div className={`notifications ${className}`} {...props}>
                <NotificationsList locale={locale} />
            </div>
        </PageHeader>
    )
}

export default Notifications
