
import { Switch, Select } from 'antd'
import './notification.scss'

const Notification = ({ text, toggled, dropdown_options }) => {
    return (
        <div className="notification-item">
            <div className="notification-item__title">
                <div className="notification-item__title-switch">
                    <Switch defaultChecked={toggled} />
                </div>
                <div className="notification-item__title-text">
                    <p>{text}</p>
                </div>
            </div>
            <div className="notification-item__dropdown">
                <span className="notification-item__label">Label</span>
                <Select style={{ width: 250 }}>
                    {dropdown_options.map(option => (
                        <Select.Option value={option.value} key={option.id}>
                            {option.text}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}

export default Notification
