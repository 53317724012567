export const jbBrand1 = '#08255f'
export const jbBrand2 = '#284998'
export const jbBrand3 = '#5d7bb6'
export const jbBase1 = '#ffffff'
export const jbBase2 = '#ecf2f5'
export const jbBase3 = '#dce6ea'
export const jbBase4 = '#bcccd1'
export const jbUi1 = '#000000'
export const jbUi2 = '#262a34'
export const jbUi3 = '#474c5a'
export const jbUi4 = '#646b7a'
export const jbUi5 = '#818897'

export const jbGraph1 = '#7bdbf6'
export const jbGraph2 = '#93aef0'
export const jbGraph3 = '#90e1bd'
export const jbGraph4 = '#edb5e7'
export const jbGraph5 = '#b7ccd6'
export const jbGraph6 = '#e3e978'
export const jbGraph7 = '#e0d8c4'

export const jbStatusErrorBackground = '#e6a3ae'
export const jbStatusErrorFill = '#c01935'
export const jbStatusErrorText = '#ac162f'

export const jbStatusSuccessBackground = '#b2d4c0'
export const jbStatusSuccessFill = '#007230'
export const jbStatusSuccessText = '#00662b'

export const jbStatusWarningBackground = '#fcefb2'
export const jbStatusWarningFill = '#f5cc00'
export const jbStatusWarningText = '#dcb700'

export const jbStatusPendingBackground = '#fcefb2'
export const jbStatusPendingFill = '#f5cc00'
export const jbStatusPendingText = '#dcb700'

export const jbButtonSecondaryDisabledBackground = '#fff'
export const jbButtonSecondaryDisabledFill = jbBase4
export const jbButtonSecondaryDisabledText = jbUi5

export const jbButtonSecondaryRestBackground = '#fff'
export const jbButtonSecondaryRestFill = jbUi1
export const jbButtonSecondaryRestText = jbUi1

export const jbButtonPrimaryRestText = jbBase1