import { useEffect, useState } from 'react'
import { Switch } from 'antd'
import { distinct, O } from '@prospective/pms-js-utils'
import { PluginManager } from '@modules/plugins/plugin_manager'
import './plugins_viewer.scss'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import { ErrorAlert } from '@components/elements/alerts/alerts.jsx'

const PluginsViewer = ({ style, ...props }) => {
    const [allPlugins, setAllPlugins] = useState([])
    const [pluginsSwitchState, setPluginsSwitchState] = useState({ Admin: true })
    const [loadError, setLoadError] = useState()

    const onPluginsChange = ({ plugins, activePlugins }) => {
        setAllPlugins(plugins)
        setPluginsSwitchState(
            O(plugins)
                .map((plugin, key) => activePlugins.includes(key))
                .valueOf()
        )
    }

    useEffect(() => {
        onPluginsChange(PluginManager)
        PluginManager.subscribe(onPluginsChange)
        return () => PluginManager.unsubscribe(onPluginsChange)
    }, [])

    const pluginHandler = (checked, plugin) => {
        setLoadError(null)
        PluginManager.setActivePlugins(checked
            ? distinct([...PluginManager.activePlugins, plugin])
            : PluginManager.activePlugins.filter(activePlugin => plugin !== activePlugin)
        ).catch(error => setLoadError(RemoteData.error('Failed to activate/deaktivate plugins')))
    }

    const renderPlugins = () => {
        return O(allPlugins)
            .map((plugin, key) => (
                <li className="plugins-viewer__list-item" key={key}>
                    <div className="pluginName">{plugin.name}</div>
                    <div className="pluginDescription">{plugin.description}</div>
                    <Switch
                        checked={pluginsSwitchState[key] || false}
                        onChange={checked => pluginHandler(checked, key)}
                    />
                </li>
            ))
            .values()
    }

    return (
        <div className="plugins-viewer" style={style} {...props}>
            <ErrorAlert error={loadError?.getErrorDetails()} />
            <ul className="plugins-viewer__list">{renderPlugins()}</ul>
        </div>
    )
}

export default PluginsViewer
