import { useContext } from 'react'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'

export default function Help({ className = '', ...props }) {
    const { locale } = useContext(LocaleContext)
    return (
        <div className={`Help ${className}`} {...props}>
            <h1>{locale.help}</h1>
        </div>
    )
}
