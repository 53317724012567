
import { Card as AntCard } from 'antd'
import './card.scss'

export default function Card({ className = 'jbCard', children, ...props }) {
    return (
        <AntCard className={`Card ${className}`} {...props}>
            {children}
        </AntCard>
    )
}
