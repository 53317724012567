import { useEffect, useRef, useState } from 'react'

const px = value => `${value}px`

const defaultMaximizedStyle = {
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    maxHeight: null,
    maxWidth: null,
    width: null,
    height: null,
    zIndex: 5,
}

export const Maximizable = Component => props => {
    const [maximized, setMaximized] = useState(false)
    const [mounted, setMounted] = useState(false)
    const animationRef = useRef(null)
    const containerRef = useRef(null)
    const defaultStyle = { width: '100%' }
    const [style, setStyle] = useState(defaultStyle)
    const [placeholderStyle, setPlaceholderStyle] = useState({})
    const [viewportOffset, setViewportOffset] = useState(null)
    const [viewportOffsetMaximized, setViewportOffsetMaximized] = useState(null)
    const [animationEndHandler, setAnimationEndHandler] = useState(null)
    const [maximizable, setMaximizable] = useState({
        animationRef,
        containerRef,
        isMaximized: maximized,
        setMaximized,
        style,
        placeholderStyle,
        animating: false,
        get onAnimationEnd() {
            return animationEndHandler
        },
        set onAnimationEnd(value) {
            setAnimationEndHandler(() => value)
        },
    })

    useEffect(() => {
        if (!mounted) return
        if (maximized) {
            const maximizedStyle = props.maximizedStyle || defaultMaximizedStyle
            const style = {
                ...defaultStyle,
                ...(props.style || {}),
                ...maximizedStyle,
                position: 'fixed',
            }
            const currentViewportOffset = containerRef.current.getBoundingClientRect()
            const placeholderStyle = {
                minHeight: currentViewportOffset.height,
                maxHeight: currentViewportOffset.height,
                minWidth: currentViewportOffset.width,
                maxWidth: currentViewportOffset.width,
            }
            setViewportOffset(currentViewportOffset)
            setPlaceholderStyle(placeholderStyle)
            setStyle(style)
            setMaximizable({
                animationRef,
                containerRef,
                isMaximized: maximized,
                setMaximized,
                style,
                placeholderStyle,
                animating: true,
                get onAnimationEnd() {
                    return animationEndHandler
                },
                set onAnimationEnd(value) {
                    setAnimationEndHandler(() => value)
                },
            })
        } else {
            const newViewportOffset = animationRef.current.getBoundingClientRect()
            const style = {
                ...defaultStyle,
                ...(props.style || {}),
                position: null,
            }

            const keyframes = [
                {
                    left: px(viewportOffsetMaximized.left),
                    top: px(viewportOffsetMaximized.top),
                    width: px(viewportOffsetMaximized.width),
                    height: px(viewportOffsetMaximized.height),
                },
                {
                    left: px(newViewportOffset.left),
                    top: px(newViewportOffset.top),
                    width: px(newViewportOffset.width),
                    height: px(newViewportOffset.height),
                },
            ]
            const animation = containerRef.current.animate(keyframes, {
                duration: 250,
                easing: 'ease-in',
            })
            animation.onfinish = () => {
                setStyle(style)
                setPlaceholderStyle({})
                if (animationEndHandler) {
                    animationEndHandler({
                        isMaximized: maximized,
                        style,
                        placeholderStyle,
                        animationRef,
                        containerRef,
                    })
                }
            }

            setTimeout(() => {
                setMaximizable({
                    animationRef,
                    containerRef,
                    isMaximized: maximized,
                    setMaximized,
                    style,
                    placeholderStyle,
                    animating: false,
                    get onAnimationEnd() {
                        return animationEndHandler
                    },
                    set onAnimationEnd(value) {
                        setAnimationEndHandler(() => value)
                    },
                })
            }, 250)
        }
    }, [maximized])

    useEffect(() => {
        if (maximized) {
            const viewportOffsetMaximized = containerRef.current.getBoundingClientRect()
            setViewportOffsetMaximized(viewportOffsetMaximized)
            const keyframes = [
                {
                    left: px(viewportOffset.left),
                    top: px(viewportOffset.top),
                    width: px(viewportOffset.width),
                    height: px(viewportOffset.height),
                },
                {
                    left: px(viewportOffsetMaximized.left),
                    top: px(viewportOffsetMaximized.top),
                    width: px(viewportOffsetMaximized.width),
                    height: px(viewportOffsetMaximized.height),
                },
            ]
            const animation = containerRef.current.animate(keyframes, {
                duration: 250,
                easing: 'ease-out',
            })
            animation.onfinish = () => {
                if (animationEndHandler)
                    animationEndHandler({
                        isMaximized: maximized,
                        style,
                        placeholderStyle,
                        animationRef,
                        containerRef,
                    })
            }
            setTimeout(() => {
                setMaximizable({
                    animationRef,
                    containerRef,
                    isMaximized: maximized,
                    setMaximized,
                    style,
                    placeholderStyle,
                    animating: false,
                    get onAnimationEnd() {
                        return animationEndHandler
                    },
                    set onAnimationEnd(value) {
                        setAnimationEndHandler(() => value)
                    },
                })
            }, 250)
        }
    }, [style])

    useEffect(() => {
        setMounted(true)
    }, [])

    useEffect(() => {
        const handleEscape = e => {
            if (maximized && e.key === 'Escape') setMaximized(false)
        }
        window.addEventListener('keydown', handleEscape)

        return () => {
            window.removeEventListener('keydown', handleEscape)
        }
    }, [maximized])

    // useEffect(() => {
    //     setMaximizable({
    //         animationRef,
    //         containerRef,
    //         isMaximized: maximized,
    //         setMaximized,
    //         style,
    //         placeholderStyle,
    //         get onAnimationEnd() {
    //             return animationEndHandler
    //         },
    //         set onAnimationEnd(value) {
    //             setAnimationEndHandler(() => value)
    //         },
    //     })
    // }, [animationRef, containerRef, placeholderStyle, animationEndHandler])

    return <Component maximizable={maximizable} {...props} />
}
