import React, { useEffect, useState } from 'react'
import {
	defaultLocalizationState,
	Localization,
	mergeLocalizationResources,
	mergeTagTables
} from '@lib/i18n/localization.js'
import { PluginManager } from '@modules/plugins/plugin_manager'
import { resources, TAG_TABLE } from '@lib/i18n/resources.js'

export const LocaleContext = React.createContext({ ...Localization.state })
LocaleContext.displayName = 'LocaleContext'

export function LocalizationProvider(props) {
	const [localization, setLocalization] = useState({...defaultLocalizationState})

	const onLocalizationChange = localization => {
		setLocalization({...localization})
	}

	const onPluginResourcesChange = (pluginManagerState) => {
		Localization.resources = mergeLocalizationResources(resources, pluginManagerState.localizationResources)
		Localization.tagTable = mergeTagTables(TAG_TABLE, pluginManagerState.tagTable)
	}

	useEffect(() => {
		Localization.change.subscribe(onLocalizationChange)
		PluginManager.onLocalizationResourcesChange.subscribe(onPluginResourcesChange)
		onPluginResourcesChange(PluginManager.state)
		setLocalization(Localization.state)
		return () => {
			Localization.change.unsubscribe(onLocalizationChange)
			PluginManager.onLocalizationResourcesChange.unsubscribe(onPluginResourcesChange)
		}
	}, [])

	useEffect(() => {
		Localization.tagTable = props.tagTable
	}, [props.tagTable])

	return <LocaleContext.Provider value={localization}>{props.children}</LocaleContext.Provider>;
}