import { CATEGORIES, Logger } from '@modules/logging/logger.js'
import { distinct, O, tryCatch, tryCatchSync } from '@prospective/pms-js-utils'
import { Authorization, DEFAULT_PERMISSIONS } from '@modules/authorization/authorization'
import { Process, stateOf } from '@prospective/process-router'
import { FeatureToggles } from '@utils/feature_toggle.jsx'
import { UserSession } from '@login/user-session'
import { Dictionaries } from '@modules/dictionaries/dictionaries'
import JobBoosterService from '@services/job_booster_service'

const structureProAnalyticsPermissions = (serviceResponse = { grantedWidgets: [] }) => ({
    permissions: {
        read: !!serviceResponse.grantedWidgets.length,
    },
    performance: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('performanceWidget'),
        },
    },
    kpi: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('kpiWidget'),
        },
    },
    costsPerMedium: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('costsPerMediumWidget'),
        },
    },
    costs: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('costsWidget'),
        },
    },
    candidateJourney: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('candidateJourneyWidget'),
        },
    },
    numberOfPositions: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('jobsCountWidget'),
        },
    },
    positionMarket: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('careerCenterWidget'),
        },
    },
    reports: {
        permissions: {
            read: serviceResponse.grantedWidgets.includes('reports'),
        },
    },
})

const structureSettingsPermissions = (paPermissions, globalPermissions) => {
    return {
        permissions: {
            read: true,
        },
        reports: {
            permissions: {
                read: paPermissions.grantedAuthorities.includes('READ_REPORT'),
                write: paPermissions.grantedAuthorities.includes('WRITE_REPORT'),
                execute: paPermissions.grantedAuthorities.includes('PERFORM_REPORT_QUERY'),
            },
        },
        invoices: {
            permissions: {
                read: true,
                write: true,
            },
        },
        profiles: {
            permissions: {
                read: globalPermissions?.MITARBEITER_TYP_READ,
                write: globalPermissions?.MITARBEITER_TYP_WRITE,
                delete: globalPermissions?.MITARBEITER_TYP_WRITE,
            },
            systemUser: {
                permissions: {
                    read: true,
                    write: globalPermissions?.MITARBEITER_TYP_SYSUSER_WRITE,
                    delete: globalPermissions?.MITARBEITER_TYP_SYSUSER_WRITE,
                },
            },
        },
        notifications: {
            permissions: {
                read: true,
                write: true,
            },
        },
        applicationChannel: {
            permissions: {
                read: true,
                write: true,
            },
        },
        companyInformation: {
            permissions: {
                read: true,
                write: true,
            },
        },
        jobBoard: {
            permissions: {
                read: true,
                write: true,
            },
        },
        inviteUser: {
            permissions: {
                read: true,
                write: true,
            },
        },
        imageLibrary: {
            permissions: {
                read: globalPermissions?.MITARBEITER_TYP_READ,
                write: globalPermissions?.MITARBEITER_TYP_WRITE,
                delete: globalPermissions?.MITARBEITER_TYP_WRITE,
            },
        },
    }
}

const structureMediaListPermissions = globalPermissions => {
    // Whether the user can read/write media lists at all.
    const readWriteMediaLists =
        globalPermissions?.STAT_PRAEFERENZLISTE_STD_VIEW ||
        globalPermissions?.STAT_PRAEFERENZLISTE_STD ||
        globalPermissions?.PRAEFERENZLISTE_PERSOENLICHE ||
        globalPermissions?.STAT_PRAEFERENZLISTE_VIEW ||
        globalPermissions?.STAT_PRAEFERENZLISTE
    return {
        permissions: {
            read: readWriteMediaLists ?? false,
            write: readWriteMediaLists ?? false,
        },
        companyDefaultList: {
            permissions: {
                read: globalPermissions?.STAT_PRAEFERENZLISTE_STD_VIEW ?? false,
                write: globalPermissions?.STAT_PRAEFERENZLISTE_STD ?? false,
            },
        },
        personalList: {
            permissions: {
                read:
                    (globalPermissions?.PRAEFERENZLISTE_PERSOENLICHE || globalPermissions?.STAT_PRAEFERENZLISTE_VIEW) ??
                    false,
                write: globalPermissions?.STAT_PRAEFERENZLISTE ?? false,
            },
        },
        mediaFlags: {
            permissions: {
                read: globalPermissions?.STAT_PRAEFERENZLISTE_STD_VIEW ?? false,
                write: globalPermissions?.PRAEFERENZLISTE_AUTOBOOKING ?? false,
            },
        },
    }
}

const structureOrdersPermissions = (response = { grantedAuthorities: [], grantedRoles: [] }) => ({
    permissions: {
        read: true,
        write: true,
        delete: true,
        execute: true,
    },
})

const logger = Logger('getProAnalyticsPermissions', CATEGORIES.MAIN)
export const PermissionsProcess = Process(({ process }) => {
    const service = JobBoosterService
    const userSession = stateOf(UserSession)

    const featureToggle = stateOf(FeatureToggles)
    const companyNodeId = userSession.companyNodeId
    const companyAdmin = Dictionaries?.user?.companyAdmin

    const getPermissions = async () => {
        logger.info('Starting GetProAnalyticsPermissions')
        if (Authorization.permissions !== DEFAULT_PERMISSIONS && companyNodeId === Authorization.currentCompanyId)
            process.exit(Authorization.state)
        else {
            const fetchPermissions =
                companyAdmin && companyNodeId
                    ? tryCatch(service.getPermissions)(companyNodeId)
                    : !companyAdmin
                      ? tryCatch(service.getPermissions)(companyNodeId)
                      : undefined

            const requests = [
                tryCatch(service.getProAnalyticsPermissions)(),
                featureToggle.features.cockpit.value || Authorization.isAdminUser ? fetchPermissions : undefined,
            ]

            const [
                [paPermissionsRequestError, paPermissionsResponse],
                [globalPermissionsRequestError = null, globalPermissionsResponse = null] = [],
            ] = await Promise.all(requests)

            let paPermissions = paPermissionsResponse
            if (paPermissionsRequestError) {
                logger.error.withError(paPermissionsRequestError, `Loading PA permissions failed.`)
                paPermissions = { grantedWidgets: [] }
            }

            if (globalPermissionsRequestError) {
                logger.error.withError(globalPermissionsRequestError, `Loading global permissions failed.`)
                process.throw('Could not get global permissions')
                return
            }

            const [proAnalyticsPermissionsError, proAnalyticsPermissions] = tryCatchSync(
                structureProAnalyticsPermissions
            )(paPermissions)

            const [settingsPermissionsError, settingsPermissions] = tryCatchSync(structureSettingsPermissions)(
                paPermissionsResponse,
                globalPermissionsResponse
            )

            // Modify this function once we have backend availability for order permissions
            const orderPermissions = structureOrdersPermissions()

            const cockpitPermissions = {
                permissions: {
                    read: orderPermissions.permissions.read,
                    write: orderPermissions.permissions.write,
                },
            }

            const [mediaListPermissionsError, mediaListPermissions] =
                tryCatchSync(structureMediaListPermissions)(globalPermissionsResponse)

            if (proAnalyticsPermissionsError) {
                logger.error.withError(proAnalyticsPermissionsError, 'Could not map received ProAnalytics permissions')
                process.throw('Could not map received ProAnalytics permissions')
            }

            if (settingsPermissionsError) {
                logger.error.withError(settingsPermissionsError, 'Could not map received settings permissions')
                process.throw('Could not map received settings permissions')
            }

            if (mediaListPermissionsError) {
                logger.error.withError(mediaListPermissionsError, 'Could not map received media list permissions')
                process.throw('Could not map received media list permissions')
            }

            const nextPermissions = {
                ...Authorization.permissions,
                proAnalytics: proAnalyticsPermissions,
                cockpit: cockpitPermissions,
                orders: orderPermissions,
                settings: O(Authorization.permissions.settings).deepMerge(settingsPermissions).valueOf(),
                mediaLists: mediaListPermissions,
                application: {
                    permissions: {
                        read: true,
                    },
                },
            }

            Authorization.state = {
                permissions: nextPermissions,
                currentCompanyId: companyNodeId,
                roles: distinct([...Authorization.roles, ...paPermissionsResponse.grantedRoles]),
            }

            process.exit(Authorization.state)
        }
    }

    return async () => {
        await getPermissions()
    }
})

PermissionsProcess.label = 'Permissions'
