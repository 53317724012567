import { useContext } from 'react'
import { Alert, Button, Result } from 'antd'
import { useViewModel } from '@lib/view_context/view-model.js'
import { ProAnalyticsReportContext } from '@views/pro_analytics/pro_analytics_report/pro_analytics_report.context'
import { ProAnalyticsReportFilters } from '@views/pro_analytics/pro_analytics_report/pro_analytics_report_filters/pro_analytics_report_filters'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { useCustomerConfig } from '@modules/customer_config/customer_config'
import ReportsWidget from '@views/pro_analytics/reports_widget/reports_widget'
import ReportsVirtualWidget from './reports_virtual_widget/reports_virtual_widget'
import './pro_analytics_report.scss'

const maximizedStyle = {
    left: '24px',
    right: '24px',
    top: '96px',
    bottom: '24px',
    maxHeight: null,
    width: null,
    height: null,
    zIndex: 5,
}

const widgetStyle = {
    minHeight: '27.714rem',
    maxHeight: '27.714rem',
    height: '100%',
}

const ProAnalyticsReport = () => {
    const viewModel = useViewModel(ProAnalyticsReportContext)
    const noReport = viewModel?.noReportId?.value
    const customerConfig = useCustomerConfig()

    const { locale } = useContext(LocaleContext)

    const renderContent = locale => {
        if (noReport) {
            return (
                <div className="pro-analytics-report__no-report-content">
                    <Result
                        status="error"
                        title="No report found"
                        extra={
                            <>
                                <div>Please choose another report below and try again.</div>
                            </>
                        }
                    />
                    <ReportsWidget viewModel={viewModel} locale={locale} />
                </div>
            )
        } else {
            return (
                <div className="pro-analytics-report__table-wrap">
                    <ReportsVirtualWidget
                        locale={locale}
                        viewModel={viewModel}
                        style={widgetStyle}
                        maximizedStyle={maximizedStyle}
                    />
                </div>
            )
        }
    }

    if (customerConfig.error) {
        return (
            <div className="messageContainer">
                <Alert
                    message={locale('proAnalytics.clientConfigErrorTitle')}
                    description={customerConfig.error.error}
                    type="info"
                    showIcon
                />
            </div>
        )
    }

    return viewModel.errors?.insufficientPermissions ? (
        <Result
            status="error"
            title={locale('insufficientPermissions')}
            subTitle={viewModel.errors.insufficientPermissions}
        />
    ) : (
        <div className="pro-analytics-report">
            <div className="pro-analytics-report__filters-wrap">
                <div className="pageSection">
                    <ProAnalyticsReportFilters />
                </div>
            </div>
            <div className="pro-analytics-report__content-wrap pageSection">
                <div className="pro-analytics-report__back-cta">
                    <Button className="ant-btn ant-btn-default" onClick={viewModel.backAction.onTrigger}>
                        Back
                    </Button>
                </div>
                {viewModel.dashboardMessage.value && (
                    <div className="messageContainer">
                        <Alert
                            message={viewModel.dashboardMessage.value}
                            description={
                                viewModel.recentlyUsedOrganisationNodes.value.length > 0 && (
                                    <>
                                        {viewModel.recentlyUsedOrganisationNodes.label}
                                        <ul className="recentlyUsedOrganisationNodes">
                                            {viewModel.recentlyUsedOrganisationNodes.value.map(node => (
                                                <li key={node.id}>
                                                    <Button
                                                        type="link"
                                                        onClick={() =>
                                                            viewModel.recentlyUsedOrganisationNodes.onChange(node)
                                                        }
                                                    >
                                                        {node.label}
                                                    </Button>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )
                            }
                            type="info"
                            showIcon
                        />
                    </div>
                )}
                {!viewModel.dashboardMessage.value && renderContent(locale)}
            </div>
        </div>
    )
}

export default ProAnalyticsReport
