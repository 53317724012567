import { useContext } from 'react'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { Button, theme, Typography } from 'antd'
import { useViewModel } from '@lib/view_context/view-model'
import { ExitConfirmationContext } from './exit_confirmation.context'
import '@views/misc/exit_confirmation/exit_confirmation.scss'

const ExitConfirmationView = () => {
    const viewModel = useViewModel(ExitConfirmationContext)
    const { locale } = useContext(LocaleContext)
    const { token } = theme.useToken()

    const componentStyle = {
        backgroundColor: token.colorBgLayout,
    }

    return (
        <div className="exit-confirmation" style={componentStyle}>
            <div className="exit-confirmation__wrap">
                <div className="exit-confirmation__top">
                    <Typography.Text strong>{locale('prompt.saveChanges.headline')}</Typography.Text>
                    <Typography.Text className="exit-confirmation__text">
                        {locale('prompt.saveChanges.message')}
                    </Typography.Text>
                </div>
                <div className="exit-confirmation__cta-wrap">
                    {viewModel.cancel.visible && (
                        <Button shape="round" onClick={viewModel.cancel.onTrigger}>
                            {viewModel.cancel.label}
                        </Button>
                    )}
                    {viewModel.discard.visible && (
                        <Button shape="round" onClick={viewModel.discard.onTrigger}>
                            {viewModel.discard.label}
                        </Button>
                    )}
                    {viewModel.save.visible && (
                        <Button
                            type="primary"
                            shape="round"
                            onClick={viewModel.save.onTrigger}
                            style={{ fontWeight: 'bold' }}
                        >
                            {viewModel.save.label}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ExitConfirmationView
