import { Publisher } from '@prospective/pms-js-utils'
import { useEffect, useState } from 'react'
import { O } from '@prospective/pms-js-utils'

export const DEFAULT_CONFIGURATION = {
    proAnalytics: {
        kpiWidget: {
            isHidden: true,
        },
        costsPerMediumWidget: {
            isHidden: true,
        },
        costsWidget: {
            isHidden: true,
        },
        candidateJourneyWidget: {
            isHidden: true,
            columns: {
                numberOfPublications: {
                    isHidden: false,
                },
                channelGooglePerOD: {
                    isHidden: false,
                },
                channelDisplayPerOD: {
                    isHidden: false,
                },
                channelSocialPerOD: {
                    isHidden: false,
                },
            },
        },
        jobsCountWidget: {
            isHidden: true,
        },
        performanceWidget: {
            isHidden: true,
        },
        careerCenterWidget: {
            isHidden: true,
        },
        reports: {
            isHidden: true,
        },
    },
    configurationIsLoading: true,
    error: null,
}

const [change, publishChange] = Publisher()

let state = DEFAULT_CONFIGURATION

const reset = () => {
    setState(DEFAULT_CONFIGURATION)
}

const setState = partialState => {
    state = O(DEFAULT_CONFIGURATION).deepMerge(partialState).valueOf()
    publishChange(state)
}

export const CustomerConfig = {
    get state() {
        return state
    },
    set state(value) {
        setState(value)
    },
    get reset() {
        return reset
    },
    get configurationLoaded() {
        return state !== DEFAULT_CONFIGURATION
    },
    ...change,
}

export const useCustomerConfig = () => {
    const [config, setConfig] = useState({})

    const onCustomerConfigChange = state => {
        if (config !== state) setConfig(state)
    }

    useEffect(() => {
        CustomerConfig.subscribe(onCustomerConfigChange)
        onCustomerConfigChange(CustomerConfig.state)
        return () => {
            CustomerConfig.unsubscribe(onCustomerConfigChange)
            setConfig({})
        }
    }, [])

    return config
}
