import { useCallback, useContext, useEffect, useState } from 'react'
import { Spin, TreeSelect } from 'antd'
import { TreeNode } from 'antd/es/tree-select'
import { debounce } from '@prospective/pms-js-utils'
import { LoadingOutlined } from '@ant-design/icons'
import { SearchSuggestions } from '../search_suggestions.js'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'

export const ProAnalyticsSearch = function ({ className = '', classes, popupContainer, viewModel, ...props }) {
    const suggestions = viewModel.searchSuggestions.dictionary?.suggestions || []
    const [isCancelled, setIsCancelled] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [searching, setSearching] = useState(false)
    const { locale } = useContext(LocaleContext)

    const onSuggestionSelect = value => {
        if (value && !searching) viewModel.searchSuggestions.setValue(value)
    }

    const onClear = () => {
        viewModel.clearSearch.onTrigger()
    }

    const onBlur = () => {
        setIsCancelled(true)
        viewModel.cancelSearch.onTrigger()
    }

    const onFocus = () => {
        setIsCancelled(false)
    }

    const triggerSearchTermChange = useCallback(
        debounce(searchTerm => {
            viewModel.search.setValue(searchTerm)
        }, 1000),
        []
    )

    const areSuggestionsUpToDate = (suggestions, searchTerm) => {
        return (
            suggestions &&
            suggestions.find(suggestion => suggestion.match === 'all')?.searchTerm === searchTerm
        )
    }

    /*
	 Why the heck do we need to save the search term internally and debounce it ???
	 - TreeSelect calls the onSearch callback with an empty string when you leave the text input
	   without changing anything. This triggers a new search with an empty value which we don't want.
	   We want to keep the old search term, so we block it. Instead, we trigger the cancelSearch action of the viewModel.
	   We need to debounce the onSearch callback because TreeSelect calls it before onBlur and that's the only way
	   to figure out if the user typed something or left the input field.
	*/
    const onSearch = useCallback(
        debounce((value, input) => {
            if (!isCancelled) {
                setSearching(true)
                setSearchTerm(value)
                // setSelectedSuggestion(null)
                // viewModel.searchSuggestions.setValue(null)
            } else {
                // setIsCancelled(false)
            }
        }, 50),
        []
    )

    useEffect(() => {
        setSearching(false)
    }, [viewModel.searchSuggestions?.dictionary])

    useEffect(() => {
        if (!isCancelled) triggerSearchTermChange(searchTerm)
    }, [searchTerm])

    const dropdownRender = originNode => (
        <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            spinning={viewModel.searchSuggestions.isLoading}
        >
            {originNode}
        </Spin>
    )

    const onKeyDown = event => {
        if (event.key === 'Enter' && searching && !areSuggestionsUpToDate(suggestions, searchTerm))
            viewModel.search.setValue(searchTerm)
    }

    const selectedSuggestion =
        SearchSuggestions(suggestions).findByValue(viewModel.searchSuggestions.value)?.value ||
        (searching
            ? null
            : suggestions?.length === 0
                ? null
                : searchTerm
                    ? `all.${searchTerm}`
                    : null)

    return (
        <div className={`SearchField ${className}`} {...props}>
            <TreeSelect
                onKeyDown={onKeyDown}
                showSearch
                allowClear
                value={selectedSuggestion}
                placeholder={locale('proAnalytics.search.placeholder')}
                filterTreeNode={false}
                onSearch={onSearch}
                onSelect={onSuggestionSelect}
                treeDefaultExpandAll={true}
                treeExpandedKeys={suggestions.map(suggestion => suggestion.value)}
                onClear={onClear}
                onBlur={onBlur}
                onFocus={onFocus}
                style={{ width: '100%' }}
                loading={viewModel.searchSuggestions.isLoading}
                getPopupContainer={() => popupContainer.current}
                dropdownRender={dropdownRender}
                disabled={viewModel.search.disabled}
            >
                {suggestions.map(item => {
                    if (item.field) {
                        return (
                            <TreeNode
                                key={item.value}
                                value={item.value}
                                title={item.label}
                                selectable={false}
                            >
                                {item.suggestions.map(suggestion => {
                                    return (
                                        <TreeNode
                                            key={suggestion.value}
                                            value={suggestion.value}
                                            title={suggestion.label}
                                        />
                                    )
                                })}
                            </TreeNode>
                        )
                    } else {
                        return <TreeNode key={item.value} value={item.value} title={item.label} />
                    }
                })}
            </TreeSelect>
        </div>
    )
}
