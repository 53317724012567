import { defaultDarkTheme, defaultTheme } from '@themes/default/default.theme.js'

export const stellenCockpitTheme = {
    antd: {
        ...defaultTheme.antd,
        token: {
            ...defaultTheme.antd.token,
            colorPrimary: '#A5D535',
            colorBgSecondary: '#dce6ea',
            colorBgLayout: '#ecf2f5',
            fontFamily: `'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
            borderRadius: 4,
            colorTextPlaceholder: '#474c5a',
        },
        components: {
            ...defaultTheme.antd.components,
            Button: {
                colorTextLightSolid: '#000000',
            },
            Tabs: {
                colorPrimary: '#A5D535',
                colorPrimaryHover: '#000000',
                itemSelectedColor: '#4d4d4d'
            },
        },
    },
}
export const stellenCockpitDarkTheme = {
    antd: {
        ...defaultDarkTheme.antd,
        token: {
            ...defaultDarkTheme.antd.token,
            colorPrimary: '#A5D535',
            colorBgLayout: '#494949',
            colorTextPlaceholder: '#474c5a',
        },
        components: {
            ...defaultDarkTheme.antd.components,
            Checkbox: {
                colorBgContainer: '#000000',
            },
            TreeSelect: {
                colorBgContainer: '#000000',
            },
            Input: {
                colorBgContainer: '#000000',
            },
            Select: {
                colorBgContainer: '#000000',
            },
            DatePicker: {
                colorBgContainer: '#000000',
            },
        },
    },
}
