import { Process, stateOf } from '@prospective/process-router'
import { FeatureToggles } from '@utils/feature_toggle.jsx'
import { ProAnalyticsProcess } from '@views/pro_analytics/pro_analytics.process.js'

export const CockpitRedirectionProcess = Process(({ process }) => {
    const { features } = stateOf(FeatureToggles)
    if (!features.cockpit.value) {
        process.exit('Cockpit feature is off. Redirecting to ProAnalytics')
        ProAnalyticsProcess()
        // window.location.href = '#/pro-analytics'
    }
})
CockpitRedirectionProcess.label = 'Cockpit redirection'