import { useEffect, useState } from 'react'
import { Publisher, O, createPromise } from '@prospective/pms-js-utils'

/**
 * @typedef {{} & Publisher} ApplicationStatus
 * @property fun
 */

const ApplicationStatus = () => {
    const [update, publishUpdate] = Publisher()
    let processes = []

    const onProcessStatusChange = () => {
        const allComplete = processes.every(processStatus => processStatus.status === 'fulfilled')
        if (allComplete) processes = []

        const statusEntries = allComplete
            ? []
            : processes.map(processStatus => ({
                  label: processStatus.label,
                  status: processStatus.status,
                  progress: processStatus.progress,
                  errorMessage: processStatus.errorMessage,
                  actions: processStatus.actions,
                  retry: processStatus.allowRetry ? processStatus : undefined,
              }))
        publishUpdate(statusEntries)
    }

    const applicationStatus = {
        ...update,
        get state() {
            return processes
        },
        /**
         *
         * @param {ProcessStatusType} processStatus
         * @return {any}
         */
        add: processStatus => {
            if (processes.includes(processStatus)) return applicationStatus
            processes = [...processes, processStatus]
            processStatus.subscribe(onProcessStatusChange)
            onProcessStatusChange()
            return applicationStatus
        },
        remove: processStatus => {
            processes = processes.filter(entry => entry !== processStatus)
            processStatus.unsubscribe(onProcessStatusChange)
            onProcessStatusChange()
            return applicationStatus
        },
    }

    return applicationStatus
}

/**
 * @typedef {{}} StatusDescriptor
 * @param {string} label
 * @param {string} errorMessage
 * @param {function(): *} start
 * @param {boolean} [retry]
 * @param {{callback: function(): *, label: string}[]} [pendingActions] Button which should appear while the operation is pending
 * @param {{callback: function(): *, label: string}[]} [errorActions] Button which should appear if the operation failed
 * @param {{callback: function(): *, label: string}[]} [successActions] Button which should appear when the operation succeeds
 */

/**
 * @typedef {{}&Publisher&function} ProcessStatusType
 * @param {string} label
 * @param {'queued'|'pending'|'terminated'|'rejected'|'fulfilled'} status
 * @param {number} progress A number between 0 and 1
 * @param {string} errorMessage
 * @param {{label: string, callback: function}[]} actions
 * @param {boolean} retry
 * @returns Promise
 */

/**
 * Representation of an asynchronous operation status
 * IMPORTANT: ProcessStatus creates a function which returns a Promise. That promise is never rejected,
 * even if the process fails. Some processes can be restarted if they fail, but the promise is resolved only
 * when the related process completes with success.
 * @param {function({stateOf: function=, reason: *=, result: *=}): StatusDescriptor} updateFn
 * @returns {ProcessStatusType}
 * @example
 * // Definition:
 * const myTask = ProcessStatus(({ stateOf }) => {
 *     const locale = stateOf(Localization).locale
 *
 *     return {
 *         label: locale('task'),
 *         errorMessage: locale('taskError'),
 *         start: startTask,
 *         pendingActions: [{
 *             label: locale('stop'),
 *             callback: stopTask
 *         }],
 *         successActions: [{
 *             label: locale('archive'),
 *             callback: archiveTask
 *         }],
 *         errorActions: [{
 *             label: locale('report'),
 *             callback: reportTaskError
 *         }],
 *         retry: true
 *     }
 * })
 *
 * // Usage:
 * applicationStatus.add(myTask).add(myOtherTask).add(myYetAnotherTask)
 * await myTask()
 * await Promise.all([
 *     myOtherTask(),
 *     myYetAnotherTask
 * ])
 */
export const ProcessStatus = updateFn => {
    const [update, publishUpdate] = Publisher()
    let processPromise
    let resolvePromise
    let process

    const status = {
        label: '',
        status: 'queued',
        progress: 0,
        errorMessage: undefined,
        actions: undefined,
        allowRetry: false,
    }

    const updateStatus = params => {
        O(params).forEach((value, param) => (status[param] = value))
        publishUpdate({ ...status })
    }
    const onProgress = () => {
        updateStatus({ progress: process?.progress })
    }
    const onTerminate = () => {
        updateStatus({ status: 'terminated' })
        unsubscribe()
    }
    const onFulfilled = result => {
        const { label, errorMessage, status, successActions, allowRetry } = updateFn({ stateOf, result })

        updateStatus({
            status: status !== undefined ? status : 'fulfilled',
            progress: 1,
            label,
            errorMessage,
            actions: successActions,
            allowRetry,
        })
        unsubscribe()
        process = undefined
        processPromise = undefined
        resolvePromise(result)
    }
    const onRejected = reason => {
        const { label, errorMessage, errorActions, allowRetry } = updateFn({ stateOf, reason })
        updateStatus({
            status: 'rejected',
            errorMessage,
            label,
            actions: errorActions,
            allowRetry,
        })
        unsubscribe()
        process = undefined
    }
    const unsubscribe = () => {
        if (Reflect.has(process, 'onProgress')) process.onProgress.unsubscribe(onProgress)
        stateListeners.forEach((listener, statePublisher) => statePublisher.unsubscribe(listener))
        // if (Reflect.has(process, 'onTerminate'))
        // 	process.onTerminate.unsubscribe(onTerminate)
    }
    let stateListeners = new Map()
    const stateOf = statePublisher => {
        if (!stateListeners.has(statePublisher)) {
            const listener = () => {
                statePublisher.unsubscribe(listener)
                stateListeners.delete(statePublisher)
                const { label, errorMessage, errorActions, successActions, pendingActions, retry } = updateFn({
                    stateOf,
                })
                const actions =
                    status.status === 'fulfilled'
                        ? successActions
                        : status.status === 'rejected'
                        ? errorActions
                        : pendingActions
                updateStatus({ label, errorMessage, actions, retry: allowRetry })
            }
            stateListeners.set(statePublisher, listener)
            statePublisher.subscribe(listener)
        }
        return statePublisher.state
    }

    const { start, label, errorMessage, errorActions, successActions, pendingActions, allowRetry } = updateFn({
        stateOf,
    })

    status.label = label
    status.errorMessage = errorMessage
    status.actions =
        status.status === 'fulfilled' ? successActions : status.status === 'rejected' ? errorActions : pendingActions
    status.allowRetry = allowRetry

    const processTrigger = Object.assign((...args) => {
        if (process) return processPromise
        process = start(...args)
        // We call processTrigger also when we retry after unsuccessful process execution, so we need to wrap
        // the original process in a promise so that other processes can reliably await it.
        // Once the process completes successfully, we clear the promise.
        if (!processPromise) {
            const [promise, resolve] = createPromise()
            processPromise = promise
            resolvePromise = resolve
        }
        if (Reflect.has(process, 'onProgress')) process?.onProgress.subscribe(onProgress)
        // if (Reflect.has(process, 'onTerminate'))
        // 	process.onTerminate.subscribe(onTerminate)
        process.then(onFulfilled).catch(onRejected)
        onProgress()
        updateStatus({ label, status: 'pending', progress: process?.progress, errorMessage })
        return processPromise
    }, update)
    Reflect.defineProperty(processTrigger, 'label', {
        get: () => status.label,
    })
    Reflect.defineProperty(processTrigger, 'errorMessage', {
        get: () => status.errorMessage,
    })
    Reflect.defineProperty(processTrigger, 'status', {
        get: () => status.status,
    })
    Reflect.defineProperty(processTrigger, 'progress', {
        get: () => status.progress,
    })
    Reflect.defineProperty(processTrigger, 'actions', {
        get: () => status.actions,
    })
    Reflect.defineProperty(processTrigger, 'allowRetry', {
        get: () => status.allowRetry,
    })

    return processTrigger
}

export const applicationStatus = ApplicationStatus()

export const withApplicationStatus = Component => {
    return (...props) => {
        const [status, setStatus] = useState([])

        const onStatusChange = value => {
            setStatus(value)
        }
        useEffect(() => {
            applicationStatus.subscribe(onStatusChange)
            setStatus(applicationStatus.state)
            return () => {
                applicationStatus.unsubscribe(onStatusChange)
            }
        }, [])

        return <Component applicationStatus={status} {...props} />
    }
}
