import { Localization } from '@lib/i18n/localization.js'
import { View } from '@lib/view_context/view-context.js'

const ApplicationMetadata = locale => ({
    loadPluginsTask: {
        label: locale('loadingPlugins'),
    },
    salutation: {
        label: locale('salutation'),
    },
    gender: {
        label: locale('gender'),
    },
    firstName: {
        label: locale('firstName'),
    },
    lastName: {
        label: locale('lastName'),
    },
    fullName: {
        label: locale('name'),
    },
    email: {
        label: locale('email'),
    },
    profileImage: {
        label: locale('profileImage'),
    },
    company: {
        label: locale('company'),
    },
    logIn: {
        label: locale('login'),
        actions: ['onTrigger'],
    },
    logOut: {
        label: locale('logout'),
        actions: ['onTrigger'],
    },
    authenticated: {
        label: locale('authenticated'),
    },
    settings: {
        label: locale('settings'),
        actions: ['onTrigger'],
    },
    password: {
        label: locale('settings.password'),
        actions: ['onTrigger'],
    },
    initializationTasks: {},
    backendVersion: {},
})

export const ApplicationContext = View.observe(Localization).update(localization =>
    ApplicationMetadata(localization.locale)
)
