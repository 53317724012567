export const Value = field => (object, value) => ({ ...object, [field]: value })
Value.difference = function() {
    const values = Array.from(arguments)
    return values.some(value => value !== values.at(0)) ? values : undefined
}
Value.compare = function () {
    return Value.difference(Array.from(arguments)) === undefined
}

export const UnorderedListValue = field => (object, value) => ({ ...object, [field]: value })
const defaultItemCompareFunction = (item1, item2) => item1 !== item2
UnorderedListValue.compare = (...lists) => UnorderedListValue.difference(...lists) === undefined
UnorderedListValue.Difference = (compareFunction = defaultItemCompareFunction) => function() {
    const values = Array.from(arguments)
    const haveSameLength = lists => lists.every(list => list?.length === lists.at(0)?.length)
    const haveSameElements = values => {
        let lists = values.map((list = []) => [...list])
        let firstList = lists.at(0) || []
        do {
            const item = firstList.at(-1)
            lists = lists.map(list => list.filter(listItem => compareFunction(listItem, item)))
            firstList = lists.at(0) || []
            if (!haveSameLength(lists)) return false
        } while (firstList.length > 0)
        return true
    }
    if (haveSameLength(values) && haveSameElements(values))
        return undefined
    return [...values]
}
UnorderedListValue.difference = UnorderedListValue.Difference()

export const LocalizedValue = {
    en: Value,
    de: Value,
    fr: Value,
    it: Value
}
LocalizedValue.difference = function() {
    const localizedValues = Array.from(arguments)
    return Object.keys(LocalizedValue)
        .map(key => [
            key,
            Value.difference(localizedValues.map(localizedValue => localizedValue[key]))])
        .filter(([,value]) => value !== undefined)
        .reduce(([key, value]) => ({ [key]: value }), {})
}
LocalizedValue.compare = function() {
    const difference = LocalizedValue.difference(Array.from(arguments))
    return Object.keys(difference).length === 0
}

export const DateValue = field => (object, value) => ({ ...object, [field]: value })
DateValue.difference = function() {
    const values = Array.from(arguments)
    return values.some(value => {
        const timestamp = new Date(value || null).getTime()
        const firstValueTimestamp = new Date(values.at(0) || null).getTime()
        return timestamp !== firstValueTimestamp
    }) ? values : undefined
}
DateValue.compare = function() { return DateValue.difference(arguments) === undefined }

const updateField = (object, path, reducer) => {
    if (path.length === 0)
        return reducer(object)
    const fieldChain = [...path]
    const root = { ...object }
    let currentNode = root
    let currentField = fieldChain.shift()
    while (currentField) {
        if (fieldChain.length === 0)
            currentNode[currentField] = reducer(currentNode[currentField])
        else
            currentNode[currentField] = {...currentNode[currentField]}
        currentNode = currentNode[currentField]
        currentField = fieldChain.pop()
    }
    return root
}
const compose = function() {
    const components = Array.from(arguments)
    const result = {}
    components.forEach(component => {
        const properties = Reflect.ownKeys(component)
        properties.forEach(property => {
            const propertyDescriptor = Reflect.getOwnPropertyDescriptor(component, property)
            Reflect.defineProperty(result, property, propertyDescriptor)
        })
    })
    return result
}

/**
 *
 * @type BusinessObjectType
 */
export const BusinessObject = descriptor => {
    let extensions = []

    /**
     * @type BusinessObjectCreator
     */
    const Creator = (value = {}) => {
        const merge = function() {
            const [descriptor, ...rest] = Array.from(arguments)
            const objects = [...rest].filter(object => object !== undefined && object !== null)
            return Object
                .entries(descriptor)
                .reduce((result, [key, type]) => {
                    if (type instanceof Function)
                        return Object.assign(result, ...objects.filter(object => Reflect.has(object, key)).map(object => getPartial(object, key)))
                    else if (type instanceof Object && objects.some(object => Reflect.has(object, key)))
                        return Object.assign(result, { [key]: merge(descriptor[key], ...objects.map(object => object[key]).filter(object => object)) })
                    else
                        return result
                }, {})
        }
        let object = { ...value }
        try {
            const extensionComponents = extensions.map(extension => extension(object))
            object = compose(object, ...extensionComponents)
        } catch (error) {
            throw new Error('Error in extension', { cause: error })
        }
        const getObject = () => object
        const getSetters = (path = []) => {
            const nodeDescriptor = path.reduce((result, field) => result[field], descriptor)
            return Object.entries(nodeDescriptor).reduce((result, [key, fieldType]) => {
                if (fieldType instanceof Function)
                    Reflect.defineProperty(result, key, {
                        get: () => value => {
                            const ObjectType = BusinessObject(descriptor).extend(...extensions)
                            const nextObject = updateField(getObject(), path, original => fieldType(key)(original, value))
                            object = ObjectType(nextObject)
                            return object
                        },
                    })
                else if (fieldType instanceof Object)
                    Reflect.defineProperty(result, key, {
                        get: () => getSetters([...path, key]),
                    })
                return result
            }, {})
        }
        const getPartial = (object, field) => Reflect.has(object, field) ? { [field]: object[field] } : {}
        Reflect.defineProperty(object, 'set', {
            enumerable: false,
            get: () => getSetters(),
        })
        Reflect.defineProperty(object, 'update', {
            enumerable: false,
            get: () => (...partials) => {
                const merged = merge(descriptor, object, ...partials)
                const extended = compose(merged, ...extensions.map(extension => extension(merged)))
                return BusinessObject(descriptor).extend(...extensions)(extended)
            },
        })
        Reflect.defineProperty(object, 'delete', {
            enumerable: false,
            get: () => (...fields) => {
                const merged = merge(descriptor, object, {})
                const extended = compose(merged, ...extensions.map(extension => extension(merged)))
                const copy = BusinessObject(descriptor).extend(...extensions)(extended)
                Array.from(fields).forEach(field => Reflect.deleteProperty(copy, field))
                return copy
            }
        })
        object[BusinessObject.symbolDescriptor] = descriptor
        return object
    }

    const getDifference = function() {
        const [descriptor, ...objects] = Array.from(arguments)
        if (!descriptor) return {}
        const difference = Object
            .entries(descriptor)
            .reduce((result, [key, type]) => {
                if (type instanceof Function) {
                    const differenceFunction = type.difference || Value.difference
                    const difference = differenceFunction(...objects.map(object => object?.[key]))
                    if (difference !== undefined) {
                        if (result === null) result = {}
                        result[key] = difference
                    }
                    return result
                }
                else if (type instanceof Object && objects.some(object => object && Reflect.has(object, key))) {
                    const childrenDifference = getDifference(descriptor[key], ...objects.map(object => object[key]))
                    if (childrenDifference !== undefined) {
                        if (result === null) result = {}
                        result[key] = childrenDifference
                    }
                    return result
                }
                else
                    return result
            }, null)
        return difference === null ? undefined : difference
    }
    const compare = function() { return getDifference(descriptor, ...Array.from(arguments)) === undefined }
    Reflect.defineProperty(Creator, 'extend', {
        enumerable: false,
        get: () => (...components) => {
            extensions.push(...components)
            return Creator
        },
    })
    Reflect.defineProperty(Creator, 'difference', {
        enumerable: false,
        get: () => (...businessObjects) => getDifference(descriptor, ...businessObjects)
    })
    Reflect.defineProperty(Creator, 'compare', {
        enumerable: false,
        get: () => compare
    })
    return Creator
}
BusinessObject.symbolDescriptor = Symbol('BusinessObjectDescriptor')
