import { useEffect, useState } from 'react'
import { Authorization } from '@modules/authorization/authorization'
import { Localization } from '@lib/i18n/localization'
import { Dictionaries } from '@modules/dictionaries/dictionaries.js'
import { UserSession } from '@login/user-session.js'
import { ProAnalyticsContext } from '@views/pro_analytics/pro_analytics.context.js'
import { getViewContext } from '@prospective/pms-view-context'
import { O, Publisher } from '@prospective/pms-js-utils'
import { router } from '@configuration/router.js'
import { PluginManager } from '@modules/plugins/plugin_manager'
import { OrganizationHierarchy } from '@utils/organization_hierarchy.js'
import { ProAnalyticsFiltersInterface } from '@views/pro_analytics/pro_analytics_filters.interface.js'
import { CustomerConfig } from '@modules/customer_config/customer_config.js'
import { FeatureToggles } from '@utils/feature_toggle.jsx'
import { CONFIG } from '@configuration/config_variables.js'
import { UserPreferences } from '@modules/user_preferences/user_preferences.js'
import { Process } from '@prospective/process-router'
import { UserDetails } from '@modules/user/user.js'

let debugMode = CONFIG.environment === 'development' || CONFIG.isLocalEnvironment
let isLogViewerOpen = false
let selectedLogNumber = undefined
const [change, publishChange] = Publisher()

/**
 * Debug state publisher.
 * @type {{[p: string]: *}}
 */
export const Debug = {
    ...change,
    get state() {
        return debugMode
    },
    get environment() {
        return CONFIG.environment
    },
    /**
     * Debug mode
     * @return {boolean}
     */
    get debugMode() {
        return debugMode
    },
    get version() {
        return CONFIG.version
    },
    set debugMode(value) {
        if (value !== debugMode) {
            debugMode = value
            const callback = debugMode ? turnOnDebugMode : turnOffDebugMode
            callback()
            publishChange(Debug)
        }
    },
    get isLogViewerOpen() {
        return isLogViewerOpen
    },
    get selectedLogNumber() {
        return selectedLogNumber
    },
    openLogViewer: logNumber => {
        if (!debugMode) return
        selectedLogNumber = logNumber
        isLogViewerOpen = true
        publishChange(Debug)
    },
    closeLogViewer: () => {
        isLogViewerOpen = false
        publishChange(Debug)
    },
}

const turnOnDebugMode = () => {
    Debug.Authorization = Authorization
    Debug.Localization = Localization
    Debug.UserDictionaries = Dictionaries
    Debug.UserSession = UserSession
    Debug.UserPreferences = UserPreferences
    Debug.getViewContext = getViewContext
    Debug.ProAnalyticsContext = ProAnalyticsContext
    Debug.pluginManager = PluginManager
    Debug.OrganizationHierarchy = OrganizationHierarchy
    Debug.UserDetails = UserDetails
    // Debug.CollectionUtils = CollectionUtils
    // Debug.FunctionalUtils = FunctionalUtils
    Debug.ProAnalyticsFiltersInterface = ProAnalyticsFiltersInterface
    Debug.CustomerConfig = CustomerConfig
    Debug.FeatureToggles = FeatureToggles
    Debug.Process = Process
    Debug.router = router
    Debug.O = O
}

const turnOffDebugMode = () => {
    Debug.Authorization = undefined
    Debug.Localization = undefined
    Debug.UserDictionaries = undefined
    Debug.UserSession = undefined
    Debug.UserPreferences = undefined
    Debug.getViewContext = undefined
    Debug.ProAnalyticsContext = undefined
    Debug.pluginManager = undefined
    Debug.OrganizationHierarchy = undefined
    Debug.UserDetails = undefined
    // Debug.CollectionUtils = undefined
    // Debug.FunctionalUtils = undefined
    Debug.ProAnalyticsFiltersInterface = undefined
    Debug.CustomerConfig = undefined
    Debug.FeatureToggles = undefined
    Debug.Process = undefined
    Debug.router = undefined
    Debug.O = undefined
}

window.debug = Debug
if (debugMode) turnOnDebugMode()

window.addEventListener('keypress', event => {
    if (event.ctrlKey && event.key === 'd') Debug.debugMode = !Debug.debugMode
})

/**
 * React hook. Updates the component when debug mode changes.
 * @return {{
 * debugMode: boolean,
 * isLogViewerOpen: boolean,
 * selectedLogNumber: string|number,
 * openLogViewer: function,
 * closeLogViewer: function
 * }}
 */
export const useDebug = () => {
    const [debugMode, setDebugMode] = useState(false)
    const [isLogViewerOpen, setIsLogViewerOpen] = useState(false)
    const [selectedLogNumber, setSelectedLogNumber] = useState()

    const onDebugChange = state => {
        setDebugMode(state.debugMode)
        setIsLogViewerOpen(state.isLogViewerOpen)
        setSelectedLogNumber(state.selectedLogNumber)
    }

    useEffect(() => {
        Debug.subscribe(onDebugChange)
        onDebugChange(Debug)
        return () => {
            Debug.unsubscribe(onDebugChange)
        }
    }, [])

    return {
        debugMode,
        isLogViewerOpen,
        selectedLogNumber,
        openLogViewer: Debug.openLogViewer,
        closeLogViewer: Debug.closeLogViewer,
    }
}
