import { useContext, useEffect } from 'react'
import { Spin } from 'antd'
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { errorModal } from '@components/modules/global_notifications/global_notifications.jsx'
import { usePluginFeatures } from '@modules/plugins/plugin_manager_react_utils.jsx'
import { ProAnalyticsJobContext } from './pro-analytics-job.context'
import {
    ProAnalyticsJobFilters,
} from '@views/pro_analytics/pro-analytics-job/pro_analytics_job_filters/pro_analytics_job_filters'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { useCustomerConfig } from '@modules/customer_config/customer_config'
import './pro_analytics_job.scss'
import { useViewModel } from '@lib/view_context/view-model.js'

const ProAnalyticsJobView = () => {
    const viewModel = useViewModel(ProAnalyticsJobContext)
    const { locale } = useContext(LocaleContext)
    const currency = viewModel.currency.value
    const pluginFeatures = usePluginFeatures()
    const customerConfig = useCustomerConfig()
    const jobDetailsDateSpanStatus = viewModel?.jobDetailsDatesSpanStatus?.value?.status

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        if (viewModel?.jobDetailsDatesSpanStatus?.value?.status === 'error') {
            errorModal({
                title: locale('proAnalytics.jobDetails'),
                content: locale('proAnalytics.jobDetailsError'),
            })
        }
    }, [viewModel?.jobDetailsDatesSpanStatus?.value?.status])

    return (
        <>
            <div className="pro-analytics-job__filters-wrap">
                <div className="pageSection">
                    <ProAnalyticsJobFilters />
                </div>
            </div>
            <div className="pro-analytics-job pageSection">
                {customerConfig.configurationIsLoading || jobDetailsDateSpanStatus === 'pending' ? (
                    <div className="messageOverlay">
                        <div className="spinContainer">
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="pro-analytics-job__top-section">
                            <div className="pro-analytics-job__top-section-item">
                                <p>
                                    <span>{viewModel?.jobDetails?.value?.jobId}</span>
                                    {viewModel?.jobDetails?.value?.jobName}
                                </p>
                            </div>
                            <div className="pro-analytics-job__top-section-item">
                                <p onClick={viewModel.backAction.onTrigger}>
                                    <ArrowLeftOutlined />
                                    {viewModel.backAction.label}
                                </p>
                            </div>
                        </div>
                        <div className="widgetContainer">
                            {pluginFeatures?.proAnalytics?.kpiWidget?.inject({
                                locale,
                                viewModel,
                                currency,
                                single: true,
                            })}
                            {pluginFeatures?.proAnalytics?.costsPerMediumWidget?.inject({
                                locale,
                                viewModel,
                                currency,
                            })}
                            {pluginFeatures?.proAnalytics?.costsWidget?.inject({
                                locale,
                                viewModel,
                                currency,
                            })}
                            {pluginFeatures?.proAnalytics?.performanceWidget?.inject({
                                locale,
                                viewModel,
                                single: true,
                            })}
                            {pluginFeatures?.proAnalytics?.deviceRatio?.inject({
                                locale,
                                viewModel,
                            })}
                            {pluginFeatures?.proAnalytics?.candidateJourneyWidget?.inject({
                                locale,
                                viewModel,
                                single: true,
                            })}
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default ProAnalyticsJobView
