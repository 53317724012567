export const customPriceFormatter = (price, currency, locale = undefined) => {
    if (isNaN(price)) return '-'
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency,
        ... currency ? {
            currencyDisplay: 'code',
            style: 'currency'
        } : undefined,
    }
    const formatter = new Intl.NumberFormat(locale, options)
    const parts = formatter.formatToParts(price)
    const fraction = parts.find(part => part.type === 'fraction')
    if (fraction.value === '00') fraction.value = '-'
    return parts.map(part => part.value).join('')
}
