export const DEFAULT_TREE_DESCRIPTOR = {
    getChildren: node => Object
        .entries(node)
        .map(([key, node]) => ({ key, node })),
    setChildren: (node, children) => Object.assign(node, children),
    isBranch: node => Object.entries(node).length > 0,
    isLeaf: node => Object.entries(node).length === 0,
}

export const Tree = (treeStructure, descriptor = DEFAULT_TREE_DESCRIPTOR) => {
    const find = predicate => {
        let nodes = [{ key: undefined, node: treeStructure }]
        while (nodes.length) {
            let { key, node } = nodes.shift()
            if (predicate(node, key, treeStructure))
                return node
            const children = descriptor.getChildren(node)
            if (children)
                nodes.push(...children)
        }
    }
    const findNodes = predicate => {
        const result = []
        let nodes = [{ key: undefined, node: treeStructure }]
        while (nodes.length) {
            let { key, node } = nodes.shift()
            if (predicate(node, key, treeStructure))
                result.push(node)
            const children = descriptor.getChildren(node)
            if (children)
                nodes.push(...children)
        }
        return result
    }
    const recursiveMap = ({ node, key }, tree, mappingFunction) => {
        const children = descriptor.getChildren(node)
        const mappedChildren = children?.map(child => recursiveMap(child, tree, mappingFunction))
        return {key, node: mappingFunction(node, mappedChildren, key, treeStructure)}
    }
    const map = mappingFunction => recursiveMap({ node: treeStructure }, treeStructure, mappingFunction).node
    const some = predicate => find(predicate) !== undefined
    const breadthFirstForEach = callback => {
        let nodes = [{ key: undefined, node: treeStructure }]
        while (nodes.length) {
            let { key, node } = nodes.shift()
            callback(node, key, treeStructure)
            const children = descriptor.getChildren(node)
            if (children)
                nodes.push(...children)
        }
    }
    const BF = {
        forEach: breadthFirstForEach
    }

    return {
        find,
        findNodes,
        some,
        map,
        BF
    }
}

Tree.DEFAULT_TREE_DESCRIPTOR = DEFAULT_TREE_DESCRIPTOR
