import {useState, useEffect, useContext} from 'react'
import { Button, Dropdown, Empty, Menu, Result, Space, Spin } from 'antd'
import { Maximizable } from '@hoc/maximizable/maximizable.jsx'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { LoadingOutlined } from '@ant-design/icons'
import { CloudDownload, ExpandDiagonal, XLSDownload, Close } from '@assets/icons/icons.js'
import { VirtualTable } from '@components/modules/virtual_table/virtual_table'
import { getXlsReport } from '../reports_utilities/reports_utilities'
import ErrorBoundary from '@hoc/error_boundary/error_boundary.jsx'
import Card from '@components/elements/card/card'
import './reports_virtual_widget.scss'
import {LocaleContext} from "@lib/i18n/localization_provider.jsx";

const logger = Logger('ReportDetails', CATEGORIES.MAIN)

const mergeArrToJSON = (a, b) =>
    a.map((item, i) => ({ [item.toLowerCase()]: b[i] })).reduce((json, val) => Object.assign({}, json, val))

const getAggregation = (reportData = []) => {
    const reportDataHeaders = reportData?.header?.map(header => header.replace(/,/g, ''))

    const columns = reportDataHeaders?.map(headItem => ({
        dataIndex: headItem.toLowerCase(),
        title: headItem.split('')[0].toUpperCase() + headItem.slice(1).toLowerCase(),
        width: 200,
        ellipsis: true,
        key: headItem.toLowerCase(),
        render: item => item || '-',
    }))

    const dataSource = reportData?.data?.reduce((obj, report) => {
        obj.push(mergeArrToJSON(reportDataHeaders, report))
        return obj
    }, [])

    return {
        columns,
        dataSource,
    }
}

const ReportsVirtualWidget = ({ viewModel, locale, maximizable, style }) => {
    const [errorMessage, setErrorMessage] = useState(viewModel.reports.value?.error)
    const status = viewModel?.queriedReportsStatus.value?.status
    const dateRangeFilter = viewModel?.dateRangeFilter?.value
    const currentReport = viewModel?.currentReport?.value

    const queriedReports = viewModel?.queriedReports?.value
    const reports = viewModel?.reports?.value
    const noReport = viewModel?.noReportId?.value
    const {languageCode} = useContext(LocaleContext)
    const reportName = 'name' + languageCode.charAt(0).toUpperCase() + languageCode.slice(1)
    const currentReportName = reports?.find(report => report.id === +currentReport)[reportName]

    useEffect(() => {
        if (status && status === 'error' && !noReport) {
            setErrorMessage(viewModel.reports.value?.error)
        }
    }, [status])

    const data = getAggregation(queriedReports)

    const exportClickHandler = key => {
        const reportName = reports.find(report => report.id.toString() === currentReport)['name']

        if (key === 'xls') {
            try {
                getXlsReport(queriedReports, reportName, dateRangeFilter, locale)
            } catch (error) {
                logger.error.withError(error, 'Export to XLS failed')
            }
        }
    }

    const menuItems = {
        items: [
            {
                key: 'xls',
                label: 'XLS',
                icon: <XLSDownload />,
                onClick: () => exportClickHandler('xls'),
            },
        ],
    }

    const extraCardHeaderContent = (
        <Space size={[16, 0]} style={{ display: 'flex', alignItems: 'center', maxWidth: 700 }}>
            {[1].length !== 0 && status === 'success' && (
                <Dropdown role="tooltip" menu={menuItems} placement="bottom" trigger={['click']}>
                    <Button type="text" icon={<CloudDownload />} />
                </Dropdown>
            )}
            <Button
                type="text"
                icon={maximizable.isMaximized ? <Close /> : <ExpandDiagonal />}
                onClick={() => {
                    maximizable.setMaximized(!maximizable.isMaximized)
                }}
            />
        </Space>
    )

    const cardStyle = {}
    if (maximizable.isMaximized) cardStyle.boxShadow = '0 0 10px 0 rgba(0, 0, 0, 0.18)'
    const maximizedClassName = maximizable.isMaximized ? ' maximized' : ''

    return (
        <ErrorBoundary
            title="Reports"
            viewModel={viewModel}
            message={errorMessage}
            status={status}
            locale={locale}
            key={viewModel}
        >
            <div ref={maximizable.animationRef} style={style}>
                <div ref={maximizable.containerRef} style={{ height: '100%', ...maximizable.style }}>
                    <Card
                        title={
                            <>
                                <span>{currentReportName}</span>
                            </>
                        }
                        style={cardStyle}
                        extra={extraCardHeaderContent}
                    >
                        <Spin
                            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                            spinning={status === 'pending'}
                        >
                            <div className={'tableContainer' + maximizedClassName}>
                                {status === 'error' ? (
                                    <Result
                                        status="warning"
                                        title="Oooops..."
                                        extra={
                                            <>
                                                <div>{errorMessage}</div>
                                            </>
                                        }
                                    />
                                ) : queriedReports?.data?.length >= 1 ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            rowGap: '16px',
                                            position: 'relative',
                                            paddingTop: '1rem',
                                            height: 'calc(100% - 1rem)',
                                        }}
                                    >
                                        <div>
                                            <VirtualTable
                                                dynamic
                                                locale={locale('antd', 'Table')}
                                                style={{
                                                    flexGrow: 1,
                                                    height: '100%',
                                                    position: 'relative',
                                                }}
                                                scroll={{ y: 400 }}
                                                columns={data.columns}
                                                dataSource={data.dataSource}
                                                pagination={{
                                                    hideOnSinglePage: true,
                                                    showSizeChanger: false,
                                                    size: 'big',
                                                }}
                                                size="small"
                                                rowClassName={(_, index) =>
                                                    index % 2 === 0 ? 'even' : 'odd'
                                                }
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <Empty description={locale('noData')} style={{ marginTop: '2rem' }} />
                                )}
                            </div>
                        </Spin>
                    </Card>
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default Maximizable(ReportsVirtualWidget)
