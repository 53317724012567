import { ProcessStatus } from '@lib/application_status/application_status.jsx'
import { ProAnalyticsDictionariesProcess } from '@modules/dictionaries/dictionary-processes'
import { PermissionsProcess } from '@modules/authorization/permissions.process'
import { Localization } from '@lib/i18n/localization'
import { CustomerConfiguration } from '@modules/customer_config/customer_config.process'
import { Dictionaries } from '@modules/dictionaries/dictionaries'
import { Authorization } from '@modules/authorization/authorization'
import { Plugins } from '@plugins/plugins.process'
import { includeChildNodes } from '@modules/dictionaries/organization-hierarchy.js'
import { Login, Logout } from '@login/login-process.js'

const login = ProcessStatus(({ stateOf }) => {
    const locale = stateOf(Localization).locale
    const label = locale('login')
    const errorMessage = locale('loginFailed')
    const start = Login
    const successActions = [
        {
            label: locale('logout'),
            callback: () => Logout(),
        },
    ]

    return { label, errorMessage, start, successActions }
})

const dictionaries = ProcessStatus(({ stateOf, reason }) => {
    const locale = stateOf(Localization).locale
    const label = locale('loadingDictionaries')

    let errorMessage = locale('loadingDictionariesFailed')
    if (reason && reason.status === 403) errorMessage = locale('proAnalytics.insufficientPermissions')
    const start = ProAnalyticsDictionariesProcess

    return { label, errorMessage, start, allowRetry: true }
})

const authorization = ProcessStatus(({ stateOf, result }) => {
    const locale = stateOf(Localization).locale
    const label = locale('loadingPermissions')

    if (result && !result.permissions.application.permissions.read)
        return {
            label,
            errorMessage: locale('application.insufficientPermissions'),
            status: 'rejected',
            allowRetry: true,
        }

    const errorMessage = locale('loadingPermissionsFailed')
    const start = PermissionsProcess

    return { label, errorMessage, start, allowRetry: true }
})

const configuration = ProcessStatus(({ stateOf, reason }) => {
    const locale = stateOf(Localization).locale
    const label = locale('proAnalytics.loadingCustomerConfig')

    let errorMessage = locale('proAnalytics.loadingCustomerConfigError')
    if (reason && reason.status === 403) errorMessage = locale('proAnalytics.insufficientPermissions')
    const start = () => {
        if (Authorization.isAdminUser) return CustomerConfiguration()
        const nodeIds = Dictionaries.organizationStructure?.map(node => node.id) || []
        if (nodeIds.length)
            return CustomerConfiguration({
                nodeIds: includeChildNodes(nodeIds, Dictionaries.organizationStructure),
            })

        return Promise.resolve()
    }

    return { label, errorMessage, start, allowRetry: true }
})

const plugins = ProcessStatus(({ stateOf }) => {
    const locale = stateOf(Localization).locale
    const label = locale('loadingPlugins')

    let errorMessage = locale('loadingPluginsFailed')
    const start = () => {
        return Plugins()
    }

    return { label, errorMessage, start, allowRetry: true }
})

export { login, dictionaries, authorization, configuration, plugins }
