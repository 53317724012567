import { Stream } from '@lib/stream/stream2.js'
import { createPromise } from '@prospective/pms-js-utils'

export const IDLE = 'idle'
export const PENDING = 'pending'
export const ERROR = 'error'
export const SUCCESS = 'success'
export const QUEUED = 'queued'
/**
 * Creates a status object of status STATUS_IDLE
 * @param {*} [details] Any additional useful information (i.e. id of an item being fetched from a service)
 * @returns {{progress: number, error: undefined, status: string, details: *}}
 */
export const createIdleStatus = details => ({
    status: IDLE,
    error: undefined,
    progress: 0,
    details,
})
/**
 * Creates a status object of status STATUS_PENDING
 * @param {number} progress Between 0 and 1
 * @param {*} [details] Any additional useful information (i.e. id of an item being fetched from a service)
 * @returns {{progress: number, error: undefined, status: string, details: *}}
 */
export const createPendingStatus = (progress = 0, details) => ({
    status: PENDING,
    error: undefined,
    progress,
    details,
})
/**
 * Creates a status object of status QUEUED
 * @param {number} progress Between 0 and 1
 * @param {*} [details] Any additional useful information (i.e. id of an item being fetched from a service)
 * @returns {{progress: number, error: undefined, status: string, details: *}}
 */
export const createQueuedStatus = (progress = 0, details) => ({
    status: QUEUED,
    error: undefined,
    progress,
    details,
})
/**
 * Creates a status object of status STATUS_SUCCESS
 * @param {*} [details] Any additional useful information (i.e. id of an item being fetched from a service)
 * @returns {{progress: number, error: undefined, status: string, details: *}}
 */
export const createSuccessStatus = details => ({
    status: SUCCESS,
    error: undefined,
    progress: 1,
    details,
})
/**
 * Creates a status object of status STATUS_ERROR
 * @param {(any|{logNumber: (string|number), error: string})} error
 * @param {*} [details] Any additional useful information (i.e. id of an item being fetched from a service)
 * @returns {{error, status: string, details: *}}
 */
export const createErrorStatus = (error, details) => ({ status: ERROR, error, details })

const RemoteDataType = Symbol('RemoteData')

const TranslatableError = error => {
    if (Reflect.has(error, 'call')) {
        const translatableError = (...args) => error(...args)
        translatableError.toString = (locale = key => key) => error(locale)
        return translatableError
    }
    return error
}

/**
 * @typedef {{
 *     setStatus: function(status): RemoteDataObject,
 *     setValue: function(value: any): RemoteDataObject,
 *     abort: function(abortController: AbortController): RemoteDataObject,
 *     success: function(details?: any): RemoteDataObject,
 *     pending: function(progress?: number, details?: any): RemoteDataObject,
 *     queued: function(progress?: number, details?: any): RemoteDataObject,
 *     message: function(message?: string|function): RemoteDataObject,
 *     isIdle: boolean,
 *     isQueued: boolean,
 *     isPending: boolean,
 *     isSuccessful: boolean,
 *     isError: boolean,
 *     isComplete: boolean,
 *     value: *,
 *     status: *,
 *     abortController: AbortController,
 *     originalError: string | function(locale):string,
 *     error: function(error?: string | function(locale): string): RemoteDataObject,
 *     getErrorDetails: function(locale): { title: string, description: string, logNumber }
 *     logNumber: function(logNumber: string | number): RemoteDataObject,
 *     cause: function(originalError:any): RemoteDataObject,
 *     update: function(updateObject: {}): RemoteDataObject,
 * }} RemoteDataObject
 */

/**
 *
 * @param {object | RemoteDataObject} remoteData
 * @returns {RemoteDataObject}
 * @constructor
 */
export const RemoteData = (remoteData = {}) => {
    const result = {
        [RemoteDataType]: RemoteData
    }

    if (Reflect.has(remoteData, 'value')) result.value = remoteData.value
    if (Reflect.has(remoteData, 'status')) result.status = remoteData.status
    if (Reflect.has(remoteData, 'abortController')) result.abortController = remoteData.abortController
    if (Reflect.has(remoteData, 'originalError')) result.originalError = remoteData.originalError
    result.getErrorDetails = locale => result.status?.error ? ({
        title: locale('error'),
        description: typeof result.status?.error?.error === 'function'
            ? result.status.error.error(locale)
            : result.status?.error?.error,
        logNumber: result.status?.error?.logNumber
    }) : undefined

    result.setStatus = status => RemoteData({ ...result, status })
    result.setValue = value => RemoteData({ ...result, value})
    result.abort = abortController => RemoteData({ ...result, abortController })
    result.success = (details = undefined) => RemoteData({...result, status: createSuccessStatus(details)})
    result.pending = (progress = 0, details = undefined) => RemoteData({...result, status: createPendingStatus(progress, details)})
    result.queued = (progress = 0, details = undefined) => RemoteData({...result, status: createQueuedStatus(progress, details)})
    result.message = message => RemoteData({...result, message})
    result.isIdle = result.status?.status === undefined || result.status?.status === IDLE
    result.isQueued = result.status?.status === QUEUED
    result.isPending = result.status?.status === PENDING
    result.isSuccessful = result.status?.status === SUCCESS
    result.isError = result.status?.status === ERROR
    result.isIncomplete = result.status?.status === PENDING || result.status?.status === ERROR
    result.error = error => RemoteData({ ...result, status: createErrorStatus({ error: TranslatableError(error), logNumber: result.status?.error?.logNumber }) })
    result.logNumber = logNumber => RemoteData({ ...result, status: createErrorStatus({ error: result.status?.error?.error, logNumber }) })
    result.cause = originalError => RemoteData({ ...result, originalError })
    result.update = updateObject => {
        if (updateObject?.value === result?.value &&
            updateObject?.status === result?.status &&
            updateObject?.abortController === result?.abortController) return result

        const merged = {
            ...result,
            ...updateObject
        }
        if (updateObject.abortController && result.abortController &&
            result.abortController !== updateObject.abortController &&
            result.status?.status === PENDING
        ) {
            result.abortController?.abort()
            merged.aborted = true
        }

        if (result.aborted) {
            merged.value = result.value
            merged.status = result.status
            merged.aborted = false
        }

        if (updateObject.status?.status !== PENDING)
            delete merged.abortController

        if (updateObject.status?.status === PENDING)
            delete merged.originalError

        return merged
    }

    return result
}

RemoteData.setStatus = status => RemoteData().setStatus(status)
RemoteData.setValue = value => RemoteData().setValue(value)
RemoteData.abort = abortController => RemoteData().abort(abortController)
RemoteData.success = (details = undefined) => RemoteData().success(details)
RemoteData.pending = (progress = 0, details = undefined) => RemoteData().pending(progress, details)
RemoteData.queued = (progress = 0, details = undefined) => RemoteData().queued(progress, details)
RemoteData.message = message => RemoteData().message(message)
RemoteData.error = error => RemoteData().error(error)
RemoteData.logNumber = logNumber => RemoteData().logNumber(logNumber)
RemoteData.cause = originalError => RemoteData().cause(originalError)
RemoteData.IDLE = IDLE
RemoteData.QUEUED = QUEUED
RemoteData.SUCCESS = SUCCESS
RemoteData.PENDING = PENDING
RemoteData.ERROR = ERROR
RemoteData.createIdleStatus = createIdleStatus
RemoteData.createPendingStatus = createPendingStatus
RemoteData.createErrorStatus = createErrorStatus
RemoteData.createSuccessStatus = createSuccessStatus
RemoteData.allSuccessful = remoteDataObjects => (remoteDataObjects || [])
    .every(remoteData => !remoteData || remoteData.isSuccessful)
RemoteData.allIdle = remoteDataObjects => (remoteDataObjects || [])
    .every(remoteData => !remoteData || remoteData.isIdle)
RemoteData.somePending = remoteDataObjects => (remoteDataObjects || [])
    .some(remoteData => remoteData?.isPending)
RemoteData.someQueued = remoteDataObjects => (remoteDataObjects || [])
    .some(remoteData => remoteData?.isQueued)

export const Retryable = stream => Stream(async function*() {
    const args = Array.from(arguments)
    let success = false
    let streamInstance
    let result
    while (!success) {
        try {
            streamInstance = stream(...args)
            for await (const data of streamInstance)
                yield data
            result = await streamInstance
            success = true
        } catch (error) {
            const [retryPromise, resolveRetry] = createPromise()
            const retry = () => { resolveRetry() }
            const getErrorDetails = locale => {
                const originalErrorDetails = error.getErrorDetails
                    ? error.getErrorDetails(locale)
                    : { title: 'Unhandled error', description: `Technical details: ${error.toString()}` }
                if (originalErrorDetails)
                    return ({ ...originalErrorDetails, retry })
                return originalErrorDetails
            }
            if (error[RemoteDataType] === RemoteData)
                yield RemoteData(error).update({ retry, getErrorDetails })
            else
                yield RemoteData.error(error).update({ retry, getErrorDetails })
            await retryPromise
        }
    }
    return result
})

let slaveEntries = new Map()
export const SlaveOf = (...streams) => stream => {
    const masterStreams = Array.from(streams)
    if (!slaveEntries.has(stream))
        slaveEntries.set(stream, { masterStreamsState: new Map() })
    masterStreams.forEach(masterStream => {
        masterStream.subscribe((newState, done) => {
            const entry = slaveEntries.get(stream)
            const lastState = entry.masterStreamsState.get(masterStream)
            if (lastState?.status?.status !== newState.status?.status || lastState?.value !== newState.value) {
                console.debug('State update', newState.status)
                entry.masterStreamsState.set(masterStream, newState)
            }
        })
    })

    return Stream(async function* () {
        const args = Array.from(arguments)
        const entry = slaveEntries.get(stream)
        const originalStream = stream(...args)
        yield* originalStream
        return await originalStream
    })
}

