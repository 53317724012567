import { Stream } from '@lib/stream/stream2.js'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import { View } from '@lib/view_context/view-context.js'
import { Localization } from '@lib/i18n/localization.js'
import { Dictionaries } from '@modules/dictionaries/dictionaries.js'
import { Process, stateOf, useCallback } from '@prospective/process-router'
import { useView } from '@lib/view_context/view_context_hooks.js'
import { Publisher } from '@prospective/pms-js-utils'
import { Logger } from '@modules/logging/logger.js'
import { UserDetails } from '@modules/user/user.js'
import { OrganizationHierarchy } from '@utils/organization_hierarchy.js'
import { UserPreferences } from '@modules/user_preferences/user_preferences.js'
import { ModuleController } from '@modules/module_controller.js'
import { useRemoteDataStream } from '@utils/process_hooks.js'

const logger = Logger('HierarchySelection')
const [change, publishChange] = Publisher()

let state = {
    activeHierarchy: undefined,
    isParentAgency: undefined,
}

export const selectHierarchy = Stream(async function* (user, organisation) {
    if (!user.companyAdmin) state.activeHierarchy = OrganizationHierarchy(organisation).findById(user.nodeId)
    if (state.activeHierarchy) return RemoteData.success().setValue(state.activeHierarchy)
    const userPreferences = await ModuleController.getUserPreferences()

    const recentlyUserHierarchyId = userPreferences.value?.default_node_id
    if (recentlyUserHierarchyId) {
        state.activeHierarchy = OrganizationHierarchy(organisation).findById(recentlyUserHierarchyId)
        HierarchySelection.setHierarchyNode(state.activeHierarchy)
    }

    if (state.activeHierarchy) return RemoteData.success().setValue(state.activeHierarchy)
    logger.info('Selecting hierarchy...')

    yield RemoteData.pending()

    do {
        state.activeHierarchy = (await change.promise).activeHierarchy
    } while (!state.activeHierarchy)
    logger.info('Hierarchy selected')

    return RemoteData.success().setValue(state.activeHierarchy)
})

const [hierarchyUpdate, publishHierarchyUpdate] = Publisher()
export const HierarchySelection = {
    get state() {
        return HierarchySelection
    },
    selectHierarchyState: RemoteData.queued(),
    setSelectHierarchyState: state => {
        HierarchySelection.selectHierarchyState = state
        publishHierarchyUpdate(HierarchySelection.selectHierarchyState)
    },
    setHierarchyNode: hierarchyNode => {
        if (state?.activeHierarchy?.id !== hierarchyNode?.id) state.activeHierarchy = hierarchyNode
        state.isParentAgency = hierarchyNode?.is_parent_agency
        HierarchySelection.setSelectHierarchyState(RemoteData.success().setValue(hierarchyNode))

        if (UserPreferences.state?.preferences?.default_node_id !== state.activeHierarchy?.id && state.activeHierarchy)
            UserPreferences.set(UserPreferences.DEFAULT_NODE_ID, state.activeHierarchy?.id)

        publishChange(state)
    },
    get activeHierarchy() {
        return state.activeHierarchy
    },
    get activeHierarchyId() {
        return state.activeHierarchy?.id
    },
    get isParentAgency() {
        return state.isParentAgency
    },
    selectHierarchy,
    ...hierarchyUpdate,
}

const HierarchyMetadata = (locale, dictionaries, userDetails) => ({
    hierarchy: {
        label: locale('company'),
        dictionary: dictionaries.organizationStructure,
        placeholder: locale('pleaseSelect'),
        description: locale('hierarchySelector.infoTooltip.description'),
        visible: userDetails.value?.companyAdmin === true,
    },
    userInteraction: {
        isLoading: false,
    },
})

// prettier-ignore
export const HierarchyContext = View
    .observe(Localization, Dictionaries, UserDetails)
    .update((localization, dictionaries, userDetails) => HierarchyMetadata(localization.locale, dictionaries, userDetails))

export const HierarchySelectionProcess = Process(() => {
    const view = useView(HierarchyContext)
    const hierarchySelection = stateOf(HierarchySelection)
    const onHierarchyChange = useCallback(hierarchy => HierarchySelection.setHierarchyNode(hierarchy))
    const [, getHierarchyNodeState] = useRemoteDataStream(selectHierarchy)

    view.update(fields => {
        fields.hierarchy.onChange = onHierarchyChange
        fields.hierarchy.value = hierarchySelection.activeHierarchy
        fields.userInteraction.isLoading = getHierarchyNodeState.isPending
    })
})

HierarchySelectionProcess.label = 'Hierarchy selection'
HierarchySelectionProcess()
window.selector = HierarchySelection
