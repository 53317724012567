import { CATEGORIES, Logger } from '@modules/logging/logger'
import { login } from '@modules/process_status/process_status'
import { Localization } from '@lib/i18n/localization'
import { PasswordContext } from '@views/settings/password/password.context'
import { createErrorStatus, createPendingStatus, createSuccessStatus, getErrorStructure } from '@utils/request_statuses'
import { tryCatch } from '@prospective/pms-js-utils'
import { UserSession } from '@login/user-session'
import { Process, stateOf, useCallback, useState } from '@prospective/process-router'
import { useView } from '@lib/view_context/view_context_hooks.js'
import JobBoosterService from '@services/job_booster_service'

const logger = Logger('PasswordProcess', CATEGORIES.MAIN)
export const PasswordProcess = Process(({ process }) => {
    const { locale } = stateOf(Localization)
    const { user } = stateOf(UserSession)
    const view = useView(PasswordContext)
    const [requestStatus, setRequestStatus] = useState({})

    const onApply = useCallback(async (oldPassword, newPassword) => {
        setRequestStatus(createPendingStatus())
        const [error] = await tryCatch(JobBoosterService.resetPassword)(oldPassword, newPassword)

        if (error) {
            const { logNumber } = logger.error.withError(error, 'Could not save password.')
            setRequestStatus(
                createErrorStatus({
                    error: locale('settings.password.error', { logNumber }),
                    logNumber,
                })
            )
        } else setRequestStatus(createSuccessStatus())
    })

    view.update((fields, errors) => {
        fields.newPasswordApply.onTrigger = onApply
        fields.changeRequestStatus.value = requestStatus
        fields.username.value = user?.email
        fields.back.onTrigger = process.exit

        errors.applyError = getErrorStructure(locale, requestStatus?.error)
    })

    return async () => {
        await login()
        process.ready()
    }
})

PasswordProcess.label = 'Password'
