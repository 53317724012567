import { de, enGB, fr, it } from 'date-fns/locale';
import { findLocaleByTag } from '@lib/i18n/localization';
// JSON resources
import jbEnGBJSON from "@i18n/en-GB.json"
import jbDeCHJSON from "@i18n/de-CH.json"
import jbDeDEJSON from "@i18n/de-DE.json"
import jbFrCHJSON from "@i18n/fr-CH.json"
import jbItCHJSON from "@i18n/it-CH.json"


// ANTD locales
import en_US from 'antd/lib/locale/en_US';
import en_GB from 'antd/lib/locale/en_GB';
import de_DE from 'antd/lib/locale/de_DE';
import de_CH from 'antd/lib/locale/de_DE';
import de_AT from 'antd/lib/locale/de_DE';
import gsw_CH from 'antd/lib/locale/de_DE';
import it_IT from 'antd/lib/locale/it_IT';
import it_CH from 'antd/lib/locale/it_IT';
import fr_FR from 'antd/lib/locale/fr_FR';
import fr_CH from 'antd/lib/locale/fr_FR';

export const antdLocales = {
    en_US,
    en_GB,
    de_DE,
    de_CH,
    it_IT,
    it_CH,
    fr_FR,
    fr_CH,
    de_AT,
    gsw_CH
}

// Date Picker object manipulation
en_US.DatePicker.lang.locale = 'en_US'
en_GB.DatePicker.lang.locale = 'en_GB'
de_DE.DatePicker.lang.locale = 'de'
it_IT.DatePicker.lang.locale = 'it'
it_CH.DatePicker.lang.locale = 'it'
fr_FR.DatePicker.lang.locale = 'fr'
fr_CH.DatePicker.lang.locale = "fr"
de_CH.DatePicker.lang.locale = "de"
de_AT.DatePicker.lang.locale = "de"
gsw_CH.DatePicker.lang.locale = "de"

/**
 * @typedef {{
 *  tag: String,
 *  country: string,
 *  language: string,
 *  fallbacks: string[]}} LocaleDescriptor
 */

export const LOCALE_TAG = Symbol("language tag");
export const DATE_FNS = Symbol("dateFns");

/**
 * Default locales descriptors
 * @type {LocaleDescriptor[]}
 */
export const TAG_TABLE = [
    { language: "english", country: "usa", tag: "en-US", fallbacks: ["en-GB","de-CH", "de-CH"] },
    { language: "english", country: "greatBritain", tag: "en-GB", fallbacks: ["en-US", "de-CH"] },
    { language: "english", country: "australia", tag: "en-AU", fallbacks: ["en-US", "de-CH"] },
    { language: "german", country: "germany", tag: "de-DE", fallbacks: ["de-CH", "en-GB"] },
    { language: "german", country: "austria", tag: "de-AT", fallbacks: ["de-DE", "en-GB"] },
    { language: "german", country: "switzerland", tag: "de-CH", fallbacks: ["de-DE", "en-GB"] },
    { language: "swissGerman", country: "switzerland", tag: "gsw_CH", fallbacks: ["de-CH", "de-DE", "en-GB"] },
    { language: "french", country: "france", tag: "fr-FR", fallbacks: ["fr-CH", "en-GB"] },
    { language: "french", country: "switzerland", tag: "fr-CH", fallbacks: ["fr-FR", "en-GB"] },
    { language: "italian", country: "italy", tag: "it-IT", fallbacks: ["it-CH", "en-GB"] },
    { language: "italian", country: "switzerland", tag: "it-CH", fallbacks: ["it-IT", "en-GB"] },
];

export let resources = {
    "en-GB": {
        [LOCALE_TAG]: "en-GB",
        [DATE_FNS]: enGB,
        "antd": (component) => {
            const tag = findLocaleByTag('en-GB').tag.split('-').join('_');
            return antdLocales[tag]?.[component] || antdLocales["de_DE"][component]
        },
        ...jbEnGBJSON,

    },
    "fr-FR": {
        [LOCALE_TAG]: "fr-FR",
        [DATE_FNS]: fr,
        "antd": (component) => {
            const tag = findLocaleByTag('fr-FR').tag.split('-').join('_');
            return antdLocales[tag]?.[component] || antdLocales["de_DE"][component]
        },
        ...jbFrCHJSON
    },
    "it-IT": {
        [LOCALE_TAG]: "it-IT",
        [DATE_FNS]: it,
        "antd": (component) => {
            const tag = findLocaleByTag('it-IT').tag.split('-').join('_');
            return antdLocales[tag]?.[component] || antdLocales["de_DE"][component]
        },
        ...jbItCHJSON
    },
    "de-CH": {
        [LOCALE_TAG]: "de-CH",
        [DATE_FNS]: de,
        "antd": (component) => {
            const tag = findLocaleByTag('de-CH').tag.split('-').join('_');
            return antdLocales[tag]?.[component] || antdLocales["de_DE"][component]
        },
        ...jbDeCHJSON
    },
    "de-DE": {
        [LOCALE_TAG]: "de-DE",
        [DATE_FNS]: de,
        "antd": (component) => {
            const tag = findLocaleByTag('de-DE').tag.split('-').join('_');
            return antdLocales[tag]?.[component] || antdLocales["de_DE"]
        },
        ...jbDeDEJSON
    },
    "gsw-CH": {
        [LOCALE_TAG]: "gsw-CH",
        [DATE_FNS]: de,
        "antd": (component) => {
            const tag = findLocaleByTag('gsw-CH').tag.split('-').join('_');
            return antdLocales[tag]?.[component] || antdLocales["de_DE"][component]
        },
    },
};

resources.en = resources["en-GB"];
resources.de = resources["de-DE"];