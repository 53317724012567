export const notificationData = () => {
    return [
        {
            id: 1,
            toggled: true,
            text: 'Benachrichtigen Sie mich, wenn Medien meines Auftrages ablaufen',
            dropdown_options: [
                { id: 1, text: 'Option 1', value: 'content_1' },
                { id: 2, text: 'Option 2', value: 'content_2' },
                { id: 3, text: 'Option 3', value: 'content_3' },
            ],
        },
        {
            id: 2,
            toggled: true,
            text: 'Ich möchte eine Auftragsbestätigung durch Prospective erhalten',
            dropdown_options: [
                { id: 1, text: 'Option 1', value: 'content_1' },
                { id: 2, text: 'Option 2', value: 'content_2' },
                { id: 3, text: 'Option 3', value: 'content_3' },
            ],
        },
        {
            id: 3,
            toggled: false,
            text: 'Ich möchte eine Meldung erhalten, wenn ich neue Aufgaben erhalte (“Meine Aufgaben”)',
            dropdown_options: [
                { id: 1, text: 'Option 1', value: 'content_1' },
                { id: 2, text: 'Option 2', value: 'content_2' },
                { id: 3, text: 'Option 3', value: 'content_3' },
            ],
        },
        {
            id: 4,
            toggled: true,
            text: 'Benachrichtigen Sie mich, wenn Rahmenverträge (Abos) ablaufen',
            dropdown_options: [
                { id: 1, text: 'Option 1', value: 'content_1' },
                { id: 2, text: 'Option 2', value: 'content_2' },
                { id: 3, text: 'Option 3', value: 'content_3' },
            ],
        },
    ]
}
