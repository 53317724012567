export const Plugin =
    (factory, dependencyContainers = []) =>
    async dependencies => {
        dependencyContainers.forEach(container => container.injectDependencies(dependencies))
        const plugin = await factory().catch(error => {
            console.error(`Could not load plugin content`, error)
            throw error
        })
        return plugin.default
    }
