import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useViewModel } from '@lib/view_context/view-model'
import { Link } from '@prospective/process-router-react'
import { ReportEditorContext } from '@views/settings/reports/report_editor.context'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { Route } from '@prospective/process-router'
import {
    Alert,
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Space,
    Spin,
    Tag,
    Transfer,
    TreeSelect,
    Typography,
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { LoadingOutlined } from '@ant-design/icons'
import { O } from '@prospective/pms-js-utils'
import { isPending, STATUS_PENDING } from '@utils/request_statuses'
import { routes } from '@configuration/routes'
import TextArea from 'antd/es/input/TextArea'
import Alerts from '@components/elements/alerts/alerts'
import './report_editor.scss'

const renderValidationMessages = messages => messages.map(message => <div key={message}>{message}</div>)

function tagRender(props) {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = event => {
        event.preventDefault()
        event.stopPropagation()
    }
    return (
        <Tag
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                border: 'none',
                borderRadius: '256px',
                backgroundColor: '#dce6ea',
                height: '24px',
                marginTop: '2px',
                marginBottom: '2px',
                paddingTop: '2px',
                fontSize: '1rem',
                maxWidth: 128,
            }}
        >
            <Typography.Text ellipsis={{ title: label }} style={{ width: 'calc(100% - 20px)' }} title={label}>
                {label}
            </Typography.Text>
        </Tag>
    )
}

const getColumnValue = (sourceType, field) => `${sourceType}:${field}`
const getColumnLabel = (sourceType, field) => `${sourceType} → ${field.replace('.', ' → ')}`
const getColumnByKey = (columns, key) => columns.find(column => column.key === key)
const formatColumns = (columns = {}) => {
    return O(columns)
        .map((fields, sourceType) =>
            fields.map(field => {
                const value = getColumnValue(sourceType, field)
                const label = getColumnLabel(sourceType, field)
                return { label, key: value, value, field, sourceType }
            })
        )
        .values()
        .flat()
}

const getColumnNameInCorrectLanguage = (colName) => {
    return colName + Localization.languageCode.charAt(0).toUpperCase() + Localization.languageCode.slice(1)
}

export default function ReportEditor({ className = '', ...props }) {
    const container = useRef(null)
    const { locale } = useContext(LocaleContext)
    const viewModel = useViewModel(ReportEditorContext)
    const panelTitle = viewModel.title?.label + (viewModel.name?.value ? ` - ${viewModel.name?.value}` : '')
    const reportsPath = Route(routes.reports).toLocation().path
    // const breadcrumbRoutes = [
    //     { path: reportsPath, breadcrumbName: locale('reports') },
    //     { breadcrumbName: viewModel.reportName.value ? viewModel.reportName.value : '' },
    // ]
    const [columns, setColumns] = useState([])
    const [selectedColumns, setSelectedColumns] = useState([])

    const hierarchyProps = {
        treeData: viewModel.visibility.dictionary,
        value: viewModel.visibility.value,
        onChange: value => viewModel.visibility.setValue(value),
        onBlur: () => viewModel.visibility.validate(),
        showCheckedStrategy: TreeSelect.SHOW_CHILD,
        placeholder: viewModel.visibility.placeholder,
        disabled: viewModel.visibility.disabled,
        allowClear: false,
        maxTagCount: 'responsive',
        label: viewModel.visibility.label,
        showSearch: true,
        filterTreeNode: (value, option) => option.label?.toLowerCase().indexOf(value.toLowerCase()) > -1,
        getPopupContainer: () => container.current,
        tagRender: tagRender,
    }

    const hierarchyFilterProps = {
        treeData: viewModel.queryTestOrganizationNodes.dictionary,
        value: viewModel.queryTestOrganizationNodes.value,
        onChange: value => viewModel.queryTestOrganizationNodes.setValue(value).onChange(value),
        onBlur: () => viewModel.queryTestOrganizationNodes.validate(),
        treeCheckable: true,
        showCheckedStrategy: TreeSelect.SHOW_ALL,
        placeholder: viewModel.queryTestOrganizationNodes.placeholder,
        allowClear: false,
        maxTagCount: 'responsive',
        label: viewModel.queryTestOrganizationNodes.label,
        showSearch: true,
        style: { width: '100%' },
        className: 'hierarchy-filter',
        filterTreeNode: (value, option) => option.label?.toLowerCase().indexOf(value.toLowerCase()) > -1,
        getPopupContainer: () => container.current,
        tagRender: tagRender,
    }

    const columnsTransferProps = {
        dataSource: columns,
        titles: [locale('reports.columns.available'), locale('reports.columns.selected')],
        targetKeys: selectedColumns,
        onChange: targetKeys => {
            viewModel.columns.setValue(targetKeys.map(key => getColumnByKey(columns, key))).validate()
        },
        onBlur: () => viewModel.columns.validate(),
        oneWay: false,
        render: item => item.label,
        showSearch: true,
        locale: locale('antd', 'Transfer'),
    }

    const onBack = () => {
        if (viewModel.insufficientPermissionsMessage.value) {
            viewModel.cancel.onTrigger()
        }
    }

    const onFinish = useCallback(() => {
        const validationResult = viewModel.validate()
        if (O(validationResult).size === 0) {
            viewModel.save.onTrigger()
        }
    }, [viewModel])

    useEffect(() => {
        setColumns(formatColumns(viewModel.columns.dictionary))
    }, [viewModel.columns.dictionary])

    useEffect(() => {
        const selected = viewModel.columns.value
            ?.map(({ sourceType, field }) => getColumnValue(sourceType, field))
            .filter(value => !!value)
        setSelectedColumns(selected)
    }, [viewModel.columns.value, columns])

    const {languageCode} = useContext(LocaleContext)
    const reportName = 'reportName' + languageCode.charAt(0).toUpperCase() + languageCode.slice(1)

    const breadcrumbItems = [
        { title: <Link routePath={reportsPath}>{locale('reports')}</Link> },
        { title: viewModel[reportName].value ? viewModel[reportName].value : '' },
    ]

    return (
        <PageHeader
            className={`report-editor ${className}`}
            ref={container}
            title={panelTitle}
            onBack={onBack}
            ghost={false}
            breadcrumbRender={() => <Breadcrumb items={breadcrumbItems} />}
        >
            {viewModel.insufficientPermissionsMessage.visible && (
                <Alert
                    message={viewModel.insufficientPermissionsMessage.label}
                    showIcon
                    description={viewModel.insufficientPermissionsMessage.value}
                    type="error"
                />
            )}
            <Alerts errors={viewModel.errors} />
            {!viewModel.insufficientPermissionsMessage.visible && (
                <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                    spinning={
                        isPending(viewModel.reportRequestStatus.value) || isPending(viewModel.saveRequestStatus.value)
                    }
                >
                    <Form layout="vertical" onFinish={viewModel.save.onTrigger} disabled={viewModel.save.isLoading}>
                        <Row gutter={48}>
                            <Col span={12} flex={1}>
                                {viewModel.reportNumber.visible && (
                                    <Form.Item label={viewModel.reportNumber.label}>
                                        <span>{viewModel.reportNumber.value}</span>
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col span={12} flex={1}>
                                <Form.Item
                                    label={viewModel.reportNameDe.label}
                                    validateStatus={viewModel.reportNameDe.validationStatus}
                                    hasFeedback={viewModel.reportNameDe.isInvalid}
                                    required={viewModel.reportNameDe.isRequired}
                                    disabled={viewModel.reportNameDe.disabled}
                                    help={renderValidationMessages(viewModel.reportNameDe.validationMessages)}
                                >
                                    <Input
                                        placeholder={viewModel.reportNameDe.placeholder}
                                        value={viewModel.reportNameDe.value}
                                        onChange={event => viewModel.reportNameDe.setValue(event.target.value)}
                                        onBlur={() => viewModel.reportNameDe.validate()}
                                        suffix={
                                            <span />
                                        } /* https://ant.design/components/input/#Why-Input-lose-focus-when-change-prefix/suffix/showCount */
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} flex={1}>
                                <Form.Item
                                    label={viewModel.reportNameFr.label}
                                    validateStatus={viewModel.reportNameFr.validationStatus}
                                    hasFeedback={viewModel.reportNameFr.isInvalid}
                                    required={viewModel.reportNameFr.isRequired}
                                    disabled={viewModel.reportNameFr.disabled}
                                    help={renderValidationMessages(viewModel.reportNameFr.validationMessages)}
                                >
                                    <Input
                                        placeholder={viewModel.reportNameFr.placeholder}
                                        value={viewModel.reportNameFr.value}
                                        onChange={event => viewModel.reportNameFr.setValue(event.target.value)}
                                        onBlur={() => viewModel.reportNameFr.validate()}
                                        suffix={
                                            <span />
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col span={12} flex={1}>
                                <Form.Item
                                    label={viewModel.reportNameIt.label}
                                    validateStatus={viewModel.reportNameIt.validationStatus}
                                    hasFeedback={viewModel.reportNameIt.isInvalid}
                                    required={viewModel.reportNameIt.isRequired}
                                    disabled={viewModel.reportNameIt.disabled}
                                    help={renderValidationMessages(viewModel.reportNameIt.validationMessages)}
                                >
                                    <Input
                                        placeholder={viewModel.reportNameIt.placeholder}
                                        value={viewModel.reportNameIt.value}
                                        onChange={event => viewModel.reportNameIt.setValue(event.target.value)}
                                        onBlur={() => viewModel.reportNameIt.validate()}
                                        suffix={
                                            <span />
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} flex={1}>
                                <Form.Item
                                    label={viewModel.reportNameEn.label}
                                    validateStatus={viewModel.reportNameEn.validationStatus}
                                    hasFeedback={viewModel.reportNameEn.isInvalid}
                                    required={viewModel.reportNameEn.isRequired}
                                    disabled={viewModel.reportNameEn.disabled}
                                    help={renderValidationMessages(viewModel.reportNameEn.validationMessages)}
                                >
                                    <Input
                                        placeholder={viewModel.reportNameEn.placeholder}
                                        value={viewModel.reportNameEn.value}
                                        onChange={event => viewModel.reportNameEn.setValue(event.target.value)}
                                        onBlur={() => viewModel.reportNameEn.validate()}
                                        suffix={
                                            <span />
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col span={12}>
                                <Form.Item
                                    label={viewModel.visibility.label}
                                    validateStatus={viewModel.visibility.validationStatus}
                                    hasFeedback={viewModel.visibility.isInvalid}
                                    required={viewModel.visibility.isRequired}
                                    help={renderValidationMessages(viewModel.visibility.validationMessages)}
                                >
                                    <TreeSelect {...hierarchyProps} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col span={12}>
                                <Form.Item
                                    label={viewModel.descriptionDe.label}
                                    validateStatus={viewModel.descriptionDe.validationStatus}
                                    hasFeedback={viewModel.descriptionDe.isInvalid}
                                    required={viewModel.descriptionDe.isRequired}
                                    help={renderValidationMessages(viewModel.descriptionDe.validationMessages)}
                                >
                                    <TextArea
                                        placeholder={viewModel.descriptionDe.placeholder}
                                        value={viewModel.descriptionDe.value}
                                        onChange={event => viewModel.descriptionDe.setValue(event.target.value)}
                                        onBlur={() => viewModel.descriptionDe.validate()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={viewModel.descriptionFr.label}
                                    validateStatus={viewModel.descriptionFr.validationStatus}
                                    hasFeedback={viewModel.descriptionFr.isInvalid}
                                    required={viewModel.descriptionFr.isRequired}
                                    help={renderValidationMessages(viewModel.descriptionFr.validationMessages)}
                                >
                                    <TextArea
                                        placeholder={viewModel.descriptionFr.placeholder}
                                        value={viewModel.descriptionFr.value}
                                        onChange={event => viewModel.descriptionFr.setValue(event.target.value)}
                                        onBlur={() => viewModel.descriptionFr.validate()}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col span={12}>
                                <Form.Item
                                    label={viewModel.descriptionIt.label}
                                    validateStatus={viewModel.descriptionIt.validationStatus}
                                    hasFeedback={viewModel.descriptionIt.isInvalid}
                                    required={viewModel.descriptionIt.isRequired}
                                    help={renderValidationMessages(viewModel.descriptionIt.validationMessages)}
                                >
                                    <TextArea
                                        placeholder={viewModel.descriptionIt.placeholder}
                                        value={viewModel.descriptionIt.value}
                                        onChange={event => viewModel.descriptionIt.setValue(event.target.value)}
                                        onBlur={() => viewModel.descriptionIt.validate()}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={viewModel.descriptionEn.label}
                                    validateStatus={viewModel.descriptionEn.validationStatus}
                                    hasFeedback={viewModel.descriptionEn.isInvalid}
                                    required={viewModel.descriptionEn.isRequired}
                                    help={renderValidationMessages(viewModel.descriptionEn.validationMessages)}
                                >
                                    <TextArea
                                        placeholder={viewModel.descriptionEn.placeholder}
                                        value={viewModel.descriptionEn.value}
                                        onChange={event => viewModel.descriptionEn.setValue(event.target.value)}
                                        onBlur={() => viewModel.descriptionEn.validate()}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label={viewModel.columns.label}
                                    validateStatus={viewModel.columns.validationStatus}
                                    hasFeedback={viewModel.columns.isInvalid}
                                    help={renderValidationMessages(viewModel.columns.validationMessages)}
                                >
                                    <Transfer {...columnsTransferProps} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider />
                        <Form.Item
                            label={viewModel.queryTestOrganizationNodes.label}
                            validateStatus={viewModel.queryTestOrganizationNodes.validationStatus}
                            hasFeedback={viewModel.queryTestOrganizationNodes.isInvalid}
                            required={viewModel.queryTestOrganizationNodes.isRequired}
                            help={renderValidationMessages(viewModel.queryTestOrganizationNodes.validationMessages)}
                        >
                            <TreeSelect {...hierarchyFilterProps} />
                        </Form.Item>
                        <Form.Item
                            label={viewModel.queryDe.label}
                            validateStatus={viewModel.queryDe.validationStatus}
                            hasFeedback={viewModel.queryDe.isInvalid}
                            required={viewModel.queryDe.isRequired}
                            help={renderValidationMessages(viewModel.queryDe.validationMessages)}
                        >
                            <Row gutter={16}>
                                <Col flex={1}>
                                    <TextArea
                                        placeholder={viewModel.queryDe.placeholder}
                                        value={viewModel.queryDe.value}
                                        onChange={event => viewModel.queryDe.setValue(event.target.value)}
                                        onBlur={() => viewModel.queryDe.validate()}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        shape="round"
                                        htmlType="button"
                                        disabled={viewModel.queryTest.disabled}
                                        icon={
                                            viewModel.queryTestStatus.value?.status === STATUS_PENDING && (
                                                <LoadingOutlined style={{ fontSize: 16 }} spin />
                                            )
                                        }
                                        onClick={
                                            viewModel.queryTestStatus.value?.status === STATUS_PENDING
                                                ? viewModel.queryTestStop.onTrigger
                                                : viewModel.queryTest.onTrigger
                                        }
                                    >
                                        {viewModel.queryTestStatus.value?.status === STATUS_PENDING
                                            ? viewModel.queryTestStop.label
                                            : viewModel.queryTest.label}
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label={viewModel.queryFr.label}
                            validateStatus={viewModel.queryFr.validationStatus}
                            hasFeedback={viewModel.queryFr.isInvalid}
                            required={viewModel.queryFr.isRequired}
                            help={renderValidationMessages(viewModel.queryFr.validationMessages)}
                        >
                            <Row gutter={16}>
                                <Col flex={1}>
                                    <TextArea
                                        placeholder={viewModel.queryFr.placeholder}
                                        value={viewModel.queryFr.value}
                                        onChange={event => viewModel.queryFr.setValue(event.target.value)}
                                        onBlur={() => viewModel.queryFr.validate()}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label={viewModel.queryIt.label}
                            validateStatus={viewModel.queryIt.validationStatus}
                            hasFeedback={viewModel.queryIt.isInvalid}
                            required={viewModel.queryIt.isRequired}
                            help={renderValidationMessages(viewModel.queryIt.validationMessages)}
                        >
                            <Row gutter={16}>
                                <Col flex={1}>
                                    <TextArea
                                        placeholder={viewModel.queryIt.placeholder}
                                        value={viewModel.queryIt.value}
                                        onChange={event => viewModel.queryIt.setValue(event.target.value)}
                                        onBlur={() => viewModel.queryIt.validate()}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label={viewModel.queryEn.label}
                            validateStatus={viewModel.queryEn.validationStatus}
                            hasFeedback={viewModel.queryEn.isInvalid}
                            required={viewModel.queryEn.isRequired}
                            help={renderValidationMessages(viewModel.queryEn.validationMessages)}
                        >
                            <Row gutter={16}>
                                <Col flex={1}>
                                    <TextArea
                                        placeholder={viewModel.queryEn.placeholder}
                                        value={viewModel.queryEn.value}
                                        onChange={event => viewModel.queryEn.setValue(event.target.value)}
                                        onBlur={() => viewModel.queryEn.validate()}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label={viewModel.queryTestResult.label}
                            validateStatus={viewModel.queryTestResult.validationStatus}
                            hasFeedback={viewModel.queryTestResult.isInvalid}
                            help={renderValidationMessages(viewModel.queryTestResult.validationMessages)}
                        >
                            <TextArea
                                className="report-editor__test-result"
                                placeholder={viewModel.queryTestResult.placeholder}
                                value={viewModel.queryTestResult.value}
                                disabled
                            />
                        </Form.Item>
                        <div className="jb-content-container__footer">
                            <Space>
                                <Button shape="round" type="primary" htmlType="submit">
                                    {viewModel.save.label}
                                </Button>
                                <Button shape="round" htmlType="button" onClick={viewModel.cancel.onTrigger}>
                                    {viewModel.cancel.label}
                                </Button>
                            </Space>
                        </div>
                    </Form>
                </Spin>
            )}
        </PageHeader>
    )
}
