import { CATEGORIES, Logger } from '@modules/logging/logger'
import { PluginManager } from '@modules/plugins/plugin_manager'
import { O } from '@prospective/pms-js-utils'
import { plugins } from '../configuration/plugins'
import { Process } from '@prospective/process-router'
import { CONFIG, PMS_CUSTOMERS } from '@configuration/config_variables.js'

const logger = Logger('PluginsProcess', CATEGORIES.MAIN)
export const Plugins = Process(({ process }) => {
    logger.info('Starting PluginProcess')

    return async () => {
        PluginManager.plugins = plugins
        const isVDVMandate = CONFIG.customer === PMS_CUSTOMERS.VDV
        const customer = CONFIG.customer.code
        const activePlugins = !O(plugins).hasKey(customer)
            ? ['JB']
            : isVDVMandate
                ? ['SC_APP', 'VDV']
                : [customer]
        logger.debug(`Updating active plugins: ${activePlugins.join(',')}`)
        try {
            await PluginManager.setActivePlugins(activePlugins)
        } catch (e) {
            process.throw('Some plugins could not be activated')
        }
        if (PluginManager.status === PluginManager.STATUS_LOADING) {
            const status = await PluginManager.onStatusChange.promise
            if (status === PluginManager.STATUS_ERROR) {
                process.throw('Some plugins could not be activated')
                return
            }
        }
        process.exit()
    }
})

Plugins.label = 'Plugin Process'
