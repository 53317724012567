/**
 * @typedef {Object} ThemeDescriptor
 * @property {string} name unique Theme name
 * @property {string} label Theme label for humans
 * @property {string} description Theme description
 * @property {Object} preview Theme preview image
 * @property {Object} theme Theme object
 */
import { Publisher } from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@modules/logging/logger.js'
import { defaultDarkTheme, defaultTheme } from '@themes/default/default.theme.js'
import { O } from '@prospective/pms-js-utils'
import { darkTheme } from '@themes/dark/dark.theme.js'
import { stellenCockpitDarkTheme, stellenCockpitTheme } from '@themes/stellencockpit.theme.js'

const logger = Logger('ThemeManager', CATEGORIES.MAIN)

const defaultThemeDescriptor = {
    name: 'default',
    label: 'Default theme',
    description: 'Default JobBooster theme',
    theme: defaultTheme,
    darkTheme: defaultDarkTheme,
}

let state = {
    themes: [defaultThemeDescriptor],
    activeTheme: defaultThemeDescriptor,
    darkMode: false,
}

const [change, publishChange] = Publisher()
const [activeThemeChange, publishActiveThemeChange] = Publisher()

const update = updateFunction => {
    let newState = {
        themes: state.themes,
        activeTheme: state.activeTheme,
        darkMode: state.darkMode,
    }
    /**
     * @param {ThemeDescriptor[]} themes
     */
    const setThemes = themes => {
        newState.themes = themes
        const newThemeNames = themes.map(theme => theme.name)
        newState.activeTheme =
            newState.themes.find(theme => theme.name === newState.activeTheme?.name) || newState.activeTheme
    }
    const setActiveTheme = themeDescriptor => {
        // First, clone the current active theme to avoid direct mutation
        let clonedActiveTheme = structuredClone(newState.activeTheme)

        // Merge top-level properties from themeDescriptor into clonedActiveTheme, excluding the 'theme' property
        Object.keys(themeDescriptor).forEach(key => {
            if (key !== 'theme') clonedActiveTheme[key] = themeDescriptor[key]
        })

        // Use O's deepMerge function for the 'theme' property
        if (themeDescriptor.theme && clonedActiveTheme.theme)
            clonedActiveTheme.theme = O(clonedActiveTheme.theme).deepMerge(themeDescriptor.theme).valueOf()
        else if (themeDescriptor.theme)
            // If there's no existing theme, just use the themeDescriptor's theme directly
            clonedActiveTheme.theme = themeDescriptor.theme

        // Finally, update the newState with the merged active theme
        newState.activeTheme = clonedActiveTheme
    }
    const setDarkMode = value => (newState.darkMode = value)

    try {
        updateFunction({ setThemes, setActiveTheme, setDarkMode })
    } catch (error) {
        logger.error.withError(error, 'An error occurred while updating Theme state')
        newState = state
        return false
    }

    const didThemesChange = state.themes !== newState.themes
    const didActiveThemeChange = state.activeTheme !== newState.activeTheme
    const didDarkModeChange = state.darkMode !== newState.darkMode

    state = newState
    if (didThemesChange || didActiveThemeChange || didDarkModeChange) publishChange(state)
    if (didActiveThemeChange || didDarkModeChange) publishActiveThemeChange(state.activeTheme, state.darkMode)
    return true
}

/**
 *
 * @type {IStatePublisher|{activeThemeChange: IPublisher, update: function(function({ setThemes, setActiveTheme, setDarkMode }))}}
 */
export const ThemeManager = {
    ...change,
    activeThemeChange,
    update,
    defaultThemeDescriptor,
    get themes() {
        return state.themes
    },
    get state() {
        return state
    },
    get activeTheme() {
        return state.activeTheme
    },
    get darkMode() {
        return state.darkMode
    },
}
