export const langChoices = [
    { language: 'English US', country: 'usa', tag: 'en-US'  },
    { language: 'English', country: 'greatBritain', tag: 'en-GB' },
    { language: 'Deutsch', country: 'germany', tag: 'de-DE' },
    { language: 'Deutsch AT', country: 'austria', tag: 'de-AT' },
    { language: 'Deutsch CH', country: 'switzerland', tag: 'de-CH' },
    { language: 'Schweizerdeutsch', country: 'switzerland', tag: 'gsw_CH' },
    { language: 'Français', country: 'france', tag: 'fr-FR' },
    { language: 'Français CH', country: 'switzerland', tag: 'fr-CH'},
    { language: 'Italiano', country: 'italy', tag: 'it-IT' },
    { language: 'Polski', country: 'poland', tag: 'pl-PL' },
]
