import { useState, useEffect } from 'react'
import { Switch } from 'antd'
import { Localization } from '@lib/i18n/localization'
import './translations_viewer.scss'

const TranslationsViewer = () => {
    const [translationChecked, setTranslationChecked] = useState(false)

    const onLocalizationChange = localization => {
        setTranslationChecked(localization.debugMode)
    }

    useEffect(() => {
        onLocalizationChange(Localization)
        Localization.subscribe(onLocalizationChange)
        return () => {
            Localization.unsubscribe(onLocalizationChange)
        }
    }, [])

    const switchChangeHandler = checked => {
        setTranslationChecked(checked)
        Localization.setDebugMode(checked)
    }
    return (
        <div className="translations-viewer">
            <p className="translations-viewer__text">Toggle translation keys</p>
            <Switch checked={translationChecked} onChange={switchChangeHandler} />
        </div>
    )
}

export default TranslationsViewer
