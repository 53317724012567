
import { ProAnalyticsFilters } from '@views/pro_analytics/pro_analytics_filters/pro_analytics_filters'
import { useViewModel } from '@lib/view_context/view-model.js'
import { ProAnalyticsContext } from '@views/pro_analytics/pro_analytics.context.js'

export default function ProAnalyticsSubHeader({ className = '', ...props }) {
    const viewModel = useViewModel(ProAnalyticsContext)
    return !viewModel.errors.insufficientPermissions && (
        <div className={`ProAnalyticsSubHeader ${className}`} {...props} style={{ flexGrow: 1 }}>
            <ProAnalyticsFilters />
        </div>
    )
}
