import { Localization } from '@lib/i18n/localization'
import { Authorization } from '@modules/authorization/authorization'
import { PluginManager } from '@modules/plugins/plugin_manager'
import { DatePickerDictionary } from '@components/elements/datepicker/datepicker'
import { KPIMetadata } from '../kpi_widget/cockpit_kpis.context.js'
import { View } from '@lib/view_context/view-context.js'

export const getDefaultDateRange = () => {
    const today = new Date()
    return {
        from: new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0),
        to: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0),
    }
}

export const ProAnalyticsJobMetadata = (locale, permissions, features) => {
    const performanceWidgetViewByOptions = [
        { label: locale('proAnalytics.viewBy.jobs'), value: 'STELLEN' },
        {
            label: locale('proAnalytics.viewBy.fieldOfActivity'),
            value: 'FIELD_OF_ACTIVITY',
        },
    ]
    return {
        costsPerMediumStatus: {
            label: locale('status'),
        },
        currency: {
            default: undefined,
            label: 'currencies',
        },
        webMobile: {
            label: locale('webMobile'),
        },
        dateRange: {
            label: locale('dateRange'),
        },
        backAction: {
            label: locale('back'),
            actions: ['onTrigger'],
        },
        jobDetails: {
            name: 'Job details',
        },
        candidateJourneyStatisticsStatus: {
            label: locale('status'),
        },
        performanceStatistics: {
            label: locale('proAnalytics.performanceStatistics'),
            default: [],
            visible: permissions.performance?.permissions.read || false,
            description: locale('infoTooltip.performanceWidget'),
        },
        performanceStatisticsStatus: {
            label: locale('status'),
        },
        kpiReports: {
            visible: permissions.kpi?.permissions.read || false,
        },
        /*kpiActiveJobs: {
            label: locale('kpiStatistics.stellen'),
            description: locale('infoTooltip.kpi.jobs'),
        },
        kpiActivePublications: {
            label: locale('kpiStatistics.publications'),
            description: locale('infoTooltip.kpi.publications'),
        },
        kpiCosts: {
            label: locale('kpiStatistics.costs'),
            description: locale('infoTooltip.kpi.costs'),
        },
        kpiStatistics: {
            label: locale('proAnalytics.kpiStatistics'),
            visible: permissions.kpi?.permissions.read || false,
        },*/
        ...KPIMetadata(locale, permissions),
        candidateJourneyStatistics: {
            label: locale('proAnalytics.candidateJourneyStatistics'),
            default: [],
            visible: permissions.candidateJourney?.permissions.read || false,
        },
        costsPerMediumStatistics: {
            label: locale('proAnalytics.costsPerMediumStatistics'),
            default: [],
            visible: permissions.costsPerMedium?.permissions.read || false,
            description: locale('infoTooltip.costsPerMediumWidget'),
        },
        costsStatistics: {
            label: locale('proAnalytics.costsStatistics'),
            default: [],
            visible: permissions.costs?.permissions.read || false,
            description: locale('infoTooltip.costsWidget'),
        },
        performanceStatisticsActionType: {
            label: locale('proAnalytics.performanceStatisticsActionType'),
            default: 'VIEW',
            dictionary: features.proAnalytics?.performanceWidget.actionType.options.inject(),
        },
        performanceStatisticsViewBy: {
            default: performanceWidgetViewByOptions[0],
            dictionary: performanceWidgetViewByOptions,
        },
        performanceStatisticsJobFilter: {
            default: null,
        },
        performanceStatisticsActivityFieldFilter: {
            default: null,
        },
        costsStatisticsStatus: {
            label: locale('status'),
        },
        kpiStatisticsStatus: {
            label: locale('status'),
        },
        costsByViews: {
            label: locale('proAnalytics.costsProView'),
        },
        costsByClicks: {
            label: locale('proAnalytics.costsProView'),
        },
        singleJobsDatesSpan: {
            label: 'Single Job Date Span',
        },
        costsByApplications: {
            label: locale('proAnalytics.costsProApplication'),
        },
        dateRangeFilter: {
            label: locale('timePeriod'),
            get default() {
                return getDefaultDateRange()
            },
            dictionary: DatePickerDictionary(locale),
            actions: ['onChange'],
        },
        jobDetailsDatesSpanStatus: {
            label: 'Job details dates span status',
        },
        jobDetailsDatesSpan: {
            label: 'Job details dates span',
        },
        mediaFilter: {
            label: locale('media'),
            dictionary: [],
            actions: ['onChange'],
        },
        mediaFilterRequestStatus: {
            locale: 'Media filters request status',
        },
        resetFilters: {
            label: locale('proAnalytics.resetFilters'),
            actions: ['onTrigger'],
        },
    }
}

export const ProAnalyticsJobContext = View.observe(Localization, Authorization, PluginManager).update(
    (localization, authorization, pluginManager) =>
        ProAnalyticsJobMetadata(localization.text, authorization.proAnalyticsPermissions, pluginManager.features)
)
ProAnalyticsJobContext.displayName = 'ProAnalyticsJobContext'
