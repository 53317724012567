import { Localization } from '@lib/i18n/localization.js'
import { Authorization } from '@modules/authorization/authorization.js'
import { DEFAULT_EMPTY_VALUES, lengthValidator, requiredValidator } from '@prospective/pms-view-context'
import { createIdleStatus } from '@utils/request_statuses'
import { View } from '@lib/view_context/view-context'

const isEmptyCollection = value => value?.length === 0

export const ReportEditorMetadata = (locale, permissions) => ({
    title: {
        label: locale('reports.editReport'),
    },
    reportNameDe: {
        label: locale('reports.reportName', { lang: 'de' }),
        validators: [requiredValidator(locale('reports.reportNameRequiredError'))],
    },
    reportNameFr: {
        label: locale('reports.reportName', { lang: 'fr' }),
        validators: [requiredValidator(locale('reports.reportNameRequiredError'))],
    },
    reportNameIt: {
        label: locale('reports.reportName', { lang: 'it' }),
        validators: [requiredValidator(locale('reports.reportNameRequiredError'))],
    },
    reportNameEn: {
        label: locale('reports.reportName', { lang: 'en' }),
        validators: [requiredValidator(locale('reports.reportNameRequiredError'))],
    },
    reportNumber: {
        label: locale('reports.reportNumber'),
        disabled: true,
    },
    visibility: {
        label: locale('reports.visibleFor'),
        validators: [
            requiredValidator(locale('reports.reportVisibilityRequiredError'), [
                ...DEFAULT_EMPTY_VALUES,
                isEmptyCollection,
            ]),
        ],
    },
    columns: {
        label: locale('reports.columns'),
        validators: [
            requiredValidator(locale('reports.reportColumnsRequiredError'), [
                ...DEFAULT_EMPTY_VALUES,
                isEmptyCollection,
            ]),
        ],
    },
    columnsRequestStatus: {
        label: locale('status'),
    },
    descriptionDe: {
        label: `${locale('reports.description', { lang: 'de' })} (${locale('reports.description.hint')})`,
        validators: [lengthValidator(locale('reports.reportDescriptionLengthError', { maxLength: 150 }), 0, 150)],
    },
    descriptionFr: {
        label: `${locale('reports.description', { lang: 'fr' })} (${locale('reports.description.hint')})`,
        validators: [lengthValidator(locale('reports.reportDescriptionLengthError', { maxLength: 150 }), 0, 150)],
    },
    descriptionIt: {
        label: `${locale('reports.description', { lang: 'it' })} (${locale('reports.description.hint')})`,
        validators: [lengthValidator(locale('reports.reportDescriptionLengthError', { maxLength: 150 }), 0, 150)],
    },
    descriptionEn: {
        label: `${locale('reports.description', { lang: 'en' })} (${locale('reports.description.hint')})`,
        validators: [lengthValidator(locale('reports.reportDescriptionLengthError', { maxLength: 150 }), 0, 150)],
    },
    queryDe: {
        label: locale('reports.query', { lang: 'de' }),
        default: 'select * from :result_data',
    },
    queryFr: {
        label: locale('reports.query', { lang: 'fr' }),
        default: 'select * from :result_data',
    },
    queryIt: {
        label: locale('reports.query', { lang: 'it' }),
        default: 'select * from :result_data',
    },
    queryEn: {
        label: locale('reports.query', { lang: 'en' }),
        default: 'select * from :result_data',
    },
    queryTest: {
        label: locale('reports.test'),
        disabled: !permissions.reports.management.permissions.execute,
        actions: ['onTrigger'],
    },
    queryTestStop: {
        label: locale('reports.testStop'),
        actions: ['onTrigger'],
    },
    queryTestOrganizationNodes: {
        label: locale('organizationStructure'),
        actions: ['onChange'],
        validators: [
            requiredValidator(locale('reports.organizationFilterRequired'), [
                ...DEFAULT_EMPTY_VALUES,
                isEmptyCollection,
            ]),
        ],
    },
    queryTestStatus: {
        label: locale('status'),
    },
    queryTestResult: {
        label: locale('reports.testResult'),
    },
    cancel: {
        label: locale('cancel'),
        actions: ['onTrigger'],
    },
    save: {
        label: locale('saveAndClose'),
        actions: ['onTrigger'],
        isLoading: false,
    },
    insufficientPermissionsMessage: {
        label: locale('insufficientPermissions'),
        value: locale('reports.edit.insufficientPermissions'),
        visible: !permissions?.reports?.management?.permissions?.write,
    },
    reportRequestStatus: {
        label: locale('status'),
    },
    saveRequestStatus: {
        label: locale('status'),
        default: createIdleStatus(),
    },
})

export const ReportEditorContext = View.observe(Localization, Authorization).update((localization, authorization) =>
    ReportEditorMetadata(localization.text, authorization.proAnalyticsPermissions)
)
