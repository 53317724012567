import { customPriceFormatter } from '@lib/i18n/custom_price.formatter.js'

export default {
'dateFormatter': date => date ? `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}` : ' - ', 
'dateFormatter.shortDate': date => date ? `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}` : ' - ', 
'monthFormatter': date => date ? date.toLocaleString('de-CH', {year: 'numeric', month: 'long'}) : ' - ',
'priceFormatter': (price, currency) => isNaN(price) ? '-' : currency ? price.toLocaleString('de-CH', {style: 'currency', currency}) : price.toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
'priceFormatter2': (price, currency) => customPriceFormatter(price, currency, 'de-CH'),
'numberFormatter': (number) => number ? number.toLocaleString('de-CH', {minimumFractionDigits: 0, maximumFractionDigits: 0}):0,
'twoDigitFormatter': (number) => number ? number.toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2}):0, 
}
