import { CATEGORIES, Logger } from '@modules/logging/logger'
import {
    careerCenterDefaultActionType,
    getDefaultDateRange,
    performanceStatisticsDefaultActionType,
    ProAnalyticsContext,
} from '@views/pro_analytics/pro_analytics.context'
import { warningModal } from '@components/modules/global_notifications/global_notifications.jsx'
import {
    calculateDeviceRatioKPIs,
    DateRange,
    filterOnlineMediaCosts,
    filterRelevantCosts,
    firstSelectableDate,
    formatSearchSuggestion,
    getResolution,
    JOB_COUNT_FIELDS,
    lastSelectableDate,
    validateDateRange,
} from '@views/pro_analytics/pro_analytics_utils'
import { Localization } from '@lib/i18n/localization'
import {
    distinct,
    formatISODate,
    haveSameElements,
    REFERENCE_COMPARE_FUNCTION,
    tryCatch,
} from '@prospective/pms-js-utils'
import { ProAnalyticsSearch } from '@views/pro_analytics/pro_analytics_search'
import { ProAnalyticsReport } from '@views/pro_analytics/pro_analytics_report/pro_analytics_report.process'
import { getReports, getXlsReport } from '@views/pro_analytics/pro_analytics_report/reports_utilities/reports_utilities'
import { ProAnalyticsFilters } from '@views/pro_analytics/pro_analytics_filters'
import { ProAnalyticsFiltersInterface } from '@views/pro_analytics/pro_analytics_filters.interface'
import { Dictionaries } from '@modules/dictionaries/dictionaries.js'
import { PluginManager } from '@modules/plugins/plugin_manager.js'
import { ProAnalyticsOrganizationFilter } from '@views/pro_analytics/pro_analytics_organization_filter'
import { Authorization } from '@modules/authorization/authorization'
import { includeChildNodes } from '@modules/dictionaries/organization-hierarchy'
import { CustomerConfig } from '@modules/customer_config/customer_config'
import {
    abortAndCreateNewController,
    createErrorStatus,
    createPendingStatus,
    createSuccessStatus,
    getErrorStructure,
} from '@utils/request_statuses'
import { Process, stateOf, terminate, useCallback, useMemo, useMemoLast, useState } from '@prospective/process-router'
import { endOfDay, startOfDay } from 'date-fns'
import { UserSession } from '@login/user-session'
import { injectPluginProcesses } from '@modules/plugins/plugin_manager_process_utils.js'
import { CustomerConfiguration } from '@modules/customer_config/customer_config.process.js'
import { ReportListProcess } from '@views/settings/reports/report_list.process.js'
import { useView } from '@lib/view_context/view_context_hooks.js'
import { useRemoteDataStream } from '@utils/process_hooks.js'
import { ModuleController } from '@modules/module_controller.js'
import { ProAnalyticsService } from '@views/pro_analytics/pro_analytics.service.js'
import JobBoosterService, { JobBoosterServiceError } from '@services/job_booster_service'

const logger = Logger('ProAnalytics', CATEGORIES.MAIN)

const isSameLocale = (locale1, locale2) => locale1(Localization.LOCALE_TAG) === locale2(Localization.LOCALE_TAG)
const dateRangeValidation = (params, defaultDateRange) => {
    const validDateRange = validateDateRange(params.dateRangeFilter, defaultDateRange)
    let parameters = params
    if (!DateRange(validDateRange).equal(params.dateRangeFilter)) {
        if (params.dateRangeFilter)
            warningModal(
                Localization.locale('proAnalytics.dateRange.autoCorrectionWarning', {
                    start: firstSelectableDate,
                    end: lastSelectableDate,
                })
            )
        parameters = { ...params, dateRangeFilter: validDateRange }
    }
    return parameters
}

const percent = (value = 0, total = 0) => (total !== 0 ? (value / total) * 100 : 0)

const getKpiStatistics = (performanceData = { publicationPerformance: [] }) => {
    return performanceData.publicationPerformance.reduce(
        (kpis, item) => {
            kpis.views += item.views || 0
            kpis.clicks += item.clicks || 0
            kpis.applications += item.applications || 0
            kpis.deviceRatio.total += item.viewsWeb + item.viewsMobile
            kpis.deviceRatio.web += item.viewsWeb
            kpis.deviceRatio.mobile += item.viewsMobile
            kpis.deviceRatio.percentWeb = (kpis.deviceRatio.web / kpis.deviceRatio.total) * 100
            kpis.deviceRatio.percentMobile = 100 - kpis.deviceRatio.percentWeb
            return kpis
        },
        {
            views: 0,
            clicks: 0,
            applications: 0,
            deviceRatio: { total: 0, web: 0, mobile: 0 },
            pits: performanceData.pitsToday,
            jobs: distinct(performanceData.publicationPerformance, (item1, item2) => item1.stelleId === item2.stelleId)
                .length,
            costsTotal: performanceData.costsTotal,
            activePublications: performanceData.activePublications,
            dailyCosts: performanceData.dailyCosts,
        }
    )
}

/**
 *
 * @param process
 * @param route
 * @param {ProAnalyticsParams} params
 * @param setParams
 * @returns {(function(): Promise<void>)|*}
 */
export const ProAnalyticsProcess = Process(({ process, params, setParams }) => {
    const { locale } = stateOf(Localization)
    const { features } = stateOf(PluginManager)
    const { proAnalyticsPermissions } = stateOf(Authorization)
    const customerConfig = stateOf(CustomerConfig)
    const userSession = stateOf(UserSession)
    const userDictionaries = stateOf(Dictionaries)
    const view = useView(ProAnalyticsContext)

    const [allReportsAbortController, setAllReportsAbortController] = useState()
    const [xlsDownloadLoading, setXlsDownloadLoading] = useState(null)
    const [reportsStatus, setReportsStatus] = useState()
    const [reports, setReports] = useState()

    const [jobsStatistics, setJobsStatistics] = useState()
    const [jobsCount, setJobsCount] = useState()
    const [publicationsStatistics, setPublicationsStatistics] = useState()
    const [candidateJourneyStatistics, setCandidateJourneyStatistics] = useState()
    const [performanceStatistics, setPerformanceStatistics] = useState()
    const [costsStatistics, setCostsStatistics] = useState()
    const [costsByViews, setCostsByViews] = useState()
    const [costsByClicks, setCostsByClicks] = useState()
    const [costsByApplications, setCostsByApplications] = useState()
    const [careerCenterStatistics, setCareerCenterStatistics] = useState()
    const [careerCenterStatisticsActionType, setCareerCenterStatisticsActionType] =
        useState(careerCenterDefaultActionType)
    const [kpiStatistics, setKpiStatistics] = useState()

    const [customerConfigRequestStatus, setCustomerConfigRequestStatus] = useState()
    const [jobCountStatisticsStatus, setJobCountStatisticsStatus] = useState()
    const [candidateJourneyStatisticsStatus, setCandidateJourneyStatisticsStatus] = useState()
    const [performanceStatisticsStatus, setPerformanceStatisticsStatus] = useState()
    const [costStatisticsStatus, setCostStatisticsStatus] = useState()
    const [careerCenterStatisticsStatus, setCareerCenterStatisticsStatus] = useState()
    const [kpiStatisticsStatus, setKpiStatisticsStatus] = useState()

    const [jobCountStatisticsAbortController, setJobCountStatisticsAbortController] = useState()
    const [candidateJourneyStatisticsAbortController, setCandidateJourneyStatisticsAbortController] = useState()
    const [performanceStatisticsAbortController, setPerformanceStatisticsAbortController] = useState()
    const [costStatisticsAbortController, setCostStatisticsAbortController] = useState()
    const [careerCenterStatisticsAbortController, setCareerCenterStatisticsAbortController] = useState()

    const [performanceStatisticsActionType, setPerformanceStatisticsActionType] = useState(
        performanceStatisticsDefaultActionType
    )
    const [performanceStatisticsViewBy, setPerformanceStatisticsViewBy] = useState()
    const [performanceStatisticsFieldOfActivity, setPerformanceStatisticsFieldOfActivity] = useState()
    const [performanceStatisticsJob, setPerformanceStatisticsJob] = useState()

    const [login, loginState] = useRemoteDataStream(ModuleController.login)
    const [getPermissions, getPermissionsState] = useRemoteDataStream(ModuleController.getProAnalyticsPermissions)
    const [getOrganizationStructure, getOrganizationStructureState] = useRemoteDataStream(
        ProAnalyticsService.getOrganizationStructure
    )
    const [organizationStructure, setOrganizationStructure] = useState([])

    const pluginProcesses = injectPluginProcesses(features.proAnalytics, process)
    const terminatePluginProcesses = useCallback(() => pluginProcesses.forEach(process => terminate(process)))
    process.onTerminate(terminatePluginProcesses)

    const proAnalyticsSearch = ProAnalyticsSearch({
        params,
        setParams,
        view,
        suggestionsParser: formatSearchSuggestion,
    })
    const proAnalyticsFilters = ProAnalyticsFilters({
        params,
        setParams,
        view,
    })
    const proAnalyticsOrganizationFilter = ProAnalyticsOrganizationFilter({
        params,
        setParams,
        view,
    })

    const getAllReports = useMemo(async permissions => {
        if (!permissions.reports.widget.permissions.read) return
        const abortController = abortAndCreateNewController(allReportsAbortController)

        setAllReportsAbortController(abortController)
        setReportsStatus(createPendingStatus())

        const [error, reports] = await tryCatch(JobBoosterService.getAllReports)(abortController.signal)

        if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
            const logEntry = logger.info.withError(error, 'Could not load reports')
            setReportsStatus(
                createErrorStatus(
                    locale('proAnalytics.reports.error', {
                        logNumber: logEntry.logNumber,
                    })
                )
            )
            return
        }

        setReportsStatus(createSuccessStatus())

        return reports
    })

    const onReportPreviewClick = useCallback(reportId => {
        ProAnalyticsReport({ ...params, reportId })
    })

    const onReportsManagementClick = useCallback(() => {
        process.exit()
        ReportListProcess()
    })

    const extractKpiStatistics = useMemoLast(getKpiStatistics)

    const handleXlsDownload = async report => {
        const [filtersState] = await Promise.all([proAnalyticsFilters.update(), proAnalyticsSearch.update()])
        const { filters } = filtersState
        const requestParams = ProAnalyticsFiltersInterface(filters).getRequestParameters(organizationStructure, locale)

        setXlsDownloadLoading({ id: report.id, loading: true })
        const [error, data] = await getReports(reports, report.id, requestParams)

        if (error) logger.error.withError(error, 'Export to Excel file failed')
        getXlsReport(data, report, filters.dateRangeFilter, locale)
        setXlsDownloadLoading()
    }

    const updateJobCountStatistics = useMemoLast(
        async (filters, organizationStructure, locale, permissions) => {
            if (!permissions.numberOfPositions.permissions.read) return
            const abortController = abortAndCreateNewController(jobCountStatisticsAbortController)
            setJobCountStatisticsAbortController(abortController)
            setJobCountStatisticsStatus(createPendingStatus())
            const requestParams = ProAnalyticsFiltersInterface(filters).getRequestParameters(
                organizationStructure,
                locale
            )

            const [error, data] = await tryCatch(JobBoosterService.getJobCountStatistics)(
                requestParams,
                abortController.signal
            )
            if (error) {
                if (error.type !== JobBoosterServiceError.ABORT_ERROR) {
                    const logEntry = logger.error.withError(error, 'Could not load job count statistics')
                    const errorMessage = locale('proAnalytics.jobCount.error', {
                        logNumber: logEntry.logNumber,
                    })
                    setJobCountStatisticsStatus(createErrorStatus(errorMessage))
                }
                setJobsStatistics()
                setPublicationsStatistics()
            } else {
                setJobCountStatisticsStatus(createSuccessStatus())
                const jobsStatistics = data?.map(item => ({ ...item, day: new Date(item.day) }))
                setJobsStatistics(getJobsCount(jobsStatistics))
                setPublicationsStatistics(getPublicationsCount(jobsStatistics))
                return jobsStatistics
            }
        },
        [
            ProAnalyticsFiltersInterface.areFiltersEqual,
            REFERENCE_COMPARE_FUNCTION,
            isSameLocale,
            REFERENCE_COMPARE_FUNCTION,
        ]
    )

    const updateCandidateJourneyStatistics = useMemoLast(
        async (filters, organizationStructure, locale, permissions) => {
            if (!permissions.candidateJourney.permissions.read) return
            const abortController = abortAndCreateNewController(candidateJourneyStatisticsAbortController)
            setCandidateJourneyStatisticsAbortController(abortController)
            setCandidateJourneyStatisticsStatus(createPendingStatus())
            const requestParams = ProAnalyticsFiltersInterface(filters).getRequestParameters(
                organizationStructure,
                locale
            )
            const [error, data] = await tryCatch(JobBoosterService.getCandidateJourney)(
                requestParams,
                abortController.signal
            )
            if (error) {
                if (error.type !== JobBoosterServiceError.ABORT_ERROR) {
                    const logEntry = logger.error.withError(error, 'Could not load candidate journey statistics')
                    const errorMessage = locale('proAnalytics.candidateJourney.error', {
                        logNumber: logEntry.logNumber,
                    })
                    setCandidateJourneyStatisticsStatus(createErrorStatus(errorMessage))
                }
                setCandidateJourneyStatistics()
            } else {
                setCandidateJourneyStatisticsStatus(createSuccessStatus())
                setCandidateJourneyStatistics(data)
                return data
            }
        },
        [
            ProAnalyticsFiltersInterface.areFiltersEqual,
            REFERENCE_COMPARE_FUNCTION,
            isSameLocale,
            REFERENCE_COMPARE_FUNCTION,
        ]
    )

    const updatePerformanceStatistics = useMemoLast(
        async (filters, organizationStructure, actionType, locale, permissions, currency) => {
            if (!permissions.performance.permissions.read) return
            const abortController = abortAndCreateNewController(performanceStatisticsAbortController)
            setPerformanceStatisticsAbortController(abortController)
            setPerformanceStatisticsStatus(createPendingStatus())
            const resolution = getResolution(filters.dateRangeFilter.from, filters.dateRangeFilter.to)
            const requestParams = ProAnalyticsFiltersInterface(filters).getRequestParameters(
                organizationStructure,
                locale
            )
            requestParams.resolution = resolution
            requestParams.currency = currency
            requestParams.performanceStatisticsActionType = actionType
            const [error, data] = await tryCatch(JobBoosterService.getPerformanceStatistics)(
                requestParams,
                abortController.signal
            )
            if (error) {
                if (error.type !== JobBoosterServiceError.ABORT_ERROR) {
                    const logEntry = logger.error.withError(error, 'Could not load performance statistics')
                    const errorMessage = locale('proAnalytics.performance.error', {
                        logNumber: logEntry.logNumber,
                    })
                    setPerformanceStatisticsStatus(createErrorStatus(errorMessage))
                }
                setPerformanceStatistics()
            } else {
                setPerformanceStatisticsStatus(createSuccessStatus())
                setPerformanceStatistics(data)
                return data
            }
        },
        [
            ProAnalyticsFiltersInterface.areFiltersEqual,
            REFERENCE_COMPARE_FUNCTION,
            REFERENCE_COMPARE_FUNCTION,
            isSameLocale,
            REFERENCE_COMPARE_FUNCTION,
            REFERENCE_COMPARE_FUNCTION,
        ]
    )

    const updateCostsStatistics = useMemoLast(
        async (filters, organizationStructure, filterDictionaries, locale, permissions, currency) => {
            if (
                !permissions.costs.permissions.read &&
                !permissions.costsPerMedium.permissions.read &&
                !permissions.kpi.permissions.read
            )
                return
            const abortController = abortAndCreateNewController(costStatisticsAbortController)
            setCostStatisticsAbortController(abortController)
            setCostStatisticsStatus(createPendingStatus())
            setKpiStatisticsStatus(createPendingStatus())
            const requestParams = ProAnalyticsFiltersInterface(filters).getRequestParameters(
                organizationStructure,
                locale
            )
            requestParams.currency = currency
            const [error, data] = await tryCatch(JobBoosterService.getCostsStatistics)(
                requestParams,
                abortController.signal
            )
            if (error) {
                if (error.type !== JobBoosterServiceError.ABORT_ERROR) {
                    const logEntry = logger.error.withError(error, 'Could not load costs statistics')
                    const costsErrorMessage = locale('proAnalytics.costs.error', {
                        logNumber: logEntry.logNumber,
                    })
                    const kpiErrorMessage = locale('proAnalytics.kpi.error', {
                        logNumber: logEntry.logNumber,
                    })
                    setCostStatisticsStatus(createErrorStatus(costsErrorMessage))
                    setKpiStatisticsStatus(createErrorStatus(kpiErrorMessage))
                }
            } else {
                const costs = {
                    ...data,
                    deviceRatio: calculateDeviceRatioKPIs(data.publicationPerformance),
                    clicks: data?.clicksTotal,
                }
                setCostStatisticsStatus(createSuccessStatus())
                setKpiStatisticsStatus(createSuccessStatus())
                const onlineMediaCosts = filterOnlineMediaCostsMemo(data, filterDictionaries.media)
                const costByAction = getCostsByAction(onlineMediaCosts)
                setCostsStatistics(costs)
                setCostsByViews(costByAction.byViews)
                setCostsByClicks(costByAction.byClicks)
                setCostsByApplications(costByAction.byApplications)
                const kpis = extractKpiStatistics(costs)
                setKpiStatistics(kpis)
                return costs
            }
        },
        [
            ProAnalyticsFiltersInterface.areFiltersEqual,
            REFERENCE_COMPARE_FUNCTION,
            REFERENCE_COMPARE_FUNCTION,
            isSameLocale,
            REFERENCE_COMPARE_FUNCTION,
            REFERENCE_COMPARE_FUNCTION,
        ]
    )

    const updateCareerCenterStatistics = useMemoLast(
        async (filters, organizationStructure, actionType, locale, permissions) => {
            if (!permissions.positionMarket.permissions.read) return
            const abortController = abortAndCreateNewController(careerCenterStatisticsAbortController)
            setCareerCenterStatisticsAbortController(abortController)
            setCareerCenterStatisticsStatus(createPendingStatus())
            const requestParams = ProAnalyticsFiltersInterface(filters).getRequestParameters(
                organizationStructure,
                locale
            )

            requestParams.typ = actionType
            const [error, data] = await tryCatch(JobBoosterService.getCareerCenterStatistics)(
                requestParams,
                abortController.signal
            )
            if (error) {
                if (error.type !== JobBoosterServiceError.ABORT_ERROR) {
                    const logEntry = logger.error.withError(error, 'Could not load career center statistics')
                    const errorMessage = locale('proAnalytics.careerCenter.error', {
                        logNumber: logEntry.logNumber,
                    })
                    setCareerCenterStatisticsStatus(createErrorStatus(errorMessage))
                }
                setCareerCenterStatistics()
            } else {
                setCareerCenterStatisticsStatus(createSuccessStatus())
                setCareerCenterStatistics(data)
                return data
            }
        },
        [
            ProAnalyticsFiltersInterface.areFiltersEqual,
            REFERENCE_COMPARE_FUNCTION,
            REFERENCE_COMPARE_FUNCTION,
            isSameLocale,
            REFERENCE_COMPARE_FUNCTION,
        ]
    )

    const getCustomerConfig = useMemo(async nodeIds => {
        setCustomerConfigRequestStatus(createPendingStatus())
        const [error] = await tryCatch(CustomerConfiguration)({ nodeIds })
        if (error) setCustomerConfigRequestStatus(createErrorStatus(error))
        else setCustomerConfigRequestStatus(createSuccessStatus())
    }, haveSameElements)

    // const getKpiStatisticsMemo = useMemoLast(getKpiStatistics)
    const filterOnlineMediaCostsMemo = useMemoLast(filterOnlineMediaCosts)

    const getCostsByAction = useMemoLast(costs => ({
        byViews: filterRelevantCosts(costs, 'views'),
        byClicks: filterRelevantCosts(costs, 'clicks'),
        byApplications: filterRelevantCosts(costs, 'applications'),
    }))

    const getJobsCount = useMemoLast(jobStatistics =>
        jobStatistics?.map(item => ({ ...item, value: item[JOB_COUNT_FIELDS.jobCount.key] }))
    )
    const getPublicationsCount = useMemoLast(jobStatistics =>
        jobStatistics?.map(item => ({ ...item, value: item[JOB_COUNT_FIELDS.publications.key] }))
    )

    view.update((fields, errors) => {
        proAnalyticsOrganizationFilter.viewUpdate(fields)
        proAnalyticsSearch.viewUpdate(fields)
        proAnalyticsFilters.viewUpdate(fields)

        fields.reports.value = reports
        fields.reportsStatus.value = reportsStatus
        fields.reportXlsLoading.value = xlsDownloadLoading
        fields.reportsLink.onTrigger = onReportPreviewClick
        fields.reportXls.onTrigger = handleXlsDownload
        fields.reportsManagement.onTrigger = onReportsManagementClick

        fields.jobCountStatisticsStatus.value = jobCountStatisticsStatus
        fields.jobsStatistics.value = jobsStatistics
        fields.jobCountStatistics.value = jobsCount
        fields.publicationsCountStatisticsStatus.value = jobCountStatisticsStatus
        fields.publicationsCountStatistics.value = publicationsStatistics

        fields.candidateJourneyStatisticsStatus.value = candidateJourneyStatisticsStatus
        fields.candidateJourneyStatistics.value = candidateJourneyStatistics

        fields.performanceStatisticsStatus.value = performanceStatisticsStatus
        fields.performanceStatistics.value = performanceStatistics

        fields.performanceStatisticsActionType.onChange = setPerformanceStatisticsActionType

        fields.performanceStatisticsViewBy.onChange = setPerformanceStatisticsViewBy

        fields.performanceStatisticsViewBy.dictionary =
            features?.proAnalytics?.performanceWidget?.viewBy?.options?.inject()
        fields.performanceStatisticsViewBy.value =
            performanceStatisticsViewBy || fields.performanceStatisticsViewBy.default
        fields.performanceStatisticsActivityFieldFilter.value =
            performanceStatisticsFieldOfActivity || fields.performanceStatisticsActivityFieldFilter.default

        fields.performanceStatisticsJobFilter.onChange = setPerformanceStatisticsJob
        fields.performanceStatisticsJobFilter.value =
            performanceStatisticsJob || fields.performanceStatisticsJobFilter.default

        fields.kpiActiveJobs.value = costsStatistics?.activeStellen
        fields.kpiActivePublications.value = costsStatistics?.activePublications
        fields.kpiPublicationsOnline.value = costsStatistics?.publicationsOnline
        fields.kpiPublicationsOnlinePercentage.value = percent(
            costsStatistics?.publicationsOnline,
            costsStatistics?.activePublications
        )
        fields.kpiPublicationsPrint.value = costsStatistics?.publicationsPrint
        fields.kpiPublicationsPrintPercentage.value = percent(
            costsStatistics?.publicationsPrint,
            costsStatistics?.activePublications
        )
        fields.kpiCostsTotal.value = costsStatistics?.costsTotal
        fields.kpiCostsOnline.value = costsStatistics?.costsOnline
        fields.kpiCostsOnlinePercentage.value = percent(costsStatistics?.costsOnline, costsStatistics?.costsTotal)
        fields.kpiCostsPrint.value = costsStatistics?.costsPrint
        fields.kpiCostsPrintPercentage.value = percent(costsStatistics?.costsPrint, costsStatistics?.costsTotal)
        fields.kpiDeviceRatioWeb.value = costsStatistics?.deviceRatio.web
        fields.kpiDeviceRatioMobile.value = costsStatistics?.deviceRatio.mobile
        fields.kpiDeviceRatioPercentWeb.value = costsStatistics?.deviceRatio.percentWeb
        fields.kpiDeviceRatioPercentMobile.value = costsStatistics?.deviceRatio.percentMobile
        fields.kpiViews.value = costsStatistics?.viewsTotal
        fields.kpiClicks.value = costsStatistics?.clicksTotal
        fields.kpiPits.value = costsStatistics?.pitsToday

        fields.kpiStatistics.value = kpiStatistics
        fields.kpiStatisticsStatus.value = kpiStatisticsStatus

        fields.costsPerMediumStatistics.value = costsStatistics
        fields.costsByViews.value = costsByViews
        fields.costsByClicks.value = costsByClicks
        fields.costsByApplications.value = costsByApplications
        fields.costsStatisticsStatus.value = costStatisticsStatus

        fields.careerCenterStatistics.visible =
            !customerConfig?.proAnalytics?.careerCenterWidget?.isHidden &&
            (proAnalyticsPermissions.positionMarket?.permissions.read || false)

        fields.careerCenterStatisticsActionType.onChange = setCareerCenterStatisticsActionType
        fields.careerCenterStatisticsStatus.value = careerCenterStatisticsStatus
        fields.careerCenterStatistics.value = careerCenterStatistics

        fields.customerConfigStatus.value = customerConfigRequestStatus

        fields.logo.value = userSession.company?.logo
        fields.currency.value = proAnalyticsOrganizationFilter.currency

        errors.insufficientPermissions = !proAnalyticsPermissions.permissions.read
            ? getErrorStructure(locale, createErrorStatus(locale('proAnalytics.insufficientPermissions')))
            : undefined
    })

    return async () => {
        await login()
        process.ready()
        const permissions = await getPermissions()
        if (!permissions.permissions.read) return
        const parameters = dateRangeValidation(params, getDefaultDateRange())
        setParams(parameters)

        getAllReports(permissions).then(setReports)

        const organizationStructure = await getOrganizationStructure()
        setOrganizationStructure(organizationStructure)
        const organizationFilterState = await proAnalyticsOrganizationFilter.update()
        const currency = organizationFilterState.currency
        const organizationFilterValid = organizationFilterState.isOrganisationStructureFilterValid
        const [filtersState] = await Promise.all([proAnalyticsFilters.update(), proAnalyticsSearch.update()])
        const { filters, filterDictionaries } = filtersState
        let selectedNodes = []
        if (organizationFilterValid) {
            if (organizationFilterState.selectedOrganizationNodes.length) {
                selectedNodes = organizationFilterState.selectedOrganizationNodes
            } else if (organizationStructure[0]) {
                selectedNodes = includeChildNodes([organizationStructure[0].id], organizationStructure)
            }
            getCustomerConfig(selectedNodes)

            updateJobCountStatistics(filters, organizationStructure, locale, permissions)
            updateCandidateJourneyStatistics(filters, organizationStructure, locale, permissions)
            updatePerformanceStatistics(
                filters,
                organizationStructure,
                performanceStatisticsActionType,
                locale,
                permissions,
                currency
            )
            updateCostsStatistics(filters, organizationStructure, filterDictionaries, locale, permissions, currency)
            updateCareerCenterStatistics(
                filters,
                organizationStructure,
                careerCenterStatisticsActionType,
                locale,
                permissions
            )
        }
        pluginProcesses.forEach(pluginProcess =>
            pluginProcess({
                jobsStatistics,
                publicationsStatistics,
                jobCountStatisticsStatus,
                costsStatistics,
                filters,
                currency,
            })
        )
    }
})

ProAnalyticsProcess.label = 'ProAnalytics'

ProAnalyticsProcess.paramsToQueryParams = {
    search: ({ textFilter }) => textFilter || undefined,
    jobTitle: ({ internalTitle }) => (internalTitle ? internalTitle : undefined),
    externalJobTitle: ({ externalTitle }) => (externalTitle ? externalTitle : undefined),
    posting: ({ postingId }) => postingId,
    publication: ({ publicationId }) => publicationId,
    order: ({ orderId }) => orderId,
    recruiter: ({ recruiterFilter }) => recruiterFilter,
    recruiterSearch: ({ recruiterKeyword }) => recruiterKeyword,
    media: ({ mediaFilter }) => mediaFilter,
    field_of_activity: ({ fieldOfActivityFilter }) => fieldOfActivityFilter,
    industry: ({ industryFilter }) => industryFilter,
    ats: ({ atsFilter }) => atsFilter,
    node: ({ organizationStructureFilter }) => organizationStructureFilter,
    from: ({ dateRangeFilter }) => dateRangeFilter?.from && formatISODate(dateRangeFilter.from),
    to: ({ dateRangeFilter }) => dateRangeFilter?.to && formatISODate(dateRangeFilter.to),
}
ProAnalyticsProcess.queryParamsToParams = {
    textFilter: queryParams => queryParams.first('search'),
    internalTitle: queryParams => queryParams.first('jobTitle'),
    externalTitle: queryParams => queryParams.first('externalJobTitle'),
    postingId: queryParams => queryParams.first('posting'),
    publicationId: queryParams => queryParams.first('publication'),
    orderId: queryParams => queryParams.first('order'),
    recruiterKeyword: queryParams => queryParams.get('recruiterSearch'),
    recruiterFilter: queryParams => queryParams.get('recruiter').map(value => parseInt(value)),
    mediaFilter: queryParams => queryParams.get('media'),
    fieldOfActivityFilter: queryParams => queryParams.get('field_of_activity'),
    industryFilter: queryParams => queryParams.get('industry'),
    atsFilter: queryParams => queryParams.get('ats'),
    organizationStructureFilter: queryParams => queryParams.get('node').map(value => parseInt(value)),
    dateRangeFilter: queryParams => {
        const from = queryParams.first('from')
        const to = queryParams.first('to')
        if (!from || !to) return getDefaultDateRange()
        return { from: startOfDay(new Date(from)), to: endOfDay(new Date(to)) }
    },
}
