import { useRef } from 'react'
import { Dropdown, theme } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { usePluginFeatures } from '@modules/plugins/plugin_manager_react_utils'
import { langChoices } from '@lib/i18n/lang_choices'
import { Localization } from '@lib/i18n/localization'

const AppHeaderLanguages = () => {
    const { token } = theme.useToken()
    const popupContainer = useRef(null)
    const changeLocale = locale => Localization.setLocale(locale)
    const pluginFeatures = usePluginFeatures()

    const currentLocale = () => langChoices.find(lang => Localization.state.languageTag === lang.tag)
    const items = pluginFeatures?.app?.navbarLanguages?.inject()?.map(item => ({
        key: item.tag,
        label: item.language,
        style: item.tag === Localization.state.languageTag ? { background: '#f5f5f5' } : {},
        onClick: () => changeLocale(item.tag),
    }))

    const buttonStyle = {
        color: token.colorText,
    }

    const chevronStyle = {
        fill: token.colorText,
    }

    return (
        <div className="nav-header__language-select" ref={popupContainer}>
            <Dropdown menu={{ items }} trigger={['click']} getPopupContainer={() => popupContainer.current}>
                <button type="button" data-testid="language-select" style={buttonStyle}>
                    {currentLocale()?.language?.split(' ')[0]}{' '}
                    <span>
                        <DownOutlined style={chevronStyle} />
                    </span>
                </button>
            </Dropdown>
        </div>
    )
}

export default AppHeaderLanguages
