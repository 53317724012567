import { ProductFruits } from 'react-product-fruits'
import { CONFIG } from '@configuration/config_variables.js'
import { useViewModel } from '@lib/view_context/view-model.js'
import { ApplicationContext } from '@views/application/application.context.js'
import { useContext } from 'react'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'

export const UserOnboarding = () => {
    const applicationViewModel = useViewModel(ApplicationContext)
    const { languageCode } = useContext(LocaleContext)

    const productFruitsProps = {
        workspaceCode: CONFIG.PRODUCT_FRUITS_KEY,
        language: languageCode,
        user: {
            username: applicationViewModel.firstName?.value?.[languageCode],
            firstname: applicationViewModel.firstName?.value?.[languageCode],
            lastname: applicationViewModel.lastName?.value?.[languageCode],
            props: {
                email: applicationViewModel.email?.value,
            },
        },
    }

    if (!productFruitsProps.user?.firstname) return null

    return <ProductFruits {...productFruitsProps} />
}
