import { CATEGORIES, Logger } from '@modules/logging/logger'
import { ExitConfirmationContext } from '@views/misc/exit_confirmation/exit_confirmation.context'
import { useView } from '@lib/view_context/view_context_hooks'
import { Process, useCallback } from '@prospective/process-router'
import { EXIT_CONFIRMATION } from '@structures/exit_confirmation'

Logger('ExitConfirmation', CATEGORIES.MAIN)
export const ExitConfirmation = Process(({ process, params }) => {
    const view = useView(ExitConfirmationContext)

    const exit = useCallback(result => {
        view.update(fields => {
            fields.isActive.value = false
        })
        process.exit(result)
    })

    view.update(fields => {
        fields.cancel.onTrigger = () => exit(EXIT_CONFIRMATION.CANCEL)
        fields.discard.onTrigger = () => exit(EXIT_CONFIRMATION.DISCARD)
        fields.save.onTrigger = () => exit(EXIT_CONFIRMATION.SAVE)
        fields.isActive.value = true
        if (params?.actions) params.actions.forEach(action => (fields[action].visible = true))
    })
})

ExitConfirmation.label = 'Exit Confirmation'
