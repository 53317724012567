import { requiredValidator } from '@prospective/pms-view-context'
import { Localization } from '@lib/i18n/localization.js'
import { createPasswordValidator, passwordEqualityValidator } from '@views/settings/password/password.validator.js'
import { View } from '@lib/view_context/view-context.js'

const PasswordMetadata = locale => ({
    username: {
        label: locale('username'),
        actions: ['onChange'],
    },
    passwordChange: {
        label: locale('changePassword'),
        actions: ['onChange'],
    },
    currentPassword: {
        label: locale('currentPassword'),
        actions: ['onChange'],
        validators: [requiredValidator(locale('currentPasswordIsRequiredError'))],
    },
    newPassword: {
        label: locale('newPassword'),
        actions: ['onChange'],
        description: locale('passwordValidationRules', { minLength: 6 }),
        validators: [createPasswordValidator(locale)(6)],
    },
    newPasswordRepeat: {
        label: locale('repeatNewPassword'),
        actions: ['onChange'],
        validators: [
            requiredValidator(locale('passwordConfirmationIsRequiredError')),
            passwordEqualityValidator(locale('repeatedPasswordMismatchError')),
        ],
    },
    cancel: {
        label: locale('cancel'),
        actions: ['onTrigger'],
    },
    newPasswordApply: {
        label: locale('applyNewPassword'),
        actions: ['onTrigger'],
    },
    changeRequestStatus: {
        label: locale('status'),
    },
    back: {
        actions: ['onTrigger'],
    },
})

export const PasswordContext = View.observe(Localization).update(localization => PasswordMetadata(localization.locale))
