import { Process, stateOf } from '@prospective/process-router'
import { Logger } from '@modules/logging/logger.js'
import { ModuleController } from '@modules/module_controller.js'
import { UserSessionProcess } from '@login/user_session.process.js'
import { useView } from '@lib/view_context/view_context_hooks.js'
import { ApplicationContext } from '@views/application/application.context.js'
import { Localization } from '@lib/i18n/localization.js'
import { SettingsProcess } from '@views/settings/settings.process.js'
import { injectPluginProcesses } from '@modules/plugins/plugin_manager_process_utils.js'
import { PluginManager } from '@modules/plugins/plugin_manager.js'
import { SystemInfo } from '@login/system-info.js'
import { useRemoteDataStream } from '@utils/process_hooks.js'
import { MemoizedLast, Shared } from '@lib/stream/stream2.js'

const logger = Logger('ApplicationProcess')
export const ApplicationProcess = Process(({ process }) => {
    UserSessionProcess()
    const pluginsState = stateOf(ModuleController.pluginsState)
    const view = useView(ApplicationContext)
    const { locale } = stateOf(Localization)
    const { features } = stateOf(PluginManager)
    const [getSystemInfo, getSystemInfoState] = useRemoteDataStream(
        SystemInfo.loadSystemInfo.compose(MemoizedLast).compose(Shared)
    )

    const pluginProcesses = injectPluginProcesses(features.app, process)

    view.update(fields => {
        fields.initializationTasks.dictionary = [{ label: locale('loadingPlugins'), ...pluginsState }]
        fields.settings.onTrigger = () => SettingsProcess()
        fields.backendVersion.value = getSystemInfoState?.value
    })

    ModuleController.getPlugins().catch(error => logger.error.withError(error, 'Loading plugins failed'))

    return () => {
        pluginProcesses.forEach(pluginProcess => pluginProcess())
        getSystemInfo()
    }
})
ApplicationProcess.label = 'Application Process'
