import { useContext } from 'react'
import { useViewModel } from '@lib/view_context/view-model'
import { PageHeader } from '@ant-design/pro-layout'
import { Spin, Table, Breadcrumb, Empty } from 'antd'
import { Link } from '@prospective/process-router-react'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { LoadingOutlined, FilePdfOutlined } from '@ant-design/icons'
import { InvoicesContext } from '@views/settings/invoices/invoices.context'
import { routes } from '@configuration/routes'
import { Route } from '@prospective/process-router'
import { sortBillingDates } from './invoices.process'
import Alerts, { ErrorAlert } from '@components/elements/alerts/alerts'
import TasksStatus from '@components/elements/tasks_status/tasks_status.jsx'
import '@views/settings/invoices/invoices.scss'

const prepareTableData = (locale, data = [], pdfDownload) => {
    const tableColumns = [
        {
            title: locale('billingDate'),
            dataIndex: 'billing_date',
            key: 'billingDate',
            sorter: sortBillingDates,
            render: billing_date => (billing_date ? locale('dateFormatter', new Date(billing_date)) : '-'),
        },
        {
            title: locale('billingNumber'),
            dataIndex: 'billing_number',
            key: 'billingNumber',
        },
        {
            title: locale('jobTitle'),
            dataIndex: 'jobTitle',
            key: 'jobTitle',
        },
        {
            title: locale('jobId'),
            dataIndex: 'jobId',
            key: 'jobId',
            align: 'center',
        },
        {
            title: locale('orderId'),
            dataIndex: 'orderId',
            key: 'orderId',
            align: 'center',
            render: orderId => {
                return locale('numberFormatter', orderId)
            },
        },
        {
            title: '',
            dataIndex: 'pdf',
            key: 'pdf',
            render: (_, invoice) => {
                const isDownloading = pdfDownload.isLoading?.status === 'pending'
                return (
                    <FilePdfOutlined
                        onClick={() => pdfDownload.onTrigger(invoice.billing_number, invoice.jobTitle)}
                        className={`invoices__pdf-download ${isDownloading ? 'isDownloading' : ''}`}
                    />
                )
            },
        },
    ]

    const tableData = data.map((invoice, idx) => ({
        key: idx,
        billing_date: invoice.billing_date,
        billing_number: invoice.billing_number,
        jobTitle: invoice.job_title,
        jobId: invoice.job_id,
        orderId: invoice.order_id,
    }))

    return {
        tableColumns,
        tableData,
    }
}

const Invoices = () => {
    const { locale } = useContext(LocaleContext)
    const viewModel = useViewModel(InvoicesContext)
    const invoices = viewModel.invoices.value
    const invoicesLoading = viewModel.invoices?.isLoading?.status === 'pending'
    const pdfDownload = viewModel.pdfDownload
    const { tableColumns, tableData } = prepareTableData(locale, invoices, pdfDownload)
    const settingsPath = Route(routes.settings).toLocation().path

    return (
        <div className="invoices">
            <PageHeader
                title={viewModel.invoices.label}
                onBack={viewModel.back.onTrigger}
                ghost={false}
                breadcrumbRender={() => (
                    <Breadcrumb
                        items={[
                            {
                                title: <Link routePath={settingsPath}>{locale('settings')}</Link>,
                            },
                            { title: viewModel.invoices.label },
                        ]}
                    />
                )}
            >
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={invoicesLoading}>
                    {viewModel.errors.insufficientPermissionsError ? (
                        <ErrorAlert error={viewModel.errors.insufficientPermissionsError} closable={false} />
                    ) : viewModel.errors.hasOwnProperty('invoicesError') ? (
                        <Alerts errors={viewModel.errors} />
                    ) : (
                        <div className={`invoices__wrap ${invoicesLoading ? 'isLoading' : ''}`}>
                            <div className="invoices__table">
                                <Table
                                    scroll={{ x: 'max-content' }}
                                    columns={tableColumns}
                                    dataSource={tableData}
                                    rowClassName={(_, index) => (index % 2 === 0 ? 'even' : 'odd')}
                                    pagination={{
                                        total: invoices?.length,
                                        hideOnSinglePage: true,
                                        showSizeChanger: false,
                                        pageSize: 15,
                                    }}
                                    locale={{
                                        emptyText: () => (
                                            <div>
                                                <Empty description={locale('settings.invoices.empty')} />
                                            </div>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </Spin>
            </PageHeader>
            <TasksStatus tasks={viewModel.tasks.dictionary} />
        </div>
    )
}

export default Invoices
