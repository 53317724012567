import { customPriceFormatter } from '@lib/i18n/custom_price.formatter.js'

export default {
    'dateFormatter': date => date ? `${date.getDate()}/${(date.getMonth() + 1)}/${date.getFullYear()}` : ' - ',
    'dateFormatter.shortDate': date => date ? `${(date.getMonth() + 1)}/${date.getDate()}/${date.getFullYear()}` : ' - ',
    'monthFormatter': date => date ? date.toLocaleString('en-GB', {year: 'numeric', month: 'long'}) : ' - ',
    'priceFormatter': (price, currency) => isNaN(price) ? '-' : currency ? price.toLocaleString('en-GB', {style: 'currency', currency}) : price.toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
    'priceFormatter2': (price, currency) => customPriceFormatter(price, currency, 'en-GB'),
    'numberFormatter': (number) => number ? number.toLocaleString('en-GB', {minimumFractionDigits: 0, maximumFractionDigits: 0}): 0,
    'twoDigitFormatter': (number) => number ? number.toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}): 0,
}
