import { login } from '@modules/process_status/process_status'
import { Logger, CATEGORIES } from '@modules/logging/logger'
import { Process } from '@prospective/process-router'
import { NotificationsContext } from '@views/settings/notifications/notifications.context'
import { useView } from '@lib/view_context/view_context_hooks.js'

Logger('NotificationsProcess', CATEGORIES.MAIN)
export const NotificationsProcess = Process(({ process }) => {
    const view = useView(NotificationsContext)

    view.update(fields => {
        fields.back.onTrigger = process.exit
    })

    return async () => {
        await login()
        process.ready()
    }
})

NotificationsProcess.label = 'Notifications'
