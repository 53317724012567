import { Localization } from '@lib/i18n/localization'
import { PasswordProcess } from '@views/settings/password/password.process'
import { NotificationsProcess } from '@views/settings/notifications/notifications.process'
import { ReportEditorProcess } from '@views/settings/reports/report_editor.process'
import { ReportListProcess } from '@views/settings/reports/report_list.process'
import { SettingsProcess } from '@views/settings/settings.process'
import { ProAnalyticsProcess } from '@views/pro_analytics/pro_analytics.process'
import { ProAnalyticsJobProcess } from '@views/pro_analytics/pro-analytics-job/pro-analytics-job.process'
import { ProAnalyticsReport } from '@views/pro_analytics/pro_analytics_report/pro_analytics_report.process'
import { CockpitRedirectionProcess } from '@views/cockpit/cockpit_redirection.process'
import { InvoicesProcess } from '@views/settings/invoices/invoices.process'
import { CompanyInfoProcess } from '@views/settings/company_info/company_info.process'

const settingsText = Localization.locale('settings')
const passwordText = Localization.locale('password')
const reportsText = Localization.locale('reports')
const reportEditorText = Localization.locale('reports.editReport')
const notificationsText = Localization.locale('notifications')
const companyInfo = Localization.locale('companyInfo')
const invoicesText = Localization.locale('invoices')

export const routes = {
    settings: {
        key: 'settings',
        path: 'settings',
        title: settingsText,
        process: SettingsProcess,
        paramsToQueryParams: {},
        queryParamsToParams: {},
    },
    invoices: {
        key: 'settings/invoices',
        path: 'settings/invoices',
        title: invoicesText,
        process: InvoicesProcess,
        paramsToQueryParams: {},
        queryParamsToParams: {},
    },
    companyInfo: {
        key: 'settings/company-info',
        path: 'settings/company-info',
        title: companyInfo,
        process: CompanyInfoProcess,
        paramsToQueryParams: {},
        queryParamsToParams: {},
    },
    password: {
        key: 'settings/password',
        path: 'settings/password',
        title: `${settingsText} - ${passwordText}`,
        process: PasswordProcess,
        paramsToQueryParams: {},
        queryParamsToParams: {},
    },
    notifications: {
        key: 'settings/notifications',
        path: 'settings/notifications',
        title: `${settingsText} - ${notificationsText}`,
        process: NotificationsProcess,
        paramsToQueryParams: {},
        queryParamsToParams: {},
    },
    reports: {
        key: 'settings/reports',
        path: 'settings/reports',
        title: `${settingsText} - ${reportsText}`,
        process: ReportListProcess,
        paramsToQueryParams: {},
        queryParamsToParams: {},
    },
    reportEditor: {
        key: 'settings/reports/report',
        path: 'settings/reports/:id',
        title: `${settingsText} - ${reportEditorText}`,
        process: ReportEditorProcess,
        paramsToQueryParams: {},
        queryParamsToParams: {},
    },
    proAnalytics: {
        key: 'proAnalytics',
        path: 'pro-analytics',
        title: 'Pro-Analytics',
        process: ProAnalyticsProcess,
        paramsToQueryParams: ProAnalyticsProcess.paramsToQueryParams,
        queryParamsToParams: ProAnalyticsProcess.queryParamsToParams,
    },
    proAnalyticsJob: {
        key: 'proAnalytics/single',
        title: 'Pro-Analytics: Job statistics',
        path: 'pro-analytics/:jobId',
        process: ProAnalyticsJobProcess,
        paramsToQueryParams: ProAnalyticsJobProcess.paramsToQueryParams,
        queryParamsToParams: ProAnalyticsJobProcess.queryParamsToParams,
    },
    proAnalyticsReport: {
        key: 'proAnalytics/reports',
        title: 'Pro-Analytics Reports',
        path: 'pro-analytics/reports/:reportId',
        process: ProAnalyticsReport,
        paramsToQueryParams: ProAnalyticsReport.paramsToQueryParams,
        queryParamsToParams: ProAnalyticsReport.queryParamsToParams,
    },
    cockpit: {
        key: 'cockpit',
        title: 'Cockpit',
        path: '',
        process: CockpitRedirectionProcess,
    },
    errorNotFound: {
        key: 'error/notfound',
        path: 'views/misc/not_found'
    },
}
