export const API_ERROR = 'JOBBOOSTER_SERVICE_ERROR_TYPE_API_ERROR'
export const PARSE_ERROR = 'JOBBOOSTER_SERVICE_ERROR_TYPE_PARSE_ERROR'
export const UNKNOWN_ERROR = 'JOBBOOSTER_SERVICE_ERROR_TYPE_UNKNOWN_ERROR'
export const NOT_FOUND = 'JOBBOOSTER_SERVICE_ERROR_TYPE_NOT_FOUND'
export const FORBIDDEN = 'JOBBOOSTER_SERVICE_ERROR_TYPE_FORBIDDEN'
export const ABORT_ERROR = 'JOBBOOSTER_SERVICE_ERROR_TYPE_ABORT_ERROR'

/**
 * @typedef {Object} APIErrorCause
 * @property {Response} response
 * @property {number} code Http request status code
 */

/**
 * @typedef {Object} JobBoosterServiceErrorOptions
 * @property {APIErrorCause|Error} [cause]
 * @property {string} [type]
 */
export class JobBoosterServiceError extends Error {
    /**
     *
     * @param {string} message
     * @param {JobBoosterServiceErrorOptions} options
     */
    constructor(message, options) {
        super(message, options)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, JobBoosterServiceError)
        }

        this.name = 'JobBoosterServiceError'
        this.message = options?.cause?.message?.message
        this.status = options?.cause?.response?.status
        this.statusText = options?.cause?.response?.statusText
        this.response = options?.cause?.response
        this.url = options?.cause?.response?.url
        this.type = options?.type
        this.code = options?.cause?.code
        this.isAbortError = options?.type === ABORT_ERROR
    }
}

JobBoosterServiceError.API_ERROR = API_ERROR
JobBoosterServiceError.PARSE_ERROR = PARSE_ERROR
JobBoosterServiceError.UNKNOWN_ERROR = UNKNOWN_ERROR
JobBoosterServiceError.ABORT_ERROR = ABORT_ERROR
JobBoosterServiceError.NOT_FOUND = NOT_FOUND
JobBoosterServiceError.FORBIDDEN = FORBIDDEN
