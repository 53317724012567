import { complement, haveSameElements, O, Publisher } from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { UserSession } from '@login/user-session'
import { Authorization } from '@modules/authorization/authorization'
import { Localization, mergeLocalizationResources, mergeTagTables } from '@lib/i18n/localization'
import { CustomerConfig } from '@modules/customer_config/customer_config'
import JobBoosterService from '@services/job_booster_service'
import { Dictionaries } from '@modules/dictionaries/dictionaries'
import { ThemeManager } from '@modules/theming/theme_manager.js'
import DefaultPlugin from '@plugins/defaultPlugin/defaultPlugin.index.js'
import { terminate } from '@prospective/process-router'

const logger = Logger('PluginManager', CATEGORIES.MAIN)
const defaultPluginKey = 'main'
const defaultPluginValue = {
    name: 'Default',
    description: 'Default plugin (always on)',
    key: defaultPluginKey,
    plugin: DefaultPlugin,
}

// PluginManager implements StatePublisher interface:
const [onChange, publishChange] = Publisher()
const [onStatusChange, publishStatusChange] = Publisher()
const [onLocalizationResourcesChange, publishLocalizationResourcesChange] = Publisher()
const [onThemesChange, publishOnThemesChange] = Publisher()
const [onRoutesChange, publishRoutesChange] = Publisher()
const STATUS_IDLE = 'idle'
const STATUS_LOADING = 'loading'
const STATUS_ERROR = 'error'

/**
 * @typedef {Object} RouteDescriptor
 * @property {string} key
 * @property {string} [title]
 * @property {string} [path]
 */

/**
 * @typedef {Object} ApplicationFeatures
 * @property {Object} [proAnalytics]
 * @property {Object} [proAnalytics.processes]
 * @property {function(): (function(params:Object=): ProcessObject)[]} [proAnalytics.processes.inject]
 * @property {Array<function>} [proAnalytics.partialProcesses]
 * @property {Object} [proAnalytics.kpiWidget]
 * @property {function({locale: function, viewModel: Object, currency: string}):ReactNode} [proAnalytics.kpiWidget.inject]
 * @property {Object} [proAnalytics.candidateJourneyWidget]
 * @property {Object} [proAnalytics.candidateJourneyWidget.viewBy]
 * @property {Object} [proAnalytics.candidateJourneyWidget.viewBy.options]
 * @property {function():[{value: string, label: string}]} [proAnalytics.candidateJourneyWidget.viewBy.options.inject]
 * @property {Object} [proAnalytics.candidateJourneyWidget.getAggregation]
 * @property {function(original:Object):Object} [proAnalytics.candidateJourneyWidget.getAggregation.injectWithOriginal]
 * @property {Object} [proAnalytics.performanceWidget]
 * @property {Object} [proAnalytics.performanceWidget.viewBy]
 * @property {Object} [proAnalytics.performanceWidget.viewBy.options]
 * @property {function():Array} [proAnalytics.performanceWidget.viewBy.options.inject]
 * @property {Object} [proAnalytics.performanceWidget.actionType]
 * @property {Object} [proAnalytics.performanceWidget.actionType.options]
 * @property {function():Array<{label: string, value: string}>} [proAnalytics.performanceWidget.actionType.options.inject]
 * @property {Object} [proAnalytics.filters]
 * @property {Object} [proAnalytics.filters.fieldOfActivity]
 * @property {Object} [proAnalytics.filters.fieldOfActivity.visibility]
 * @property {function():boolean} [proAnalytics.filters.fieldOfActivity.visibility.inject]
 * @property {Object} [proAnalytics.filters.industryFilter]
 * @property {Object} [proAnalytics.filters.industryFilter.visibility]
 * @property {function():boolean} [proAnalytics.filters.industryFilter.visibility.inject]
 * @property {Object} [proAnalytics.filters.atsFilter]
 * @property {Object} [proAnalytics.filters.atsFilter.visibility]
 * @property {function():boolean} [proAnalytics.filters.atsFilter.visibility.inject]
 * @property {Object} [proAnalytics.singleJobAd]
 * @property {Object} [proAnalytics.singleJobAd.processes]
 * @property {function(): (function(params:Object=): ProcessObject)[]} [proAnalytics.singleJobAd.processes.inject]
 */

/** @typedef {Object} PluginDependencies
 * @property {UserSession} UserSession UserSession module
 * @property {Authorization} Authorization Authorization module
 * @property {Dictionaries} UserDictionaries UserDictionaries module
 * @property {Localization} Localization Localization module
 * @property {CustomerConfig} CustomerConfig CustomerConfig module
 * @property {function} Logger Logger
 * @property {function} JobBoosterService JobBoosterService
 */

/**
 * @typedef {{[key: string]: PluginFeatureNode}} PluginFeatureNode
 * @property {function(*):function(*):*} [injection]
 */

/** @typedef {Object} PluginDescriptor
 * @property {string} name Plugin name
 * @property {string} [description] Plugin description
 * @property {Process} plugin Plugin function
 */

/** @typedef {Object} PluginInterface
 * @property {Object.<string, PluginFeatureNode>} [features] Hierarchical structure of features to extend or override
 * @property {{[key: string]: RouteDescriptor}} [routes] Routes
 * @property {{[key: string]: Object}} [localizationResources] Localization resources
 * @property {Object[]} [themes] Themes
 * @property {{tag: String, country: string, language: string, fallbacks: string[]}[]} [tagTable] Localization descriptors
 * @property {function} activate Callback called when the plugin is activated
 * @property {function} deactivate Callback called when the plugin is deactivated
 */

/** @typedef {Object} PluginManagerState
 * @property {Object.<string, RouteDescriptor>} [routes] Merged routes
 * @property {{[key: string]: Object}} [localizationResources] Localization resources
 * @property {Object[]} [themes] Themes
 * @property {LocaleDescriptor[]} [tagTable] Descriptors of available locales
 * @property {ApplicationFeatures} [features] Merged features
 * @property {Object.<string, PluginDescriptor>} [plugins] Full list of available plugins
 * @property {string[]} [activePlugins] List of active plugin keys
 * @property {('idle'|'loading'|'error')} [status] Current status of the PluginManager
 */

/**
 * @type {PluginManagerState}
 */
let state = {
    routes: {},
    localizationResources: {},
    features: {},
    themes: [],
    plugins: {},
    activePlugins: [],
    status: STATUS_IDLE,
}

let isDefaultPluginActive

let allPlugins = {
    [defaultPluginKey]: defaultPluginValue
}

let activePluginInstances = new Map()

const didLocalizationResourcesChange = (oldState, newState) =>
    !haveSameElements(O(oldState.localizationResources).keys(), O(newState.localizationResources).keys()) ||
    O(oldState.localizationResources).some(
        (resourceSet, key) => !O(resourceSet).isEqual(state.localizationResources[key])
    )

const didRoutesChange = (oldState, newState) => !O(oldState.routes).isEqual(newState.routes)
/* ||
    O(oldState.routes).some(
        (route, key) => !O(route).isEqual(state.routes[key])
    )*/

const didThemesChange = (oldState, newState) => !haveSameElements(oldState.themes, newState.themes)

/**
 * Performs a deep-merge of given features
 * @param {...PluginFeatureNode} features
 * @return {PluginFeatureNode}
 */
export const mergeFeatures = (...features) =>
    [...features].reduce((result, pluginFeatures) => {
        O(pluginFeatures).forEach((node, key) => {
            if (key === 'injection') {
                const originalInject = result.inject
                result.inject = (...args) => {
                    const originalValue = originalInject ? originalInject(...args) : undefined
                    return pluginFeatures.injection(originalValue)(...args)
                }
                result.injectWithOriginal = (originalValue, ...args) => {
                    return pluginFeatures.injection(originalValue)(...args)
                }
            } else if (key === 'injectWithOriginal' || key === 'inject') {
                result[key] = pluginFeatures[key]
            } else {
                result[key] = mergeFeatures(result[key], node)
            }
        })
        return result
    }, {})

/**
 * Merges plugin structures into one.
 * @param {...PluginInterface} structures
 * @return {{routes: {[key: string]: RouteDescriptor}, localizationResources: {[key: string]: Object}, features: ApplicationFeatures, themes: Object[]}}
 */
export const mergePluginStructures = (...structures) => {
    return [...structures].reduce(
        (result, structure) => {
            result.routes = { ...result?.routes, ...structure?.routes }
            result.localizationResources = mergeLocalizationResources(
                result?.localizationResources,
                structure?.localizationResources || {}
            )
            result.tagTable = mergeTagTables(result.tagTable, structure?.tagTable || [])
            result.features = mergeFeatures(result.features, structure?.features)
            result.themes = [...result.themes, ...(structure?.themes || [])]
            result.activeTheme = structure?.activeTheme || result.activeTheme
            return result
        },
        {
            routes: {},
            localizationResources: {},
            features: {},
            themes: [ThemeManager.defaultThemeDescriptor],
            activeTheme: ThemeManager.activeTheme,
        }
    )
}

const onParametersChange = () => {
    // console.debug('observePlugin state', parameters)

    const oldState = state
    const pluginStructures = Array.from(activePluginInstances.values()).map(plugin => plugin.parameters)
    state = { ...state, ...mergePluginStructures(...pluginStructures) }
    if (didLocalizationResourcesChange(oldState, state)) publishLocalizationResourcesChange(state)
    if (didRoutesChange(oldState, state)) publishRoutesChange(state?.routes)
    // router.routes = { ...router.routes, ...state.routes }
    if (didThemesChange(oldState, state)) {
        publishOnThemesChange(state.themes)
        ThemeManager.update(({ setThemes, setActiveTheme }) => {
            setThemes(state.themes)
            setActiveTheme(state.activeTheme)
        })
    }
    publishChange(state)
}

const observePlugin = plugin => {
    plugin.parametersChange.subscribe(onParametersChange)
}

const unobservePlugin = plugin => {
    plugin.parametersChange.unsubscribe(onParametersChange)
}

/**
 * Turns given plugins on and other ones off
 * @param {string[]} active The order of plugins matters.
 * The features of latter ones override features of the preceding ones
 */
const setActivePlugins = async (active = []) => {
    try {
        const activeWithDefaultPlugin = ['main', ...active]
        const pluginsToDeactivate = complement(state.activePlugins, activeWithDefaultPlugin)
        pluginsToDeactivate.forEach(key => {
            try {
                // activePluginInstances[key].deactivate()
                unobservePlugin(activePluginInstances.get(key))
                terminate(activePluginInstances.get(key), 'Plugin deactivation', true)
            } catch (error) {
                logger.error.withError(error, `An error occurred when deactivating plugin '${key}'`)
            }
        })

        const pluginsToActivate = complement(activeWithDefaultPlugin, state.activePlugins)

        if (pluginsToActivate.length) {
            state = { ...state, status: STATUS_LOADING }
            publishChange(state)
            publishStatusChange(state.status)
        }

        const activationPromises = pluginsToActivate.map(async pluginKey => {
            const pluginDefinition = allPlugins[pluginKey]
            if (!pluginDefinition) {
                logger.warn(`Plugin ${pluginKey} was not found in the plugin list`)
                return null // Return null or a suitable value indicating a skipped plugin.
            } else {
                try {
                    // Await the plugin process creation.
                    const pluginProcess = await pluginDefinition.plugin(dependencies)
                    // Call the function returned by pluginProcess to get the actual plugin instance.
                    const pluginInstance = pluginProcess()
                    // Observe the plugin instance if needed.
                    observePlugin(pluginInstance)
                    await pluginInstance.ready
                    return { pluginKey, pluginInstance }
                } catch (error) {
                    // Log the error if plugin activation fails.
                    logger.error.withError(error, `Failed to activate plugin '${pluginKey}'`)
                    throw error
                }
            }
        })

        // Await all the activation promises to resolve.
        const activatedPluginsResults = await Promise.all(activationPromises)

        const activatedPluginProcesses = activatedPluginsResults.reduce((acc, result) => {
            if (result) {
                acc[result.pluginKey] = result.pluginInstance
            }
            return acc
        }, {})

        const newActivePlugins = activeWithDefaultPlugin.reduce((result, key) => {
            if (activePluginInstances.get(key) && !pluginsToDeactivate.includes(key)) {
                result.set(key, activePluginInstances.get(key))
            }
            if (activatedPluginProcesses[key]) {
                result.set(key, activatedPluginProcesses[key])
            }
            return result
        }, new Map())

        const newActivePluginsSorted = activeWithDefaultPlugin.map(key => newActivePlugins.get(key)).filter(plugin => plugin)
        activePluginInstances = newActivePlugins
        state.activePlugins = active
        const oldState = state
        state = { ...state, ...mergePluginStructures(...newActivePluginsSorted.map(plugin => plugin.parameters)) }
        if (pluginsToActivate.length) {
            state = { ...state, status: STATUS_IDLE }
            publishStatusChange(state.status)
        }
        if (didLocalizationResourcesChange(oldState, state)) publishLocalizationResourcesChange(state)
        if (didRoutesChange(oldState, state)) publishRoutesChange(state?.routes)
        if (didThemesChange(oldState, state)) {
            publishOnThemesChange(state.themes)
            /*ThemeManager.update(({ setThemes, setActiveTheme }) => {
                setThemes(state.themes)
            })*/
            ThemeManager.update(({ setThemes, setActiveTheme }) => {
                setThemes(state.themes)
                setActiveTheme(state.activeTheme)
            })
        }
        publishChange(state)
    } catch (error) {
        const errorMessage = `There was an error by activating plugin(s) ${activeWithDefaultPlugin
            .map(key => `'${allPlugins[key]?.name || key}'`)
            .join(', ')}.`
        logger.error.withError(error, errorMessage)
        state = { ...state, status: STATUS_ERROR }
        publishStatusChange(state.status)
        publishChange(state)
        throw new Error(errorMessage, { cause: error })
    }
}

/**
 * Singleton module to manage application plugins.
 * @type {{
 * readonly features: ApplicationFeatures,
 * readonly routes: Record<string, RouteDescriptor>,
 * readonly state: PluginManagerState,
 * plugins?: Object<string, PluginDescriptor>,
 * activePlugins?: string[],
 * localizationResources?: {[key: string]: Object},
 * tagTable: LocaleDescriptor[],
 * } & IStatePublisher}
 */
export const PluginManager = {
    get plugins() {
        return state.plugins
    },
    set plugins(value) {
        state.plugins = value
        allPlugins = {
            ...value,
            [defaultPluginKey]: defaultPluginValue
        }
        publishChange(state)
    },
    setActivePlugins,
    set activePlugins(value) {
        setActivePlugins(value)
    },
    get activePlugins() {
        return state.activePlugins
    },
    get state() {
        return state
    },
    get features() {
        return state.features
    },
    get routes() {
        return state.routes
    },
    get localizationResources() {
        return state.localizationResources
    },
    get tagTable() {
        return state.tagTable
    },
    get status() {
        return state.status
    },
    ...onChange,
    onChange,
    onStatusChange,
    onLocalizationResourcesChange,
    onThemesChange,
    onRoutesChange,
}

PluginManager.STATUS_IDLE = STATUS_IDLE
PluginManager.STATUS_LOADING = STATUS_LOADING
PluginManager.STATUS_ERROR = STATUS_ERROR
/**
 * @type {PluginDependencies}
 */
const dependencies = {
    UserSession: UserSession,
    Authorization: Authorization,
    UserDictionaries: Dictionaries,
    Localization: Localization,
    CustomerConfig: CustomerConfig,
    Logger: Logger,
    JobBoosterService: JobBoosterService,
    PluginManager: PluginManager,
}

// Initialize PluginManager with defaults:
// setActivePlugins().catch(error => {
//     logger.error.withError(error, 'Could not set active plugins due to an error.')
// })
// observeDependencies()
